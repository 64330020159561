import { NgModule } from '@angular/core';
import { TranslatePipe } from './translate';
import { DevidebyPipe } from './pipes/devideby.pipe';
import { FromDailyBatchModelPipe } from './pipes/from-daily-batch-model.pipe';
import { FromModelPipe } from './pipes/frommodel.pipe';
import { TranslateDatePipe } from './pipes/translatedate.pipe';
import { TranslateISODatePipe } from './pipes/translateISOdate.pipe';
import { TranslateUTCDatePipe } from './pipes/translateUTCdate.pipe';
import { ArraySortPipe } from "./pipes/sort.pipe";
import { AbbrPipe } from "./pipes/abbr.pipe";
import { TokenizerPipe } from "./pipes/tokenizer.pipe";
import { DecimalPipe } from "./pipes/decimal.pipe";
import { VisualizePipe } from "./pipes/visualize.pipe";

@NgModule({
	imports: [],
	declarations: [
		AbbrPipe,
		TokenizerPipe,
		TranslatePipe,
		DecimalPipe,
		TranslateDatePipe,
		FromModelPipe,
		DevidebyPipe,
		FromDailyBatchModelPipe,
		TranslateISODatePipe,
		TranslateUTCDatePipe,
		ArraySortPipe,
		VisualizePipe
	],
	exports: [
		AbbrPipe,
		TokenizerPipe,
		TranslatePipe,
		DecimalPipe,
		TranslateDatePipe,
		FromModelPipe,
		DevidebyPipe,
		FromDailyBatchModelPipe,
		TranslateISODatePipe,
		TranslateUTCDatePipe,
		ArraySortPipe,
		VisualizePipe
	]
})
export class PipesModule {}
