/**
 * translate.pipe.ts
 */
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '../translate';

@Pipe({
	name: 'translate',
	pure: true // only do PURE PIPES ! (only call us when the input changes)
})

export class TranslatePipe implements PipeTransform {

	constructor(private _translate: TranslateService) {}

	transform(value: string, lang: string): any {
		if (!value) {
			//console.log(`Empty translation for value: '${value}', check that`);
			return;
		}
		return this._translate.instant(value, lang);
	}
}
