import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { throwError } from 'rxjs';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { catchError, map, share } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Base64 } from '../base64';
import { NotificationService } from '../notifications/notification.service';
import { IEnhancedLinkData, IHalLinkData, IHALObject } from './domain-model';

/// <reference path="urijs" />
const uriTemplate = require('urijs/src/URITemplate');

@Injectable({ providedIn: 'root' })
export class HalDiscoveryService {
	private encoder: Base64 = new Base64();
	gitCommitHeader: Subject<string> = new Subject<string>();

	resourceTree;

	constructor(
		private http: HttpClient,
		private notificationService: NotificationService
	) {
		let services = [] as Observable<any>[];

		const header = new HttpHeaders().set(
			'Authorization',
			'Basic ' +
				this.encoder.encode(
					`${environment.authService.clientId}:${environment.authService.clientPassword}`
				)
		);

		environment.restServices.forEach((service) => {
			services.push(
				http.get(service.url, { headers: header, observe: 'response' }).pipe(
					catchError((e) => {
						let message: string;

						if (e.status === 0) {
							message =
								'MicroService ' +
								service.name +
								' (' +
								service.url +
								') ist nicht erreichbar';
						} else {
							message =
								'MicroService ' +
								service.url +
								' konnte nicht geladen werden: ' +
								e;
						}
						console.log('error', message);
						notificationService.showError(message);
						return throwError(() => new Error(message));
						// return Observable.throw( message );
					}),
					share({
						connector: () => new ReplaySubject(1),
						resetOnError: false,
						resetOnComplete: false,
						resetOnRefCountZero: false
					}),
					map((r: HttpResponse<Object>) => {
						let result = {};
						const data: any = r.body;

						Object.keys(data._links).forEach((key: string) => {
							const link = data._links[key];
							if (key.indexOf(':') !== -1) {
								const resource: string = key.substring(key.indexOf(':') + 1);
								const curie: string = key.substring(0, key.indexOf(':'));
								if (!result[curie]) {
									result[curie] = {};
								}
								result[curie][resource] = this.processLink(link);
							}
						});

						// the backend writes an extra git commit hash header into each request , so on a request to /rest we load this information
						this.gitCommitHeader.next(r.headers.get('git.commit.id'));

						return { [service.name]: result };
					})
				)
			);
		});

		// http://reactivex.io/rxjs/class/es6/Observable.js~Observable.html#static-method-forkJoin
		// we need to "serialize" all rest endpoints into one observable
		this.resourceTree = forkJoin(services).pipe(
			map((e) => {
				let retVal = {};
				e.forEach((s) => {
					Object.assign(retVal, s);
				});
				return retVal;
			})
		);
	}

	processLink(halLink: IHalLinkData): IEnhancedLinkData {
		if (halLink instanceof Array) {
			return;
		}
		const template = uriTemplate(halLink.href);
		return {
			href: halLink.href,
			uriTemplate: <URITemplate>template,
			uri: template.expand({})
		};
	}

	decorateLinks(rsp: IHALObject) {
		for (let rel in rsp._links) {
			rsp._links[rel] = this.processLink(rsp._links[rel]);
		}
	}

	getResourceTree(): Subject<any> {
		return this.resourceTree;
	}
}
