import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AuthGuard } from './shared/auth-guard';

// DOC https://toddmotto.com/angular-component-router

@NgModule({
	imports: [
		RouterModule.forRoot([
			{ path: '', redirectTo: 'app', pathMatch: 'full' },
			{
				path: 'auths',
				loadChildren: () =>
					import(`./authentification/authentification.module`).then(
						(m) => m.AuthentificationModule
					)
			},
			{
				path: 'app',
				loadChildren: () => import(`./app/app.module`).then((m) => m.AppModule),
				canActivate: [AuthGuard],
				data: { authorization: { allow: ['ADMIN', 'USER'] } }
			},
			{ path: '**', redirectTo: 'auths' } // redirect route 404
		]) // , { enableTracing: true }) // debug only
	],
	exports: [RouterModule],
	declarations: []
})
export class RootRoutingModule {}
