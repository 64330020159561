import { Injectable } from '@angular/core';
import { ChartDefinitionBase } from './chart-definition-base';
import { IMachine } from '../api/domain-model';
import { MachineService } from '../api/machine.service';
import { NotificationService } from '../shared/notifications/notification.service';
import { ChartConfigService } from '../company/chart-config.service';
import { AuthService } from '../shared/auth-service';
import { TranslateService } from '../shared/translate';
import { IGaugeCardDefinition, IInterruptsTableCardDefinition, ISimpleContentCardDefinition } from '../charts/definition-model';
import CardDisplayVariables from '../common/domain/card-display-variables';
import { Identifiers, Util } from '../common/domain/bindings';

@Injectable()
export class ChartDefinitionTerramService extends ChartDefinitionBase {

	private machine: IMachine;

	constructor(
		public machineService: MachineService,
		public notificationService: NotificationService,
		public chartConfigService: ChartConfigService,
		public authService: AuthService,
		public translateService: TranslateService
	) {
		super();
	}

	setMachine(machine: IMachine): void {
		this.machine = machine;
	}

	getMachine(): IMachine {
		return this.machine;
	}

	getTypeChangesCardDefinition(): ISimpleContentCardDefinition {
		const requiredVariables = ['u16ProductChgDay', 'u16ProductChgBatch'];
		return <ISimpleContentCardDefinition>{
			neededValues: [requiredVariables],
			historyVariables: requiredVariables,
			title: 'A102TypeChanges',
			cardBatchTitle: 'A102TypeChangesBatch',
			unit: 'A020Count',
			displayVariable: CardDisplayVariables.terramCardDisplayVariables().productChangesToday,
		}
	}

	getEndOfBatchCardDefinition(): ISimpleContentCardDefinition {
		const requiredVariables = ['u16EndBatchDay', 'u16EndBatchDay'];
		return <ISimpleContentCardDefinition>{
			neededValues: [requiredVariables],
			historyVariables: requiredVariables,
			title: 'A103EndOfBatch',
			cardBatchTitle: 'A103EndOfBatch',
			unit: 'A020Count',
			displayVariable: CardDisplayVariables.terramCardDisplayVariables().endOfBatch,
		}
	}

	getLoadEmptyPalletsCardDefinition(): ISimpleContentCardDefinition {
		const requiredVariables = ['u16EmptyPalletCnt', 'u16EmptyPalletCntBatch'];
		return <ISimpleContentCardDefinition>{
			neededValues: [requiredVariables],
			historyVariables: requiredVariables,
			title: 'A105LoadEmptyPallets',
			cardBatchTitle: 'A105LoadEmptyPallets',
			unit: 'A020Count',
			displayVariable: CardDisplayVariables.terramCardDisplayVariables().feedEmptyPallets,
		}
	}

	getFullPalleteCardDefinition(): IGaugeCardDefinition {
		return <IGaugeCardDefinition>{
			chartOptions: this.chartConfigService.anyGaugeChartSpec(
				this.machine,
				'quantityFullPalletSpec',
				0,
				100,
				Identifiers.terramQuantityFullPalletCard,
				null
			).options,
			title: 'A104FullPalletCount',
			unit: 'A027Percent',
			historyVariables: Identifiers.terramQuantityFullPalletCard[2],
			colorHistoryVariables: Identifiers.terramQuantityFullPalletCard,
			displayVariable: CardDisplayVariables.terramCardDisplayVariables().fullPallets
		};
	}

	getDisruptCardDefinition(): IInterruptsTableCardDefinition {
		const requiredVariables = [...Identifiers.terramDisruptionsCard.countParams, ...Identifiers.terramDisruptionsCard.timeParams];
		return {
			neededValues: requiredVariables,
			historyVariables: ['u16EmergencyStopCnt'],
			title: 'A145Disrupt',
			cardBatchTitle: 'A284InterruptsBatch',
			unit: 'A124Minutes',
			machineType: '',
			countParams: Identifiers.terramDisruptionsCard.countParams,
			timeParams: Identifiers.terramDisruptionsCard.timeParams,
			titleParams: Identifiers.terramDisruptionsCard.titleParams,
			displayVariable: CardDisplayVariables.terramCardDisplayVariables().disrupts,
			historyNeededValues: Util.getHistoryNeededValues(Identifiers.terramDisruptionsCard.timeParams, Identifiers.terramDisruptionsCard.countParams, Identifiers.terramDisruptionsCard.titleParams)
		}
	}
}
