import { Injectable } from '@angular/core';
import { ChartDefinitionBase } from './chart-definition-base';
import { IMachine } from '../api/domain-model';
import { MachineService } from '../api/machine.service';
import { NotificationService } from '../shared/notifications/notification.service';
import { ChartConfigService } from '../company/chart-config.service';
import { AuthService } from '../shared/auth-service';
import { TranslateService } from '../shared/translate';
import { ICurrentProductionTableCardDefinition, ICycleTimeTableCardDefinition, IInterruptsWithMultiLineCardDefinition, IInterruptsTableCardDefinition, IPieChartCardDefinition, ISimpleContentCardDefinition } from '../charts/definition-model';
import CardDisplayVariables from '../common/domain/card-display-variables';
import { Identifiers, Util } from '../common/domain/bindings';
import { StackedAreaChartType } from '../charts/domain-model';

@Injectable()
export class ChartDefinitionFeigeGeneralService extends ChartDefinitionBase {

	private machine: IMachine;

	constructor(
		public machineService: MachineService,
		public notificationService: NotificationService,
		public chartConfigService: ChartConfigService,
		public authService: AuthService,
		public translateService: TranslateService
	) {
		super();
	}

	setMachine(machine: IMachine): void {
		this.machine = machine;
	}

	getMachine(): IMachine {
		return this.machine;
	}

	getTypeChangesTodayCardDefinition(): ISimpleContentCardDefinition {
		const requiredVariables = ['uiSortChange_day', 'uiSortChange_day'];
		return <ISimpleContentCardDefinition>{
			neededValues: [requiredVariables],
			historyVariables: requiredVariables,
			title: 'A035TypeChange',
			cardBatchTitle: 'A035TypeChangeBatch',
			unit: 'A020Count',
			displayVariable: CardDisplayVariables.feigeGeneralCardDisplayVariables().typeChangesToday,
		}
	}

	getInterruptsCardDefinition(): IInterruptsWithMultiLineCardDefinition {
		const requiredVariables = [
			...Identifiers.feigeGeneralInterruptionsTodayCard.countVariables,
			...Identifiers.feigeGeneralInterruptionsTodayCard.timeVariables
		];

		const multiLineChartSpec = this.chartConfigService.getInterruptionsMultiLineChartConfig(Identifiers.feigeGeneralInterruptionsTodayCard.timeVariables);

		return {
			neededValues: requiredVariables,
			historyVariables: ['uiErrCountProdIn_day'],
			title: 'A284Interrupts',
			cardBatchTitle: 'A284InterruptsBatch',
			unit: 'A124Minutes',
			countParams: Identifiers.feigeGeneralInterruptionsTodayCard.countVariables,
			timeParams: Identifiers.feigeGeneralInterruptionsTodayCard.timeVariables,
			titleParams: Identifiers.feigeGeneralInterruptionsTodayCard.titleVariables,
			machineType: 'feigegeneral',
			displayVariable: CardDisplayVariables.feigeGeneralCardDisplayVariables().interruptionToday,
			multiLineChartSpec,
			historyNeededValues: Util.getHistoryNeededValues(Identifiers.feigeGeneralInterruptionsTodayCard.timeVariables, Identifiers.feigeGeneralInterruptionsTodayCard.countVariables, Identifiers.feigeGeneralInterruptionsTodayCard.titleVariables)
		}
	}

	getCurrentProductionCardDefinition(): ICurrentProductionTableCardDefinition {
		const requiredVariables = Identifiers.feigeGeneralCurrentProductionCard.variables;
		return {
			neededValues: requiredVariables,
			historyVariables: ['uiRemainingFill'],
			title: 'F006_CurrentProduction',
			cardBatchTitle: 'F006_CurrentProduction',
			unit: '',
			titleParams: Identifiers.feigeGeneralCurrentProductionCard.titleParams,
			displayVariable: CardDisplayVariables.feigeGeneralCardDisplayVariables().currentProduction,
		}
	}

	getCycleTimeCardDefinition(): ICycleTimeTableCardDefinition {
		let titleParams = [];
		Identifiers.feigeGeneralCycleTimeMachineCard.titleParams.forEach(
			(titleParam) =>
				titleParams.push(this.translateService.instant(titleParam))
		);

		const cycleTimeMachineStackedAreaChartSpec = {
			column: {
				category: 'Items',
				values: titleParams,
				countAlias: ['22']
			},
			neededValues: Identifiers.feigeGeneralCycleTimeMachineCard.variables,
			vAxis: {title: 'A116CycleTime'},
			hideData: {
				isHidden: true,
				value: 65535
			},
			transformData: {
				applyTransformFunction: true,
				transformFunction: (value: number) => value / 10
			}
		} as StackedAreaChartType;

		const requiredVariables = Identifiers.feigeGeneralCycleTimeMachineCard.variables;
		return {
			neededValues: requiredVariables,
			historyVariables: ['uiCycleSum_day'],
			title: 'A116CycleTime',
			cardBatchTitle: 'A116CycleTime',
			unit: '',
			titleParams: Identifiers.feigeGeneralCycleTimeMachineCard.titleParams,
			stackedAreaChartSpec: cycleTimeMachineStackedAreaChartSpec,
			displayVariable: CardDisplayVariables.feigeGeneralCardDisplayVariables().cycleTimeMachine,
		}
	}


	getFillingStatisticsCardDefinition(): IPieChartCardDefinition {
		let titleParams = [];
		Identifiers.feigeGeneralFullStatisticsCard.titleParams.forEach(
			(titleParam) =>
				titleParams.push(this.translateService.instant(titleParam))
		);
		const fillStatisticsStackedAreaChartSpec = {
			column: {
				category: 'Items',
				values: titleParams,
				countAlias: ['7']
			},
			neededValues: Identifiers.feigeGeneralFullStatisticsCard.variables,
			vAxis: {title: 'A020Count'},
			hideData: {
				isHidden: true,
				value: 65535
			},
			transformData: {
				applyTransformFunction: false,
				transformFunction: (value: number) => value
			}
		} as StackedAreaChartType;

		const requiredVariables = Identifiers.feigeGeneralFullStatisticsCard.variables;
		return {
			neededValues: requiredVariables,
			historyVariables: ['diTU2_day'],
			title: 'F007_FillStatistic',
			cardBatchTitle: 'F007_FillStatistic',
			unit: 'A020Count',
			titleParams: Identifiers.feigeGeneralFullStatisticsCard.titleParams,
			stackedAreaChartSpec: fillStatisticsStackedAreaChartSpec,
			displayVariable: CardDisplayVariables.feigeGeneralCardDisplayVariables().fillingStatitics,
		}
	}
}
