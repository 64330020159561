import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of, ReplaySubject, Subject, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { User } from '../api/domain-impl';
import { MqttService } from '../api/mqtt.service';
import { Base64 } from './base64';
import { Constants } from './Constants';
import { Credentials } from './credentials';
import { HalDiscoveryService } from './hal/hal-discovery.service';
import { StorageService } from './storage-service';

@Injectable({ providedIn: 'root' })
export class AuthService {
	private encoder: Base64 = new Base64();
	private _isLoggedIn = false;
	private headerName = 'Authorization';
	private currentUserSubject: Subject<User> = new ReplaySubject<User>(1);
	public currentUserSubjectSelect: Subject<User> = new ReplaySubject<User>(1);
	private _currentUser: User;
	private tokenRefreshTimer: NodeJS.Timer;
	private logoutTimer: NodeJS.Timer;
	public redirectUrl: string;
	private accessTokenValidity = 0;
	private isRefreshing = false;
	private DEFAULT_TRYS_PER_VALIDITY = 5;
	private unsuccessfulTokenRefreshes = 0;

	constructor(
		private disco: HalDiscoveryService,
		private mqttService: MqttService,
		private router: Router,
		private route: ActivatedRoute,
		private storageService: StorageService,
		private http: HttpClient
	) {
		const loginResult = new Subject<any>();

		const authTokens = this.storageService.getToken();
		if (authTokens) {
			this.initiateTokenRefresh(loginResult);
		} else {
			this.logout();
		}
	}

	private initiateTokenRefresh<T>(loginResult: Subject<T>) {
		this.refreshToken().subscribe({
			next: (tokenResponse) => {
				if (tokenResponse) {
					this.processAfterLogin(tokenResponse, loginResult);
				}
			},
			error: (err) => {
				loginResult.error(err.error);
				return this.logOutAndRedirect();
			}
		});
	}

	set isLoggedIn(value: boolean) {
		this._isLoggedIn = value;
	}

	get isLoggedIn(): boolean {
		return this._isLoggedIn;
	}

	/**
	 * returns the static user instead of a promise. This is safe to call after the application has been
	 * initialized and the implicit or explicit login method succeeded. Use in RouteGuard with caution as they
	 * might be called before the auth service finished.
	 * @returns {User}
	 */
	get userSnapshot(): User {
		return this._currentUser;
	}

	showDebugData(): boolean {
		try {
			return this.userSnapshot.username == 'iot-plattform@blu-pa.com2' || this.route.snapshot.queryParamMap.get('debug') == 'true';
		} catch {
			console.log('currentUser was already deleted');
			return false;
		}
	}

	get currentUser(): Subject<User> {
		return this.currentUserSubject;
	}

	login(credentials: Credentials) {
		const loginResult = new Subject<any>();
		const header = new HttpHeaders()
			.set(this.headerName, 'Basic ' + this.encoder.encode(`${environment.authService.clientId}:${environment.authService.clientPassword}`))
			.set('Content-Type', 'application/json');

		this.disco.getResourceTree().subscribe((api) => {
			let loginUrl = `${environment.authService.url}/oauth/login`;
			if (credentials.rememberMe) {
				loginUrl = loginUrl + '?remember-me=true';
			}
			this.http
				.post(
					loginUrl,
					{
						username: credentials.login,
						password: credentials.password,
						sessionId: localStorage.getItem('generatedSessionId')
					},
					{ headers: header, observe: 'response' }
				)
				.subscribe({
					next: (res) => {
						this.enableRememberMeIfNeeded(credentials.rememberMe);

						this.processAfterLogin(res, loginResult);
					},
					error: (e) => {
						console.log('error', e);
						if (e.message) {
							loginResult.error(e.message);
						} else {
							console.log('error: ', e, ' has no error message from the server, check request');
						}
						//this.logOutAndRedirect();
					}
				});
		});
		return loginResult;
	}

	private enableRememberMeIfNeeded(rememberMe: boolean) {
		if (!rememberMe) {
			return;
		}

		this.storageService.setRememberMeEnabled();
	}

	getUserInfo() {
		const loginResult = new Subject<any>();

		this.disco.getResourceTree().subscribe((api) => {
			this.http.get(api.metrics.hb.whoami.uri).subscribe({
				next: (response) => {
					this.isLoggedIn = true;
					this._currentUser = new User(response);
					this._currentUser.selectedCompany = this._currentUser.companyId;
					this.currentUserSubject.next(this._currentUser);
					this.currentUserSubjectSelect.next(this._currentUser);
					localStorage.setItem(Constants.COUNTRYKEY, localStorage.getItem(Constants.COUNTRYKEY) || 'en');
					localStorage.setItem(Constants.COUNTRYNAMEKEY, localStorage.getItem(Constants.COUNTRYNAMEKEY) || 'A003English');
					this.disco.decorateLinks(response);
					loginResult.next(this._currentUser);
					this.mqttService.subscribeComms(this._currentUser.userId);
				},
				error: (error) => {
					console.log('error', error);
					if (error._body) {
						loginResult.error(JSON.parse(error._body).message);
					}
					this.logOutAndRedirect();
				}
			});
		});
		return loginResult;
	}

	refreshToken(): Observable<any> {
		/*
			The call that goes in here will use the existing refresh token to call a method on the oAuth server
			(usually called refreshToken) to get a new authorization token for the API calls.
		*/
		let refreshToken = '';
		const authTokens = this.storageService.getToken();
		if (authTokens) {
			const authT = JSON.parse(authTokens);
			refreshToken = authT.refresh_token;
		} else {
			return of(null);
		}

		const data = new HttpParams().append('refresh_token', refreshToken).append('grant_type', 'refresh_token');
		const header = new HttpHeaders()
			.set(this.headerName, 'Basic ' + this.encoder.encode(`${environment.authService.clientId}:${environment.authService.clientPassword}`))
			.set('Content-Type', 'application/x-www-form-urlencoded');

		return this.http
			.post(`${environment.authService.url}/oauth/token`, data.toString(), {
				headers: header,
				observe: 'response'
			})
			.pipe(
				map((res) => {
					if (res && res.body['access_token']) {
						// This will replace the old tokens
						this.storageService.saveToken(JSON.stringify(res.body));

						//console.log('Tokens refreshed!');
						this.tokenRefreshSuccessful();

						return res;
					} else {
						console.log('Failed to refresh Tokens: Server returned no access_token');
						try {
							this.tokenRefreshFailed();
							return of(null);
						} catch (err) {
							return throwError(err);
						}
					}
				}),
				catchError((err) => {
					console.log('Failed to refresh Tokens:', err);
					try {
						this.tokenRefreshFailed();
						return of(null);
					} catch (err) {
						return throwError(err);
					}
				})
			);
	}

	private tokenRefreshSuccessful() {
		this.resetLogoutTimer();
		if (this.unsuccessfulTokenRefreshes > 0) {
			this.resetTokenRefreshTimer(this.DEFAULT_TRYS_PER_VALIDITY);
			this.unsuccessfulTokenRefreshes = 0;
		}
	}

	private tokenRefreshFailed() {
		this.unsuccessfulTokenRefreshes++;
		const trys = this.accessTokenValidity / Math.pow(2, this.unsuccessfulTokenRefreshes); // 2s, 4s, 8s, 16s...
		if (trys < this.DEFAULT_TRYS_PER_VALIDITY) {
			this.resetTokenRefreshTimer(this.DEFAULT_TRYS_PER_VALIDITY);
		} else {
			this.resetTokenRefreshTimer(trys);
		}
	}

	processAfterLogin(res, loginResult) {
		if (res && res.body && res.body['access_token']) {
			this.accessTokenValidity = res.body['expires_in'];

			// store user details and jwt token in storage to keep user logged in between page refreshes
			this.storageService.saveToken(JSON.stringify(res.body));

			this.getUserInfo().subscribe((next) => {
				loginResult.next(next);
			});

			this.mqttService.startPeriodicConnect();

			this.startLogoutTimer();
			this.startTokenRefreshTimer(this.DEFAULT_TRYS_PER_VALIDITY);
		}
	}

	private startLogoutTimer() {
		this.logoutTimer = setInterval(() => {
			if (!this.isLoggedIn) {
				//console.log('Already logged out: Clear timers');
				clearInterval(this.logoutTimer);
				clearInterval(this.tokenRefreshTimer);
				return;
			} else {
				console.log('Tokens not valid anymore: Logout');
				this.logOutAndRedirect();
			}
		}, this.accessTokenValidity * 1000);
	}

	private resetLogoutTimer() {
		if (this.logoutTimer) {
			clearInterval(this.logoutTimer);
			this.startLogoutTimer();
		}
	}

	private clearLogoutTimer() {
		if (this.logoutTimer) {
			//console.log('Clear Logout Timer');
			clearInterval(this.logoutTimer);
		}
	}

	startTokenRefreshTimer(trysPerValidity: number) {
		this.tokenRefreshTimer = setInterval(() => {
			if (!this.isLoggedIn) {
				// if user still not loggedIn, ask him to login again
				this.logOutAndRedirect();
			}

			if (this.isRefreshing) {
				return;
			}

			this.isRefreshing = true;
			this.refreshToken().subscribe((_) => (this.isRefreshing = false));
		}, (this.accessTokenValidity / trysPerValidity) * 1000);
	}

	clearTokenRefreshTimer() {
		if (this.tokenRefreshTimer) {
			clearInterval(this.tokenRefreshTimer);
		}
	}

	private resetTokenRefreshTimer(trysPerValidity) {
		this.clearTokenRefreshTimer();
		this.startTokenRefreshTimer(trysPerValidity);
	}

	logout() {
		this.disconnectMqtt();
		this.clearTimers();
		this.clearLoginInfo();
	}

	private clearTimers() {
		this.clearTokenRefreshTimer();
		this.clearLogoutTimer();
	}

	private disconnectMqtt() {
		this.mqttService.stopPeriodicConnect();
		this.mqttService.disconnect();
	}

	private clearLoginInfo() {
		document.cookie = this.storageService.getStorageKey() + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
		this.storageService.clearAuthInfo();

		this.isLoggedIn = false;
		this._currentUser = null;
		this.currentUserSubject.next(null);
	}

	logOutAndRedirect() {
		this.logout();
		this.router.navigateByUrl('/auths');
	}

	getCookie(name) {
		const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
		if (match) {
			return match[2];
		}
	}

	changePassword(newPassword) {
		this._currentUser.password = newPassword;
	}
}
