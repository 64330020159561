/*
Angular Documentation: CoreModule

Create a CoreModule with providers for the singleton services you load when the application starts.
Import CoreModule in the root AppModule only. Never import CoreModule in any other module.
Consider making CoreModule a pure services module with no declarations.

Basically Singletons should go here
*/

import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { GlobalsharedModule } from 'app/globalshared/globalshared.module';
import { HttpClientModule } from '@angular/common/http';
import { TranslateModule } from 'app/translate/translate.module';
import { DirectivesModule } from './directives.module';
import { PipesModule } from './pipes.module';
import { HttpServantService } from 'app/api/http-servant.service';

@NgModule({
	imports: [
		CommonModule, // we use ngFor
		RouterModule, // because we use <router-outlet> and routerLink
		GlobalsharedModule,
		HttpClientModule,
		TranslateModule,
		DirectivesModule,
		PipesModule
	],
	exports: [PipesModule],
	declarations: [],
	providers: [HttpServantService]
})
export class CoreModule {
	constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
		if (parentModule) {
			throw new Error(
				'CoreModule is already loaded. Import it in the AppModule only'
			);
		}
	}
}
