export interface IotMessage {
	value: number | string | boolean;
	timestamp_created: number;
	timestamp_measurement: number;
	identifier: string;
	destinationName: string;
	type: types;
	when?: number;
}

export enum types {
	uint16 = 'uint16',
	uint32 = 'uint32'
}
