// no way - importing polyfill is wrong .... import './polyfills';
import 'hammerjs';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { RootModule } from './app/root.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

// , {useDebug: false} works not more angular 6
// useDebug will add ngReflects to the dom nodes
platformBrowserDynamic().bootstrapModule(RootModule).catch(err => console.error(err));;
