const CardDisplayVariables = {

	generalCardDisplayVariables() {
		return {
			production: 'bProdVis',
			availability: 'bAvialVis',
			maintenance: 'bMachMaintVis',
			productionTime: 'bProdTimeVis',
			productionTimeBaumit: 'bProdTimeBaumitVis',
			interruptionTime: 'bInterruptionTimeVis',
			productionToday: 'bProdDayVis',
			totalProduction: 'bTotProdVis',
			lastReset: 'bLastResetVis',
			faultsTodayCount: 'bFaultCntVis',
			faultsTodayDuration: 'bFaultTimeVis',
			energyMeasurement: 'bEnergyMeasurement'
		};
	},

	miniAdamsCardDisplayVariables() {
		return {
			container: 'bContainerVis',
			typeChangesToday: 'bTypeChangesVis',
			emptyBagRejectsToday: 'bEmptyBagRejVis',
			fullBagRejectsToday: 'bFullBagRejVis',
			interruptionsToday: 'bInterruptsVis',
			speedRelatedProduction: 'bRelatedProdVis',
			goodProduction: 'bGoodProdVis',
			productionToday: 'bProdDay2Vis',
			weightData: 'bWeightDataVis',
			stdDevData: 'bStdDevVis'
		};
	},

	terramCardDisplayVariables() {
		return {
			disrupts: 'bInterruptsVis',
			productChangesToday: 'bTypeChangesVis',
			endOfBatch: 'bEndBatchVis',
			production: 'bProdDay2Vis',
			fullPallets: 'bFullPalletVis',
			feedEmptyPallets: 'bEmptyPalletVis'
		};
	},

	integraCardDisplayVariables() {
		return {
			goodProduction: 'bGoodProdVis',
			bagQuality: 'bBagQualityVis',
			faults: 'bInterruptsVis',
			numberOfBundleChangesTotal: 'bTotalBundleChangeVis',
			numberOfBundleChangesToday: 'bDayBundleChangeVis',
			numberOfBagsPerBundle: 'bBagsPerBundleVis',
			typesChangesToday: 'bTypeChangesVis',
			fillingTime: 'bFillTimeVis',
			weightData: 'bWeightDataVis',
			stdDevData: 'bStdDevVis'
		};
	},

	rvt8rotopackerCardDisplayVariables() {
		return {
			bagCounterToday: 'bBagCntDayVis',
			notPlacedBags: 'bNotPlacedVis',
			typeChangesToday: 'bTypeChangesVis',
			spreadBagCounter: 'bSpeadVis',
			rejectsToday: 'bFullBagRejVis',
			interruptionsToday: 'bInterruptsVis',
			speedRelatedProduction: 'bRelatedProdVis',
			goodProduction: 'bGoodProdVis',
			weightData: 'bWeightDataVis',
			stdDevData: 'bStdDevVis'
		};
	},

	isf1CardDisplayVariables() {
		return {
			bagCounterToday: 'bBagCntDayVis',
			weightData: 'bWeightDataVis',
			stdDevData: 'bStdDevVis',
			notPlacedBags: 'bNotPlacedVis',
			productionToday: 'bProd2Vis',
			interruptionsToday: 'bInterruptsVis',
			sortChangesToday: 'bTypeChangesVis',
			production: 'bProdDay2Vis',
			totalCyclesToday: 'bTotalCyclesVis',
			metalDetectorToday: 'bMetalDetectorVis',
			goodProduction: 'bGoodProdVis',
			rejectsToday: 'bFullBagRejVis',
			rejectedIncorrectWeights: 'bWrongWeightVis',
			rejectedOpenBags: 'bOpenBagVis',
			rejectedSampleBags: 'bSampleBagsVis'
		};
	},

	ivtCardDisplayVariables() {
		return {
			bagCounterToday: 'bBagCntDayVis',
			notPlacedBags: 'bNotPlacedVis',
			typeChangesToday: 'bTypeChangesVis',
			newReloadEmptyBagMagazineToday: 'bTotalBundleChangeVis',
			rejectsToday: 'bFullBagRejVis',
			interruptionsToday: 'bInterruptsVis',
			collectiveFaultsToday: 'bFaultGeneralVis',
			goodProduction: 'bGoodProdVis',
			weightData: 'bWeightDataVis',
			stdDevData: 'bStdDevVis'
		};
	},

	evpCardDisplayVariables() {
		return {
			bagCounterToday: 'bBagCntDayVis',
			notPlacedBags: 'bNotPlacedVis',
			typeChangesToday: 'bTypeChangesVis',
			reloadEmptyBagMagazineToday: 'bTotalBundleChangeVis',
			rejectsToday: 'bFullBagRejVis',
			interruptionsToday: 'bInterruptsVis',
			collectedFaultsToday: 'bFaultGeneralVis',
			goodProduction: 'bGoodProdVis',
			faultsPressureToday: 'bFaultsPresVis',
			strokePowderPumpToday: 'bStrokesPumpVis',
			maxStrokeDurationToday: 'bMaxStrokeDurVis',
			avgNoStrokesPerBagToday: 'bAvgStrokesVis',
			internalPressure: 'bIntPressVis',
			weightData: 'bWeightDataVis',
			stdDevData: 'bStdDevVis'
		};
	},

	egfCardDisplayVariables() {
		return {
			fillingTime: 'bFillTimeVis',
			goodProduction: 'bGoodProdVis',
			incorrectWeight: 'bWrongWeightVis',
			interruptonsToday: 'bInterruptsVis',
			typeChangesToday: 'bTypeChangesVis',
			weightData: 'bWeightDataVis',
			stdDevData: 'bStdDevVis'
		};
	},

	haverAdamsCardDisplayVariables() {
		return {
			bagCounterToday: 'bBagCntDayVis',
			weightData: 'bWeightDataVis',
			stdDevData: 'bStdDevVis',
			notPlacedBags: 'bNotPlacedVis',
			productionToday: 'bProdDay2Vis',
			wrongApplicatedBags: 'bNotPlaced2Vis',
			interruptionsToday: 'bInterruptsVis',
			rejectsToday: 'bFullBagRejVis',
			goodProduction: 'bGoodProdVis',
			reelChangeToday: 'bReelChgVis',
			typeChangesToday: 'bTypeChangesVis',
			rejectedIncorrectWeights: 'bWrongWeightVis',
			rejectedOpenBags: 'bOpenBagVis',
			rejectedSampleBags: 'bSampleBagsVis',
			currentType: 'bCurrTypeVis'
		};
	},

	neutraleCardDisplayVariables() {
		return {
			bagCounterToday: 'bBagCntDayVis',
			fillingTime: 'bFillTimeVis',
			goodProduction: 'bGoodProdVis',
			incorrectWeight: 'bWrongWeightVis',
			interruptionsToday: 'bInterruptsVis',
			typeChangesToday: 'bTypeChangesVis',
			weightData: 'bWeightDataVis',
			stdDevData: 'bStdDevVis'
		};
	},

	feigeGeneralCardDisplayVariables() {
		return {
			currentProduction: 'bCurrProdVis',
			cycleTimeMachine: 'bCycleTimeVis',
			fillingStatitics: 'bFillStatisticVis',
			interruptionToday: 'bInterruptsVis',
			typeChangesToday: 'bTypeChangesVis'
		};
	},

	newTecPalletizerCardDisplayVariables() {
		return {
			interruptionsToday: 'bInterruptsVis',
			palletizer: 'bPalletizerVis',
			emptyPalletArea: 'bEmptyPalletAreaVis',
			bagTransportArea: 'bBagTransportAreaVis',
			fullPalletArea: 'bFullPalAreaVis',
			sheetDispenserArea: 'bSheetDispAreaVis',
			productionTime: 'bProdTimeSpecificVis',
			programChangesToday: 'bProgramChangesVis',
			endOfBatch: 'bEndOfBatchVis',
			production: 'bProd2Vis',
			feedOfEmptyPalletes: 'bEmptyPalFeedVis',
			bagProduction: 'bBagProdVis',
			palletProduction: 'bPalProdVis'
		};
	},

	scarabeusCardDisplayVariables() {
		return {
			powerInput: 'bPowerInputVis',
			temperatures: 'bTemperatureVis',
			operationHours: 'bOperationHourVis',
			operationMode: 'bOperationModeVis',
			typeChanges: 'bProgramChangesVis',
			waterAdd: 'bWaterAdditionVis',
			moisture: 'bMoistureVis',
			boardHeight: 'bBoardHeightVis',
			inclination: 'bInclinationVis',
			speed: 'bSpeedVis',
			capacity: 'bCapacityVis',
			product: 'bProductInRangeVis',
			undersized: 'bUndersizedVis',
			oversized: 'bOversizedVis',
			production: 'bProd2Vis'
		};
	},

	energyTabCardDisplayVariables() {
		return {
			voltages: 'bVoltagesVis',
			currents: 'bCurrentVis',
			frequency: 'bFrequencyVis',
			pressure: 'bPressureVis',
			moisture: 'bMoistureVis',
			temperature: 'bTemperatureVis',
			airflow: 'bAirflowVis',
			annualPowerConsumption: 'bAnnualPowerConsumptionVis',
			monthlyPowerConsumption: 'bMonthlyPowerConsumptionVis',
			freePowerConsumption: 'bFreePowerConsumptionVis',
			powerEfficiency: 'bPowerEfficiencyVis',
			annualAirConsumption: 'bAnnualAirConsumptionVis',
			monthlyAirConsumption: 'bMonthlyAirConsumptionVis',
			freeAirConsumption: 'bFreeAirConsumptionVis',
			airEfficiency: 'bAirEfficiencyVis',
		};
	},
};

export default CardDisplayVariables;
