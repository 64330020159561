import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { IMachine } from './api/domain-model';
import { IAdditionalCardInformation } from './company/machine-detail-maintenance/machine-detail-maintenance.component';
import { IColumnChartOptions } from './charts/domain-model';
import { ChartSpec } from './company';

@Injectable()
export class ConfignavService {
	private sidenav = new Subject<any>();
	private machine = new Subject<any>();
	private chartIndex = new Subject<any>();
	private isOpen = new Subject<boolean>();
	public alreadyClose: Boolean = false;
	private maintenanceCard = new Subject<any>();
	private flag: boolean = false;

	toggleSideNav() {
		this.sidenav.next(null);
		// To get the current status of congig sidenav
		this.flag = this.flag ? false : true;
		this.alreadyClose = !this.flag;
		this.isOpen.next(this.flag);
	}

	setmachine(machine: IMachine) {
		this.machine.next(machine);
	}

	setChartIndex(index: number) {
		this.chartIndex.next(index);
	}

	getSidenavObservable(): Observable<any> {
		return this.sidenav.asObservable();
	}

	getMachineObservable(): Observable<any> {
		return this.machine.asObservable();
	}

	getChartIndexObservable(): Observable<any> {
		return this.chartIndex.asObservable();
	}

	isSidenavOpen(): Observable<boolean> {
		return this.isOpen;
	}

	setmaintenanceCard(
		additionalInformation: IAdditionalCardInformation,
		spec: ChartSpec<IColumnChartOptions>
	) {
		this.maintenanceCard.next({
			additionalInformation: additionalInformation,
			spec: spec
		});
	}
	getmaintenanceCardObservable(): Observable<any> {
		return this.maintenanceCard.asObservable();
	}
}
