/**
 * translate.service.ts
 */
import { Injectable, Inject } from '@angular/core';
import { TRANSLATIONS } from './translations';
import { CountryList } from '../translate/translations';
import { debugOutputAstAsTypeScript } from '@angular/compiler';

@Injectable()
export class TranslateService {
	private _currentLang: string;

	get lang() {
		return window.location.pathname.split('/')[2];		// from URL
	}

	public get currentLang() {
		return this._currentLang;
	}

	constructor(@Inject(TRANSLATIONS) private _translations: any) {
		this._currentLang = localStorage.getItem('countryKey') || 'de';
	}

	public use(lang: string): void {
		// set current language
		this._currentLang = lang;
	}

	private translate(key: string, lang: string): string {
		let translatedValue;
		if (CountryList.find(element => { return element.key === lang })) {
			translatedValue = this._translations[lang][key];
		}

		// private perform translation
		//  let translation = key;
		if (!translatedValue && this._translations[this.lang] && this._translations[this.currentLang][key]) {
			translatedValue = this._translations[this.lang][key];
		}

		if (!translatedValue && !CountryList.find(element => element.key === lang)) {
			translatedValue = this._translations['en'][key];
		}

		return translatedValue || '!' + key;
	}

	public instant(key: string, lang?: string) {
		// public perform translation
		if (key == null) {
			return 'key is null';
		}
		if (!lang) {
			lang = this.lang;
		}
		return this.translate(key, lang);
	}
}
