import { EventEmitter, Injectable, Output } from '@angular/core';
import { Notification, NotificationType } from './notification';

@Injectable({ providedIn: 'root' })
export class NotificationService {
	private _notifications: Array<Notification> = [];
	@Output() notificationChange: EventEmitter<boolean> = new EventEmitter();

	constructor() {}

	showOK(message: string) {
		let notification = new Notification(message, NotificationType.Info);
		this._notifications.push(notification);
		this.notificationChange.emit(false);
	}

	showError(error: string) {
		let notification = new Notification(error, NotificationType.Error);
		this._notifications.push(notification);
		this.notificationChange.emit(true);
	}

	dismiss(notification: Notification) {
		let idx = this._notifications.indexOf(notification);
		if (idx !== -1) {
			this._notifications.splice(idx, 1);
		}
	}

	dismissAll() {
		this._notifications = [];
	}

	hasNotifications() {
		return this._notifications.length > 0;
	}

	get notifications(): Array<Notification> {
		return this._notifications;
	}
}
