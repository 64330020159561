import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { HalDiscoveryService } from '../shared/hal/hal-discovery.service';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

@Injectable()
export class FirebaseService {
	public currentMessage = new BehaviorSubject(null);

	constructor(
		private disco: HalDiscoveryService,
		private angularFireMessaging: AngularFireMessaging,
		private http: HttpClient
	) {}

	requestPermission() {
		this.angularFireMessaging.requestToken.subscribe({
			next: (token) => {
				this.disco.getResourceTree().subscribe((api) => {
					// Token can be null if the user blocked the notifications on front end.
					// It seems angularFireMessaging.requestToken observable returns token only if user permitted for notifications on browser.
					if (!token) {
						return;
					}

					// here we are checking if the token already exists in the backend
					// if it does exist (result code 200), then no action is required
					// if the token does not exist (result code 404), then we need to store this token once again in the backend
					this.http
						.get(api.metrics.hb.firebaseToken.uri, { params: { token } })
						.pipe(
							catchError((err, caught) => {
								// result = 404 -- token does not exist in backend
								const localToken = localStorage.getItem('firebaseToken');

								if (!localToken || localToken !== token) {
									localStorage.setItem('firebaseToken', token);
									this.http
										.post(api.metrics.hb.firebaseToken.uri, { token })
										.subscribe({
											next: () => {},
											error: (e) => {
												console.log('error', e);
											}
										});
								} else {
									this.http
										.post(api.metrics.hb.firebaseToken.uri, {
											token: localToken
										})
										.subscribe({
											next: () => {},
											error: (e) => {
												console.log('error', e);
											}
										});
								}

								return of([]); // this creates a new observable in the subscribe(...) section
							})
						)
						.subscribe((result) => {
							// result = 200 -- token exists in backend - do nothing
						});
				});
			},
			error: (e) => {
				console.error('Unable to get permission to notify.', e);
			}
		});
	}

	receiveMessage() {
		this.angularFireMessaging.messages.subscribe((payload: any) => {
			this.currentMessage.next(payload);
		});
	}
}
