import { Injectable } from '@angular/core';
import { ChartDefinitionBase } from './chart-definition-base';
import { IMachine } from '../api/domain-model';
import { MachineService } from '../api/machine.service';
import { NotificationService } from '../shared/notifications/notification.service';
import { ChartConfigService } from '../company/chart-config.service';
import { AuthService } from '../shared/auth-service';
import { TranslateService } from '../shared/translate';
import { IProductionScarabaeusTableCardDefinition, IRelativeGaugeCardDefinition, ISimpleBooleanContentCardDefinition, ISimpleContentCardDefinition, ITemperatureScarabaeusTableCardDefinition, } from '../charts/definition-model';
import CardDisplayVariables from '../common/domain/card-display-variables';
import { Identifiers } from '../common/domain/bindings';
import { StackedAreaChartType } from '../charts/domain-model';

@Injectable()
export class ChartDefinitionScarabaeusService extends ChartDefinitionBase {

	private machine: IMachine;

	constructor(
		public machineService: MachineService,
		public notificationService: NotificationService,
		public chartConfigService: ChartConfigService,
		public authService: AuthService,
		public translateService: TranslateService
	) {
		super();
	}

	setMachine(machine: IMachine): void {
		this.machine = machine;
	}

	getMachine(): IMachine {
		return this.machine;
	}

	getSpeedCardDefinition(): ISimpleContentCardDefinition {
		const requiredVariables = ['u16Speed', 'u16Speed'];
		return <ISimpleContentCardDefinition>{
			neededValues: [requiredVariables],
			historyVariables: requiredVariables,
			title: 'N104Speed',
			cardBatchTitle: 'N104Speed',
			unit: 'A512RPM',
			displayVariable: CardDisplayVariables.scarabeusCardDisplayVariables().speed,
		}
	}

	getInclinationCardDefinition(): ISimpleContentCardDefinition {
		const requiredVariables = ['u16Inclination', 'u16Inclination'];
		return <ISimpleContentCardDefinition>{
			neededValues: [requiredVariables],
			historyVariables: requiredVariables,
			title: 'N105Inclination',
			cardBatchTitle: 'N105Inclination',
			unit: 'N107Degree',
			displayVariable: CardDisplayVariables.scarabeusCardDisplayVariables().inclination,
		}
	}

	getBoardHeightCardDefinition(): ISimpleContentCardDefinition {
		const requiredVariables = ['u16BoardHeight', 'u16BoardHeight'];
		return <ISimpleContentCardDefinition>{
			neededValues: [requiredVariables],
			historyVariables: requiredVariables,
			title: 'N106BoardHeigh',
			cardBatchTitle: 'N106BoardHeigh',
			unit: 'N053MM',
			displayVariable: CardDisplayVariables.scarabeusCardDisplayVariables().boardHeight,
		}
	}

	getWaterAddCardDefinition(): ISimpleContentCardDefinition {
		const requiredVariables = ['u16WaterAddition', 'u16WaterAddition'];
		return <ISimpleContentCardDefinition>{
			neededValues: [requiredVariables],
			historyVariables: requiredVariables,
			title: 'N108WaterAdd',
			cardBatchTitle: 'N108WaterAdd',
			unit: 'N106LperH',
			displayVariable: CardDisplayVariables.scarabeusCardDisplayVariables().waterAdd,
		}
	}

	getTypeChangesCardDefinition(): ISimpleContentCardDefinition {
		const requiredVariables = ['u16ProductChgDay', 'u16ProductChgBatch'];
		return <ISimpleContentCardDefinition>{
			neededValues: [requiredVariables],
			historyVariables: requiredVariables,
			title: 'A035TypeChange',
			cardBatchTitle: 'A035TypeChange',
			unit: 'A020Count',
			displayVariable: CardDisplayVariables.scarabeusCardDisplayVariables().typeChanges,
		}
	}

	getOperationHoursCardDefinition(): ISimpleContentCardDefinition {
		const requiredVariables = ['u16OperationHours', 'u16OperationHours'];
		return <ISimpleContentCardDefinition>{
			neededValues: [requiredVariables],
			historyVariables: requiredVariables,
			title: 'N111OpHours',
			cardBatchTitle: 'N111OpHours',
			unit: 'A228Hours',
			displayVariable: CardDisplayVariables.scarabeusCardDisplayVariables().operationHours,
		}
	}

	getPowerInputCardDefinition(): ISimpleContentCardDefinition {
		const requiredVariables = ['u16PowerInput', 'u16PowerInput'];
		return <ISimpleContentCardDefinition>{
			neededValues: [requiredVariables],
			historyVariables: requiredVariables,
			title: 'N117PowerInput',
			cardBatchTitle: 'N117PowerInput',
			unit: 'A306KW',
			displayVariable: CardDisplayVariables.scarabeusCardDisplayVariables().powerInput,
		}
	}

	getMoistureCardDefinition(): IRelativeGaugeCardDefinition {
		const chartOptionsDef = this.chartConfigService.anyGaugeChartSpec(
			this.machine,
			'quantityFullPalletSpec',
			0,
			100,
			Identifiers.moistureCardVariables,
			null
		);
		chartOptionsDef.options.isFivePointChart = true;
		chartOptionsDef.options.fixFloatingPointNumbers = true;
		return <IRelativeGaugeCardDefinition>{
			chartOptions: chartOptionsDef.options,
			title: 'N107Moisture',
			unit: 'A027Percent',
			historyVariables: Identifiers.moistureCardVariables[2],
			colorHistoryVariables: Identifiers.moistureCardVariables,
			displayVariable: CardDisplayVariables.scarabeusCardDisplayVariables().moisture
		};
	}

	getCapacityCardDefinition(): IRelativeGaugeCardDefinition {
		const capacityChartSpec = this.chartConfigService.anyGaugeChartSpec(
			this.machine,
			'capacity',
			0,
			10,
			Identifiers.capacityAbsoluteCardVariables,
			null,
			Identifiers.capacityRelativeCardVariables
		);
		capacityChartSpec.options.isFivePointChart = true;
		capacityChartSpec.options.fixFloatingPointNumbers = true;
		return {
			chartOptions: capacityChartSpec.options,
			title: 'N100Capacity',
			unit: 'A510TonsPerHour',
			historyVariables: ['u16CapacityAbsolut', 'u16CapacityAbsolut'],
			colorHistoryVariables: Identifiers.capacityAbsoluteCardVariables,
			headLineMaxVariable: '',
			headLineMinVariable: '',
			headlineUnit: '',
			displayVariable: CardDisplayVariables.scarabeusCardDisplayVariables().capacity
		};
	}

	getCapacityRelativeCardDefinition(): IRelativeGaugeCardDefinition {
		const capacityChartSpec = this.chartConfigService.anyGaugeChartSpec(
			this.machine,
			'capacityRelative',
			0,
			10,
			Identifiers.capacityAbsoluteCardVariables,
			null,
			Identifiers.capacityRelativeCardVariables
		);
		capacityChartSpec.options.isFivePointChart = true;
		capacityChartSpec.options.fixFloatingPointNumbers = true;
		return {
			chartOptions: capacityChartSpec.options,
			title: 'N100Capacity',
			unit: 'A027Percent',
			historyVariables: ['u16CapacityRelative', 'u16CapacityRelative'],
			colorHistoryVariables: Identifiers.capacityRelativeCardVariables,
			headLineMaxVariable: '',
			headLineMinVariable: '',
			headlineUnit: '',
			displayVariable: CardDisplayVariables.scarabeusCardDisplayVariables().capacity
		};
	}

	getProductAbsoluteCardDefinition(): IRelativeGaugeCardDefinition {
		const productChartSpec = this.chartConfigService.anyGaugeChartSpec(
			this.machine,
			'product',
			0,
			10,
			Identifiers.productAbsoluteCardVariables,
			null,
			Identifiers.productRelativeCardVariables
		);

		return {
			chartOptions: productChartSpec.options,
			title: 'N101Product',
			unit: 'A510TonsPerHour',
			historyVariables: ['u16ProductInRangeAbsolut', 'u16ProductInRangeAbsolut'],
			colorHistoryVariables: Identifiers.productAbsoluteCardVariables,
			headLineMaxVariable: 'u8HeadlineMax',
			headLineMinVariable: 'u8HeadlineMin',
			headlineUnit: 'N053MM',
			displayVariable: CardDisplayVariables.scarabeusCardDisplayVariables().product
		};
	}

	getProductRelativeCardDefinition(): IRelativeGaugeCardDefinition {
		const productChartSpec = this.chartConfigService.anyGaugeChartSpec(
			this.machine,
			'productRelative',
			0,
			10,
			Identifiers.productAbsoluteCardVariables,
			null,
			Identifiers.productRelativeCardVariables
		);

		return {
			chartOptions: productChartSpec.options,
			title: 'N101Product',
			unit: 'A027Percent',
			historyVariables: ['u16ProductInRange', 'u16ProductInRange'],
			colorHistoryVariables: Identifiers.productRelativeCardVariables,
			headLineMaxVariable: 'u8HeadlineMax',
			headLineMinVariable: 'u8HeadlineMin',
			headlineUnit: 'N053MM',
			displayVariable: CardDisplayVariables.scarabeusCardDisplayVariables().product
		};
	}

	getUnderSizedAbsoluteCardDefinition(): IRelativeGaugeCardDefinition {
		const undersizedChartSpec = this.chartConfigService.anyGaugeChartSpec(
			this.machine,
			'undersized',
			0,
			10,
			Identifiers.undersizedAbsoluteCardVariables,
			null,
			Identifiers.undersizedRelativeCardVariables
		);

		return {
			chartOptions: undersizedChartSpec.options,
			title: 'N102Undersized',
			unit: 'A510TonsPerHour',
			historyVariables: ['u16UndersizedAbsolut', 'u16UndersizedAbsolut'],
			colorHistoryVariables: Identifiers.undersizedAbsoluteCardVariables,
			headLineMaxVariable: '',
			headLineMinVariable: 'u8HeadlineMin',
			headlineUnit: 'N053MM',
			displayVariable: CardDisplayVariables.scarabeusCardDisplayVariables().undersized
		};
	}

	getUnderSizedRelativeCardDefinition(): IRelativeGaugeCardDefinition {
		const undersizedChartSpec = this.chartConfigService.anyGaugeChartSpec(
			this.machine,
			'undersizedRelative',
			0,
			10,
			Identifiers.undersizedAbsoluteCardVariables,
			null,
			Identifiers.undersizedRelativeCardVariables
		);

		return {
			chartOptions: undersizedChartSpec.options,
			title: 'N102Undersized',
			unit: 'A027Percent',
			historyVariables: ['u16UndersizedRange', 'u16UndersizedRange'],
			colorHistoryVariables: Identifiers.undersizedRelativeCardVariables,
			headLineMaxVariable: '',
			headLineMinVariable: 'u8HeadlineMin',
			headlineUnit: 'N053MM',
			displayVariable: CardDisplayVariables.scarabeusCardDisplayVariables().undersized
		};
	}

	getOverSizedAbsoluteCardDefinition(): IRelativeGaugeCardDefinition {
		const oversizedChartSpec = this.chartConfigService.anyGaugeChartSpec(
			this.machine,
			'oversized',
			0,
			10,
			Identifiers.oversizedAbsoluteCardVariables,
			null,
			Identifiers.oversizedRelativeCardVariables
		);

		return {
			chartOptions: oversizedChartSpec.options,
			title: 'N103Oversized',
			unit: 'A510TonsPerHour',
			historyVariables: ['u16OversizedAbsolut', 'u16OversizedAbsolut'],
			colorHistoryVariables: Identifiers.oversizedAbsoluteCardVariables,
			headLineMaxVariable: 'u8HeadlineMax',
			headLineMinVariable: '',
			headlineUnit: 'N053MM',
			displayVariable: CardDisplayVariables.scarabeusCardDisplayVariables().oversized
		};
	}

	getOverSizedRelativeCardDefinition(): IRelativeGaugeCardDefinition {
		const oversizedChartSpec = this.chartConfigService.anyGaugeChartSpec(
			this.machine,
			'oversizedRelative',
			0,
			10,
			Identifiers.oversizedAbsoluteCardVariables,
			null,
			Identifiers.oversizedRelativeCardVariables
		);

		return {
			chartOptions: oversizedChartSpec.options,
			title: 'N103Oversized',
			unit: 'A027Percent',
			historyVariables: ['u16OversizedRange', 'u16OversizedRange'],
			colorHistoryVariables: Identifiers.oversizedRelativeCardVariables,
			headLineMaxVariable: 'u8HeadlineMax',
			headLineMinVariable: '',
			headlineUnit: 'N053MM',
			displayVariable: CardDisplayVariables.scarabeusCardDisplayVariables().oversized
		};
	}

	getOperationModeCardDefinition(): ISimpleBooleanContentCardDefinition {
		const requiredVariables = ['bOperationMode', 'bOperationMode'];
		return <ISimpleBooleanContentCardDefinition>{
			neededValues: [requiredVariables],
			historyVariables: requiredVariables,
			title: 'N109OpMode',
			cardBatchTitle: 'N109OpMode',
			unit: '',
			displayVariable: CardDisplayVariables.scarabeusCardDisplayVariables().operationMode,
		}
	}

	getTemperaturesModeCardDefinition(): ITemperatureScarabaeusTableCardDefinition {
		let titleParams = [];
		Identifiers.temperaturesMachineCard.titleParams.forEach((titleParam) =>
			titleParams.push(this.translateService.instant(titleParam))
		);

		const temperaturesStackedAreaChartSpec = {
			column: {
				category: 'Items',
				values: titleParams,
				countAlias: ['4']
			},
			neededValues: Identifiers.temperaturesMachineCard.variables,
			vAxis: {title: 'N112Temp'},
			hideData: {isHidden: false, value: 0},
			transformData: {
				applyTransformFunction: true,
				transformFunction: (value: number) => value
			}
		} as StackedAreaChartType;

		const requiredVariables = Identifiers.temperaturesMachineCard.variables;
		return {
			neededValues: requiredVariables,
			historyVariables: ['u16Temperatures', 'u16Temperatures'],
			title: 'N112Temp',
			cardBatchTitle: 'N112Temp',
			unit: 'N057DegreeCelcius',
			labelPrefix: '',
			stackedAreaChartSpec: temperaturesStackedAreaChartSpec,
			displayVariable: CardDisplayVariables.scarabeusCardDisplayVariables().temperatures,
		}
	}

	getProductionAbsoluteScarabaeusTableCardDefinition(): IProductionScarabaeusTableCardDefinition {
		const productionStackedAreaChartSpec = {
			column: {
				category: 'Items',
				values: Identifiers.productionAbsoluteMachineCard.titleParams,
				countAlias: ['4']
			},
			neededValues: Identifiers.productionAbsoluteMachineCard.variables,
			vAxis: {title: 'A010Production'},
			hideData: {isHidden: false, value: 0},
			transformData: {
				applyTransformFunction: true,
				transformFunction: (value: number) => value
			}
		} as StackedAreaChartType;

		const requiredVariables = Identifiers.productionAbsoluteMachineCard.variables;
		return {
			neededValues: requiredVariables,
			historyVariables: ['u16Production', 'u16Production'],
			title: 'A010Production',
			cardBatchTitle: 'A010Production',
			unit: 'N110Tons',
			labelPrefix: '',
			stackedAreaChartSpec: productionStackedAreaChartSpec,
			min: 'u8HeadlineMin',
			max: 'u8HeadlineMax',
			displayVariable: CardDisplayVariables.scarabeusCardDisplayVariables().production,
		}
	}

	getProductionRelativeScarabaeusTableCardDefinition(): IProductionScarabaeusTableCardDefinition {
		const productionStackedAreaChartSpec = {
			column: {
				category: 'Items',
				values: Identifiers.productionRelativeMachineCard.titleParams,
				countAlias: ['4']
			},
			neededValues: Identifiers.productionRelativeMachineCard.variables,
			vAxis: {title: 'A010Production'},
			hideData: {isHidden: false, value: 0},
			transformData: {
				applyTransformFunction: true,
				transformFunction: (value: number) => value
			}
		} as StackedAreaChartType;

		const requiredVariables = Identifiers.productionRelativeMachineCard.variables;
		return {
			neededValues: requiredVariables,
			historyVariables: ['u16Production', 'u16Production'],
			title: 'A010Production',
			cardBatchTitle: 'A010Production',
			unit: 'N110Tons',
			labelPrefix: '',
			stackedAreaChartSpec: productionStackedAreaChartSpec,
			min: 'u8HeadlineMin',
			max: 'u8HeadlineMax',
			displayVariable: CardDisplayVariables.scarabeusCardDisplayVariables().production,
		}
	}

}
