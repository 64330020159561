import { IDotChartData, ITrendChartData } from 'app/d3-charts/chart-domains';
import {
	IAlert,
	IAlertType,
	ICompany,
	IMaintenance,
	IPLCMessage,
	IReportConfig,
	IReportSettings,
	IUser,
	ILogBookItem,
	IServiceRequest,
	ICompanyDomain,
	ISensorDataHistory,
	ISensorData,
	TimeSeriesObject,
	ITuningwizarTableData,
	IAnomalyDetectionTableData,
	ITwVArsFullDetailsTableData,
	INiagaraAlertConfiguration,
	INiagaraThreshold,
	INiagaraThresholds,
	IErrorMessageItem
} from './domain-model';
export class Company implements ICompany {
	name: string;
	id: number;
	maxUsers: number;
	parentName: string;
	parentId: number;
	permissions: Array<string>;
	domain: ICompanyDomain;

	constructor(other: ICompany) {
		if (other) {
			Object.assign(this, other);
		}
	}
}

export class Maintenance implements IMaintenance {
	id: number;
	machineId: number;
	maintName: string;
	video: string;
	instruction: string;
	drawing: string;
	partsShop: string;
	extPartsShop: string;

	constructor(other: IMaintenance) {
		if (other) {
			Object.assign(this, other);
		}
	}
}

export class ReportConfig implements IReportConfig {
	id?: number;
	userId?: number;
	companyId?: number;
	machineId?: number;
	cardName?: string;
	cardTitle?: string;
	reportType?: string;
	machineName?: string;

	constructor(other: IReportConfig) {
		if (other) {
			Object.assign(this, other);
		}
	}
}

export class User implements IUser {
	companyId: number;
	companyName: string;
	userId: number;
	username: string;
	firstname: string;
	lastname: string;
	roles: string[];
	password: string;
	parentId: number;
	selectedCompany: number;
	permissions: Array<any>;
	domain: ICompanyDomain;
	partsShopUsername?: string;
	partsShopUsernameEncoded?: string;
	constructor(halUser: IUser) {
		if (halUser) {
			Object.assign(this, halUser);
		}
		if (!this.roles) {
			this.roles = [];
		}
	}

	hasRole(role: string): boolean {
		return this.roles && this.roles.indexOf(role) != -1;
	}

	addRole(role: string) {
		if (!role || this.hasRole(role)) {
			return;
		}
		this.roles.push(role);
	}

	removeRole(role: string) {
		if (this.hasRole(role)) {
			this.roles.splice(this.roles.indexOf(role), 1);
		}
	}
}

export class AlertType implements IAlertType {
	typeId: number;
	typeName: string;
	translationKey: string;
	translationSuffix: string;
	active: boolean;

	constructor(other: IAlertType) {
		if (other) {
			Object.assign(this, other);
		}
	}
}

export class Alert implements IAlert {
	alertId: number;
	emailAlertStatus: boolean;
	pushAlertStatus: boolean;
	userId: number;
	pushAlerts: number[];
	emailAlerts: number[];

	constructor(other: IAlert) {
		if (other) {
			Object.assign(this, other);
		}
	}
}

export class PLCMessage implements IPLCMessage {
	errorNumber: number;
	alertingActivated: boolean;
	alertText: string;
	resolvedMailActivated: boolean;
	resolvedText: string;

	constructor(other: IPLCMessage) {
		if (other) {
			Object.assign(this, other);
		}
	}
}

export class ReportSettings implements IReportSettings {
	id: number;
	userId: number;
	status: boolean;
	maintenanceIncluded: boolean;
	language: string;
	productionDay: number;
	firstDay: Date;
	recipients: string[];
	batchReportsEnabled: boolean;
	excelReportsEnabled: boolean;
	dailyReportEnabled: boolean;
	weeklyReportEnabled: boolean;
	monthlyReportEnabled: boolean;
	constructor(other: IReportSettings) {
		if (other) {
			Object.assign(this, other);
		}
	}
}

export class LogBookItem implements ILogBookItem {
	id: number;
	machineId: number;
	type: string;
	date: Date;
	userId: number;
	userEmail: string;
	isDone: boolean;
	item: string;
	fileNames: string[];
	constructor(other: ILogBookItem) {
		if (other) {
			Object.assign(this, other);
		}
	}
}

export class ErrorMessageItem implements IErrorMessageItem {
	active: boolean;
	key: string;
	message: string;
	metricspecIdentifier: string;
	number: string;
	plc: string;
	plcNumber: string;
	timestampCreated: number;
	timestampMeasurement: number;
	type: string;
	typeNumber: string;
	count: number;
	time: number;
	constructor(other: IErrorMessageItem) {
		if (other) {
			Object.assign(this, other);
		}
	}
}

export class ServiceRequest implements IServiceRequest {
	machineId: number;
	hasServiceContract: boolean;
	description: string;
	machineUrl: string;
	attachedLogBookFiles: string[];
	constructor(other: IServiceRequest) {
		if (other) {
			Object.assign(this, other);
		}
	}
}

export class ModuleGroup {
	module: string;
	index: number;
	status: number;

	constructor(module, index, status) {
		this.module = module;
		this.index = index;
		this.status = status;
	}
}

export class NiagaraAlertConfiguration implements INiagaraAlertConfiguration {
	variableName: string;
	userId: number;
	machineId: number;
	isSubscribed: boolean;
	constructor(other: INiagaraAlertConfiguration) {
		if (other) {
			Object.assign(this, other);
		}
	}
}

export class NiagaraThreshold implements INiagaraThreshold {
	thresholdKey: string;
	variableName: string;
	thresholdValue: number;
	constructor(other: INiagaraThreshold) {
		if (other) {
			Object.assign(this, other);
		}
	}
}

export class NiagaraThresholds implements INiagaraThresholds {
	cardName: string;
	machineId: number;
	niagaraThresholds: INiagaraThreshold[];
	constructor(other: INiagaraThresholds) {
		if (other) {
			Object.assign(this, other);
		}
	}
}

export class SensorDataHistory implements ISensorDataHistory {
	accelerationLocal: number;
	accelerationX: number;
	accelerationY: number;
	accelerationZ: number;
	amplitudeLocal: number;
	amplitudeX: number;
	amplitudeY: number;
	amplitudeZ: number;
	angleOfMovement: number;
	constant: number;
	createdAt: Date;
	deltaTemperature: number;
	displacementX: number;
	displacementY: number;
	displacementZ: number;
	frequency: number;
	phaseAngle: number;
	rms: number;
	rpm: number;
	sensorDescription: string;
	sensorId: string;
	sensorOnOff: number;
	sensorType: string;
	siteName: string;
	strokeAngle: number;
	temperatureSensor: number;
	updatedTime: Date;
	constructor(other: ISensorDataHistory) {
		if (other) {
			Object.assign(this, other);
		}
	}
}

export class SensorData implements ISensorData {
	// rpm: TimeSeriesObject[];
	sensorDataAccelerationResource: {
		accelerationLocal: TimeSeriesObject[];
		accelerationX: TimeSeriesObject[];
		accelerationY: TimeSeriesObject[];
		accelerationZ: TimeSeriesObject[];
	};
	sensorDataAmplitudeResource: {
		amplitudeLocal: TimeSeriesObject[];
		amplitudeX: TimeSeriesObject[];
		amplitudeY: TimeSeriesObject[];
		amplitudeZ: TimeSeriesObject[];
	};
	sensorDataResources: SensorDataHistory[];
	sensorDescription: string;

	constructor(other: ISensorData) {
		if (other) {
			Object.assign(this, other);
		}
	}
}

export class TuningwizardTableData implements ITuningwizarTableData {
	date: Date;
	accelLRCompFeed: number;
	accelLRCompDisch: number;
	xgLrCompFeed: number;
	xgLrCompDisch: number;
	ygLrCompFeed: number;
	ygLrCompDisch: number;
	zgLrCompFeed: number;
	zgLrCompDisch: number;
	accelFDCompLeft: number;
	accelFDCompRight: number;
	xgFDCompLeft: number;
	xgFDCompRight: number;
	ygFDCompLeft: number;
	ygFDCompRight: number;
	zgFDCompLeft: number;
	zgFDCompRight: number;
	pitch: number;
	roll: number;
	twistG: number;
	twistXG: number;
	twistYG: number;
	twistZG: number;
	zgMax: number;

	constructor(other: ITuningwizarTableData) {
		if (other) {
			Object.assign(this, other);
		}
	}
}

export class AnomalyDetectionTableData implements IAnomalyDetectionTableData {
	date: Date;
	isAnomaly: boolean;
	observedValue: number;
	constructor(other: IAnomalyDetectionTableData) {
		if (other) {
			Object.assign(this, other);
		}
	}
}

export class DotChartData implements IDotChartData {
	value: number;
	time: number;

	constructor(other: IDotChartData) {
		if (other) {
			Object.assign(this, other);
		}
	}
}

export class TwVardsFullDetailsTableData
	implements ITwVArsFullDetailsTableData {
	date: Date;
	main: number;
	higher: number;
	lower: number;
	constructor(other: ITwVArsFullDetailsTableData) {
		if (other) {
			Object.assign(this, other);
		}
	}
}

export class TrendChartData implements ITrendChartData {
	medianValue: number;
	upperValue: number;
	lowerValue: number;
	time: number;
	constructor(other: ITrendChartData) {
		if (other) {
			Object.assign(this, other);
		}
	}
}
