import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TRANSLATION_PROVIDERS, TranslateService } from '../shared/translate';

@NgModule({
	imports: [CommonModule],
	providers: [TRANSLATION_PROVIDERS, TranslateService],
	declarations: []
})
export class TranslateModule {}
