import { Injectable, Injector } from '@angular/core';
import {
	HttpInterceptor,
	HttpRequest,
	HttpHandler,
	HttpEvent,
	HttpResponse,
	HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { Base64 } from './base64';
import { AuthService } from './auth-service';
import { tap } from 'rxjs/operators';
import { StorageService } from "./storage-service";

@Injectable()
export class HBRequestOptions implements HttpInterceptor {
	private encoder: Base64 = new Base64();
	private headerName = 'Authorization';
	isRefreshingToken = false;
	private authService: AuthService;
	tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

	constructor(private injector: Injector,
				private storageService: StorageService) {
		setTimeout(() => {
			this.authService = this.injector.get(AuthService);
		});
	}

	addToken(req: HttpRequest<any>): HttpRequest<any> {
		let authTokens = this.storageService.getToken();

		if (authTokens) {
			let authT = JSON.parse(authTokens);
			return req.clone({
				setHeaders: { Authorization: 'Bearer ' + authT.access_token }
			});
		} else {
			return req;
		}
	}

	intercept(
		req: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		if (!req.headers.get(this.headerName)) {
			return next.handle(this.addToken(req)).pipe(
				tap({
					next: (event: HttpEvent<any>) => {
						// Success area there is nothing right now
					},
					error: (error: any) => {
						if (
							(error instanceof HttpErrorResponse &&
								(<HttpErrorResponse>error).status == 401 &&
								error.error &&
								error.error.error === 'invalid_token') ||
							(<HttpErrorResponse>error).status == 403
						) {
							return this.handleExpiry(req, next);
						} else {
							return throwError(() => new Error(error));
						}
					}
				})
			);
		} else {
			// Here the request with authorization headers, expects for login process
			return next.handle(req);
		}
	}

	handleExpiry(req: HttpRequest<any>, next: HttpHandler) {
		if (!this.isRefreshingToken) {
			this.isRefreshingToken = true;

			// Reset here so that the following requests wait until the token
			// comes back from the refreshToken call.
			this.tokenSubject.next(null);
			this.authService.refreshToken().subscribe((newToken) => {
				if (newToken && newToken != '') {
					this.isRefreshingToken = false;
					this.tokenSubject.next(newToken);
					return next.handle(this.addToken(req));
				} else {
					// If we don't get a new token, we are in trouble so logout.
					return this.authService.logout();
				}
			});
		}
	}
}
