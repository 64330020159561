import { InjectionToken } from '@angular/core';
import { Country } from '../country/country';
import { LANG_KEY_NAME, LANG_KEY_TRANS } from './lang-key';
import { LANG_DE_NAME, LANG_DE_TRANS } from './lang-de';
import { LANG_EN_NAME, LANG_EN_TRANS } from './lang-en';
import { LANG_FR_NAME, LANG_FR_TRANS } from './lang-fr';
import { LANG_ZH_NAME, LANG_ZH_TRANS } from './lang-zh';
import { LANG_PT_NAME, LANG_PT_TRANS } from './lang-pt';
import { LANG_VI_NAME, LANG_VI_TRANS } from './lang-vi';
import { LANG_ES_NAME, LANG_ES_TRANS } from './lang-es';
export let TRANSLATIONS = new InjectionToken<any>('translations');
export const dictionary = {
key: LANG_KEY_TRANS,
de: LANG_DE_TRANS,
en: LANG_EN_TRANS,
fr: LANG_FR_TRANS,
zh: LANG_ZH_TRANS,
pt: LANG_PT_TRANS,
vi: LANG_VI_TRANS,
es: LANG_ES_TRANS,
};
export let  CountryList = [
new Country('key', 'A000Key'),
new Country('de', 'A002German'),
new Country('en', 'A003English'),
new Country('fr', 'A969French'),
new Country('zh', 'A996Chinese'),
new Country('pt', 'A997Portuguese'),
new Country('vi', 'A998Vietnamese'),
new Country('es', 'A1000Spanish'),
];
export const TRANSLATION_PROVIDERS = [{ provide: TRANSLATIONS, useValue: dictionary },];
