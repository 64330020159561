export class ChartSpec<OptionsType> {
	constructor(public label: string, public options: OptionsType) {
		/*	if (injectedObservable){
				this.observable = injectedObservable;
				return;
			}
			this.observable = new ReplaySubject<Array<IChartValue>>(1);*/
	}
	/*public observable: Subject<Array<IChartValue>>;*/
}

export enum MachineState {
	OFFLINE = 0,
	STANDBY,
	ONLINE,
	FAULTY,
	MAINTENANCE
}

export enum MachineNiagaraState {
	OFFLINE = 0,
	ONLINE
}

export enum ChartPeriod {
	DAY,
	WEEK,
	MONTH,
	BATCH,
	YEAR,
	CUSTOM
}
export enum ChartTypes {
	STEP_CHART = 1,
	MULTI_LINE_CHART,
	STACKED_AREA_CHART,
	PIE_MULTI_LINE_DUAL_CHART
}

export enum ResetState {
	NO_RESET_NO_CONFIG = 0,
	INACTIVE_REST_NO_CONFIG,
	MAXVALUE_REST_NO_CONFIG,
	MAXVALUE_RESET_WITH_CONFIG,
	MINMAX_RESET_WITH_CONFIG,
	NO_RESET_BUT_WITH_CONFIG,
	ACTIVE_RESET_NO_CONFIG
}
