import { Injectable } from "@angular/core";

@Injectable({ providedIn: 'root' })
export class StorageService {

	private storageKey = 'credentials';
	private REMEMBER_ME_ENABLED = 'rememberMeEnabled';


	constructor() {
	}

	public setRememberMeEnabled() {
		localStorage.setItem(this.REMEMBER_ME_ENABLED, 'true')
	}

	public clearAuthInfo() {
		this.removeToken();
		this.removeRememberMe();
	}

	public saveToken(token: string) {

		if(this.isRememberMeEnabled()) {
			localStorage.setItem(this.storageKey, token);
		} else {
			sessionStorage.setItem(this.storageKey, token);
		}
	}

	public getToken(): string {
		if(this.isRememberMeEnabled()) {
			return localStorage.getItem(this.storageKey);
		} else {
			return sessionStorage.getItem(this.storageKey);
		}
	}

	public getStorageKey(): string {
		return this.storageKey;
	}

	private isRememberMeEnabled(): boolean {
		return Boolean(localStorage.getItem(this.REMEMBER_ME_ENABLED));
	}


	private removeRememberMe() {
		localStorage.removeItem(this.REMEMBER_ME_ENABLED);
	}

	private removeToken() {
		localStorage.removeItem(this.storageKey);
		sessionStorage.removeItem(this.storageKey);
	}

}