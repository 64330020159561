import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// @Injectable({
//   providedIn: 'root'
// })

@Injectable()
export class HttpServantService {
	constructor(public http: HttpClient) {
		//console.log('init http servant');
	}
	// http() {
	// 	return this.httpServant;
	// }
}
