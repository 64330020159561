import { Injectable } from '@angular/core';
import { ChartDefinitionBase } from './chart-definition-base';
import { IMachine } from '../api/domain-model';
import { MachineService } from '../api/machine.service';
import { NotificationService } from '../shared/notifications/notification.service';
import { ChartConfigService } from '../company/chart-config.service';
import { AuthService } from '../shared/auth-service';
import { TranslateService } from '../shared/translate';
import {
	IInterruptsTableCardDefinition,
	IInterruptsWithMultiLineCardDefinition,
	ISimpleContentCardDefinition,
	ISpoutTableCardDefinition,
	IStackedBarCardDefinition
} from '../charts/definition-model';
import CardDisplayVariables from '../common/domain/card-display-variables';
import { Identifiers, Util } from '../common/domain/bindings';
import { ChartSpec } from '../company';
import { IColumnChartOptions, StackedAreaChartType } from '../charts/domain-model';
import { IStackChartOptions } from '../d3-charts/chart-domains';

@Injectable()
export class ChartDefinitionNewtecPalletizerService extends ChartDefinitionBase {

	private machine: IMachine;

	constructor(
		public machineService: MachineService,
		public notificationService: NotificationService,
		public chartConfigService: ChartConfigService,
		public authService: AuthService,
		public translateService: TranslateService
	) {
		super();
	}

	setMachine(machine: IMachine): void {
		this.machine = machine;
	}

	getMachine(): IMachine {
		return this.machine;
	}

	getProgramChangesTodayCardDefinition(): ISimpleContentCardDefinition {
		const requiredVariables = ['u16ProductChgDay', 'u16ProductChgDay'];
		return <ISimpleContentCardDefinition>{
			neededValues: [requiredVariables],
			historyVariables: requiredVariables,
			title: 'New042ProgramChngsToday',
			cardBatchTitle: 'New042ProgramChngs',
			unit: 'A020Count',
			displayVariable: CardDisplayVariables.newTecPalletizerCardDisplayVariables().programChangesToday,
		}
	}

	getEndOfBatchCardDefinition(): ISimpleContentCardDefinition {
		const requiredVariables = ['u16EndBatchDay', 'u16EndBatchDay'];
		return <ISimpleContentCardDefinition>{
			neededValues: [requiredVariables],
			historyVariables: requiredVariables,
			title: 'New043EndofBatch',
			cardBatchTitle: 'New043EndofBatch',
			unit: 'A020Count',
			displayVariable: CardDisplayVariables.newTecPalletizerCardDisplayVariables().endOfBatch,
		}
	}

	getFeedOfEmptyPalletesCardDefinition(): ISimpleContentCardDefinition {
		const requiredVariables = ['u16EmptyPalletCnt_day', 'u16EmptyPalletCnt_batch'];
		return <ISimpleContentCardDefinition>{
			neededValues: [requiredVariables],
			historyVariables: requiredVariables,
			title: 'A105LoadEmptyPallets',
			cardBatchTitle: 'A105LoadEmptyPallets',
			unit: 'A020Count',
			displayVariable: CardDisplayVariables.newTecPalletizerCardDisplayVariables().feedOfEmptyPalletes,
		}
	}

	getInterruptsCardDefinition(): IInterruptsWithMultiLineCardDefinition {
		const requiredVariables = [...Identifiers.newTecPalletizerInterruptCard.countParams,
			...Identifiers.newTecPalletizerInterruptCard.timeParams];
		const multiLineChartSpec = this.chartConfigService.getInterruptionsMultiLineChartConfig(Identifiers.newTecPalletizerInterruptCard.timeParams);
		return {
			neededValues: requiredVariables,
			historyVariables: ['u16EmergStopCnt_day'],
			title: 'A284Interrupts',
			cardBatchTitle: 'A284InterruptsBatch',
			unit: 'A124Minutes',
			machineType: 'newtecpalletizer',
			countParams: Identifiers.newTecPalletizerInterruptCard.countParams,
			timeParams: Identifiers.newTecPalletizerInterruptCard.timeParams,
			titleParams: Identifiers.newTecPalletizerInterruptCard.titleParams,
			displayVariable: CardDisplayVariables.newTecPalletizerCardDisplayVariables().interruptionsToday,
			multiLineChartSpec,
			historyNeededValues: Util.getHistoryNeededValues(Identifiers.newTecPalletizerInterruptCard.timeParams, Identifiers.newTecPalletizerInterruptCard.countParams, Identifiers.newTecPalletizerInterruptCard.titleParams)
		}
	}


	getEmptyPalletAreaCardDefinition(): IInterruptsTableCardDefinition {
		const requiredVariables = [...Identifiers.newTecPalletizerEmptyPalleteAreaCard.countParams,
			...Identifiers.newTecPalletizerEmptyPalleteAreaCard.timeParams];
		return {
			neededValues: requiredVariables,
			historyVariables: ['u16EmpAccessDoorCnt_day'],
			title: 'New002EmptyPalArea',
			cardBatchTitle: 'New002EmptyPalArea',
			unit: 'A124Minutes',
			machineType: 'newtecpalletizer',
			countParams: Identifiers.newTecPalletizerEmptyPalleteAreaCard.countParams,
			timeParams: Identifiers.newTecPalletizerEmptyPalleteAreaCard.timeParams,
			titleParams: Identifiers.newTecPalletizerEmptyPalleteAreaCard.titleParams,
			displayVariable: CardDisplayVariables.newTecPalletizerCardDisplayVariables().emptyPalletArea,
			historyNeededValues: Util.getHistoryNeededValues(Identifiers.newTecPalletizerEmptyPalleteAreaCard.timeParams, Identifiers.newTecPalletizerEmptyPalleteAreaCard.countParams, Identifiers.newTecPalletizerEmptyPalleteAreaCard.titleParams)
		}
	}

	getBagTransportAreaCardDefinition(): IInterruptsTableCardDefinition {
		const requiredVariables = [...Identifiers.newTecPalletizerBagTransportAreaCard.countParams,
			...Identifiers.newTecPalletizerBagTransportAreaCard.timeParams];
		return {
			neededValues: requiredVariables,
			historyVariables: ['u16BagAccessDoorCnt_day'],
			title: 'New001BagTransArea',
			cardBatchTitle: 'New001BagTransArea',
			unit: 'A124Minutes',
			machineType: 'newtecpalletizer',
			countParams: Identifiers.newTecPalletizerBagTransportAreaCard.countParams,
			timeParams: Identifiers.newTecPalletizerBagTransportAreaCard.timeParams,
			titleParams: Identifiers.newTecPalletizerBagTransportAreaCard.titleParams,
			displayVariable: CardDisplayVariables.newTecPalletizerCardDisplayVariables().bagTransportArea,
			historyNeededValues: Util.getHistoryNeededValues(Identifiers.newTecPalletizerBagTransportAreaCard.timeParams, Identifiers.newTecPalletizerBagTransportAreaCard.countParams, Identifiers.newTecPalletizerBagTransportAreaCard.titleParams)
		}
	}

	getFullPalletAreaCardDefinition(): IInterruptsTableCardDefinition {
		const requiredVariables = [...Identifiers.newTecPalletizerFullPalletAreaCard.countParams,
			...Identifiers.newTecPalletizerFullPalletAreaCard.timeParams];
		return {
			neededValues: requiredVariables,
			historyVariables: ['u16FullAccessDoorCnt_day'],
			title: 'New003FullPalArea',
			cardBatchTitle: 'New003FullPalArea',
			unit: 'A124Minutes',
			machineType: 'newtecpalletizer',
			countParams: Identifiers.newTecPalletizerFullPalletAreaCard.countParams,
			timeParams: Identifiers.newTecPalletizerFullPalletAreaCard.timeParams,
			titleParams: Identifiers.newTecPalletizerFullPalletAreaCard.titleParams,
			displayVariable: CardDisplayVariables.newTecPalletizerCardDisplayVariables().fullPalletArea,
			historyNeededValues: Util.getHistoryNeededValues(Identifiers.newTecPalletizerFullPalletAreaCard.timeParams, Identifiers.newTecPalletizerFullPalletAreaCard.countParams, Identifiers.newTecPalletizerFullPalletAreaCard.titleParams)
		}
	}

	getPalletizerCardDefinition(): IInterruptsTableCardDefinition {
		const requiredVariables = [...Identifiers.newTecPalletizerPalletizerCard.countParams,
			...Identifiers.newTecPalletizerPalletizerCard.timeParams];
		return {
			neededValues: requiredVariables,
			historyVariables: ['u16PalAccessDoorCnt_day'],
			title: 'A208Palletizer',
			cardBatchTitle: 'A284InterruptsBatch',
			unit: 'A124Minutes',
			machineType: 'newtecpalletizer',
			countParams: Identifiers.newTecPalletizerPalletizerCard.countParams,
			timeParams: Identifiers.newTecPalletizerPalletizerCard.timeParams,
			titleParams: Identifiers.newTecPalletizerPalletizerCard.titleParams,
			displayVariable: CardDisplayVariables.newTecPalletizerCardDisplayVariables().palletizer,
			historyNeededValues: Util.getHistoryNeededValues(Identifiers.newTecPalletizerPalletizerCard.timeParams, Identifiers.newTecPalletizerPalletizerCard.countParams, Identifiers.newTecPalletizerPalletizerCard.titleParams)
		}
	}

	getSheetDispenserAreaCardDefinition(): IInterruptsTableCardDefinition {
		const requiredVariables = [...Identifiers.newTecPalletizerSheetDispenserAreaCard.countParams,
			...Identifiers.newTecPalletizerSheetDispenserAreaCard.timeParams];
		return {
			neededValues: requiredVariables,
			historyVariables: ['u16SheetAccessDoorCnt_day'],
			title: 'New004SheetDispArea',
			cardBatchTitle: 'New004SheetDispArea',
			unit: 'A124Minutes',
			machineType: 'newtecpalletizer',
			countParams: Identifiers.newTecPalletizerSheetDispenserAreaCard.countParams,
			timeParams: Identifiers.newTecPalletizerSheetDispenserAreaCard.timeParams,
			titleParams: Identifiers.newTecPalletizerSheetDispenserAreaCard.titleParams,
			displayVariable: CardDisplayVariables.newTecPalletizerCardDisplayVariables().sheetDispenserArea,
			historyNeededValues: Util.getHistoryNeededValues(Identifiers.newTecPalletizerSheetDispenserAreaCard.timeParams, Identifiers.newTecPalletizerSheetDispenserAreaCard.countParams, Identifiers.newTecPalletizerSheetDispenserAreaCard.titleParams)
		}
	}


	getProductionCardDefinition(): ISpoutTableCardDefinition {
		const requiredVariables = Identifiers.newTecPalletizerProductionCard;
		return {
			neededValues: requiredVariables,
			historyVariables: ['u8ShowUpPrograms'],
			title: 'A010Production',
			cardBatchTitle: 'A010Production',
			unit: 'A020Count',
			labelPrefix: 'A211Programme',
			displayVariable: CardDisplayVariables.newTecPalletizerCardDisplayVariables().production,
		}
	}


	/**
	 * TODO: these function can be refactored
	 */
	getProductionTimeCardDefinition(): IStackedBarCardDefinition {

		let productionTimeChartHeadings = [
			'A010Production',
			'A231NoOutlet',
			'A230NoProduct',
			'A229NoPackage',
			'New029Fault',
			'New030StandBy'
		];
		let productionTimeChartNeededValues = [
			['u16ProdTime_day', 'u16ProdTime_batch'],
			['u16OutletTime_day', 'u16OutletTime_batch'],
			['u16FeedTime_day', 'u16FeedTime_batch'],
			['u16ContainerTime_day', 'u16ContainerTime_batch'],
			['u16FaultTime_day', 'u16FaultTime_batch'],
			['u16StandByTime_day', 'u16StandByTime_batch']
		];
		const initialModel = {
			daily: {
				u16ProdTime_day: 0,
				u16OutletTime_day: 0,
				u16FeedTime_day: 0,
				u16ContainerTime_day: 0,
				u16FaultTime_day: 0,
				u16StandByTime_day: 0
			},
			batch: {
				u16ProdTime_batch: 0,
				u16OutletTime_batch: 0,
				u16FeedTime_batch: 0,
				u16ContainerTime_batch: 0,
				u16FaultTime_batch: 0,
				u16StandByTime_batch: 0
			}
		};
		const productionTimeChartSpec =
			this.chartConfigService.stackedColumnChartSpec(
				this.machine,
				'ProductionTime',
				productionTimeChartHeadings,
				productionTimeChartNeededValues,
				['#27408B', '#87CEEF', '#999999', '#FFFF00', '#15EC5A', '#EC3815'],
				initialModel
			);
		const stackedAreaChartSpecAndOptions = this.configureStackedAreaChartSpecAndOptions(
			productionTimeChartSpec,
			'A124Minutes',
			initialModel
		);
		stackedAreaChartSpecAndOptions.stackChartOptions.colors = productionTimeChartSpec.options.colors;

		return <IStackedBarCardDefinition>{
			title: 'New044ProductonTime',
			unit: 'A124Minutes',
			historyVariables: ['u16StandByTime_day'],
			displayVariable: CardDisplayVariables.newTecPalletizerCardDisplayVariables().productionTime,
			stackedAreaChartSpec: stackedAreaChartSpecAndOptions.stackedAreaChartType,
			chartOptions: stackedAreaChartSpecAndOptions.stackChartOptions
		}

	}

	/**
	 * TODO: these function can be refactored
	 */
	getBagProductionCardDefinition(): IStackedBarCardDefinition {
		let bagProductionChartHeadings = [
			'New023MetDetector',
			'New032WeightDetection',
			'New033LeakingDetection',
			'New034PrintingFault',
			'New035BagProduced',
			'New036SamplingEjection'
		];
		let bagProductionChartNeededValues = [
			['u16MetalDetectorCnt_day', 'u16MetalDetectorCnt_batch'],
			['u16WeightDetectionCnt_day', 'u16WeightDetectionCnt_batch'],
			['u16BrokenBagDetectionCnt_day', 'u16BrokenBagDetectionCnt_batch'],
			['u16PrintingFalutCnt_day', 'u16PrintingFalutCnt_batch'],
			['u16BagCnt_day', 'u16BagCnt_batch'],
			['u16RejectsSampleBag_day', 'u16RejectsSampleBag_batch']
		];
		const initialModel = {
			daily: {
				u16MetalDetectorCnt_day: 0,
				u16WeightDetectionCnt_day: 0,
				u16BrokenBagDetectionCnt_day: 0,
				u16PrintingFalutCnt_day: 0,
				u16BagCnt_day: 0,
				u16RejectsSampleBag_day: 0
			},
			batch: {
				u16MetalDetectorCnt_batch: 0,
				u16WeightDetectionCnt_batch: 0,
				u16BrokenBagDetectionCnt_batch: 0,
				u16PrintingFalutCnt_batch: 0,
				u16BagCnt_batch: 0,
				u16RejectsSampleBag_batch: 0
			}
		};
		const bagProductionChartSpec =
			this.chartConfigService.stackedColumnChartSpec(
				this.machine,
				'BagProduction',
				bagProductionChartHeadings,
				bagProductionChartNeededValues,
				['#27408B', '#87CEEF', '#999999', '#FFFF00', '#15EC5A', '#EC3815'],
				initialModel
			);

		const stackedAreaChartSpecAndOptions = this.configureStackedAreaChartSpecAndOptions(
			bagProductionChartSpec,
			'A020Count',
			initialModel
		);

		stackedAreaChartSpecAndOptions.stackChartOptions.colors = bagProductionChartSpec.options.colors;

		return <IStackedBarCardDefinition>{
			title: 'New031BagProduction',
			unit: 'A020Count',
			historyVariables: ['u16BagCnt_day'],
			displayVariable: CardDisplayVariables.newTecPalletizerCardDisplayVariables().bagProduction,
			stackedAreaChartSpec: stackedAreaChartSpecAndOptions.stackedAreaChartType,
			chartOptions: stackedAreaChartSpecAndOptions.stackChartOptions
		}
	}


	/**
	 * TODO: these function can be refactored
	 */
	getPalleteProductionCardDefinition(): IStackedBarCardDefinition {
		const palleteProductionChartHeadings = [
			'New038PalletInfeed',
			'New039PalletOutfeed',
			'New040PalletIncomplete',
			'New041PalledProduced'
		];
		const palleteProductionChartNeededValues = [
			['u16PalletInfeed_day', 'u16PalletInfeed_batch'],
			['u16PalletOutfeed_day', 'u16PalletOutfeed_batch'],
			['u16PalletIncomplete_day', 'u16PalletIncomplete_batch'],
			['u16PalletProduced_day', 'u16PalletProduced_batch']
		];
		const initialModel = {
			daily: {
				u16PalletInfeed_day: 0,
				u16PalletOutfeed_day: 0,
				u16PalletIncomplete_day: 0,
				u16PalletProduced_day: 0
			},
			batch: {
				u16PalletInfeed_batch: 0,
				u16PalletOutfeed_batch: 0,
				u16PalletIncomplete_batch: 0,
				u16PalletProduced_batch: 0
			}
		};
		const palletProductionChartSpec =
			this.chartConfigService.stackedColumnChartSpec(
				this.machine,
				'PalleteProduction',
				palleteProductionChartHeadings,
				palleteProductionChartNeededValues,
				['#27408B', '#87CEEF', '#999999', '#FFFF00', '#15EC5A', '#EC3815'],
				initialModel
			);

		const stackedAreaChartSpecAndOptions = this.configureStackedAreaChartSpecAndOptions(
			palletProductionChartSpec,
			'A020Count',
			initialModel
		);

		stackedAreaChartSpecAndOptions.stackChartOptions.colors = palletProductionChartSpec.options.colors;

		return <IStackedBarCardDefinition>{
			title: 'New037PalletProduction',
			unit: 'A020Count',
			historyVariables: ['u16PalletInfeed_day'],
			displayVariable: CardDisplayVariables.newTecPalletizerCardDisplayVariables().palletProduction,
			stackedAreaChartSpec: stackedAreaChartSpecAndOptions.stackedAreaChartType,
			chartOptions: stackedAreaChartSpecAndOptions.stackChartOptions
		}
	}

	private configureStackedAreaChartSpecAndOptions(
		chartSpec: ChartSpec<IColumnChartOptions>,
		yAxisTitle: string,
		initialModel: { daily: any; batch: any }
	): {
		stackedAreaChartType: StackedAreaChartType,
		stackChartOptions: IStackChartOptions,
	} {
		const columnValues = [];
		chartSpec.options.header.map((heading) => columnValues.push(this.translateService.instant(heading)));

		const stackedAreaChartType = <StackedAreaChartType>{
			column: {values: columnValues, countAlias: []},
			neededValues: chartSpec.options.neededValues,
			colors: chartSpec.options.colors,
			vAxis: {title: yAxisTitle},
			hideData: {isHidden: false, value: 0},
			transformData: {
				applyTransformFunction: false,
				transformFunction: (value: number) => value
			}
		};

		const stackChartOptions: IStackChartOptions = {
			...chartSpec,
			initialModel,
			neededValues: chartSpec.options.neededValues,
			header: chartSpec.options.header,
			isBatch: false,
			machineName: this.getMachine().name
		}

		return {
			stackedAreaChartType,
			stackChartOptions
		}
	}
}
