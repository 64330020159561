import { Injectable } from '@angular/core';
import { HalDiscoveryService } from '../shared/hal/hal-discovery.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, mergeMap, share } from 'rxjs/operators';
import { ICompanyDomain, ICompanyDomainResourceResponse } from './domain-model';

@Injectable({ providedIn: 'root' })
export class CompanyDomainService {
	constructor(private disco: HalDiscoveryService, private http: HttpClient) {}

	getCompanyDomains(): Observable<Array<ICompanyDomain>> {
		return this.disco.getResourceTree().pipe(
			mergeMap((api) => {
				return this.http
					.get(api.metrics.hb.domains.uriTemplate.expand({}))
					.pipe(
						map((r) => {
							let response = <ICompanyDomainResourceResponse>r;
							return <Array<ICompanyDomain>>(
								response._embedded['hb:domainResources']
							);
						})
					);
			}),
			share({
				resetOnError: false,
				resetOnComplete: false,
				resetOnRefCountZero: false,
			})
		);
	}
}
