import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarComponent } from '../snackbar/snackbar.component';
import { NotificationService } from './notification.service';

@Component({
	selector: 'hb-notification',
	templateUrl: 'notification.component.html',
	styleUrls: ['notification.component.scss']
})
export class NotificationComponent implements OnInit {
	constructor(
		private notificationService: NotificationService,
		public snackBar: MatSnackBar
	) {}

	ngOnInit() {
		this.notificationService.notificationChange.subscribe((isError) => {
			let snackBarRef = this.snackBar.openFromComponent(SnackbarComponent, {
				duration: isError ? 5000 : 3000,
				panelClass: isError
					? 'iot-snack-bar-container-error'
					: 'iot-snack-bar-container-success',
				data: {
					isError,
					messages: this.messages()
				}
			});

			snackBarRef
				.onAction()
				.subscribe(() => this.notificationService.dismissAll());
			snackBarRef
				.afterDismissed()
				.subscribe(() => this.notificationService.dismissAll());
		});
	}

	messages(): any[] {
		let messages = [];
		this.notificationService.notifications.forEach((n) => {
			messages.push({
				severity: n.isError() ? 'error' : 'success',
				summary: '',
				detail: n.message
			});
		});
		return messages;
	}

	hasNotifications(): boolean {
		return this.notificationService.hasNotifications();
	}
}
