/* always needed */
import { DatePipe, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import { ErrorHandler, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
// import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
// import { MatProgressButtonsModule } from 'mat-progress-buttons';
import { environment } from '../environments/environment';
import { LogService } from './api/log.service';
import { ConfignavService } from './confignav.service';

import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { FirebaseService } from './api/firebase.service';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';

/* angular modularization thoughts:

Core Module is ONLY imported in the app module
    Example for Core stuff is: Singletons

Shared Module is imported everywhere BUT the app module, so its not part of the (tree) root but the leaves (of the tree)
    Example for Shared Stuff is Text,Buttons and so on

Pipes Module and Directives Module are imported everywhere needed (and could be compined but that does not help much, so we keep them seperated)
    Example for Pipes  are translations, dateformatter, ...
    Example for Directives are ngIf, hasRole, .... (in html)



 */
/* App Root */
import { RootComponent } from './root.component';
import { RootRoutingModule } from './root.routing.module';
/* CoreModule */
import { CoreModule } from './shared/core.module';
import { HBRequestOptions } from './shared/hb-request-options.service';
import { NotificationComponent } from './shared/notifications/notification.component';
import { SnackbarComponent } from './shared/snackbar/snackbar.component';

registerLocaleData(localeDe, 'de-DE', localeDeExtra);

@NgModule({
	imports: [
		BrowserModule,
		FormsModule,
		RootRoutingModule,
		// NoopAnimationsModule,
		BrowserAnimationsModule,

		// Basic Material for notification component
		MatIconModule,
		MatSnackBarModule,

		CoreModule, // imports all global Modules

		ServiceWorkerModule.register('ngsw-worker.js', {
			enabled: environment.production
		}),

		// MatProgressButtonsModule.forRoot()

		AngularFireModule.initializeApp(environment.firebaseConfig),
		AngularFireMessagingModule,
		NgxMaterialTimepickerModule
	],
	providers: [
		FirebaseService,
		DatePipe,
		{ provide: ErrorHandler, useClass: LogService },
		ConfignavService,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: HBRequestOptions,
			multi: true
		}
	],
	declarations: [RootComponent, NotificationComponent, SnackbarComponent],
	entryComponents: [SnackbarComponent],
	bootstrap: [RootComponent]
})
export class RootModule {}
