export enum NotificationType {
	Info,
	Warning,
	Error
}

export class Notification {
	message: string;
	type: NotificationType;

	constructor(message: string, type: NotificationType) {
		this.message = message;
		this.type = type;
	}

	public isError() {
		return this.type === NotificationType.Error;
	}
}
