export const LANG_KEY_NAME = 'key';
export const LANG_KEY_TRANS = {
	'0030565001AE':'[0030565001AE]',
	'003056500368':'[003056500368]',
	'003056500394':'[003056500394]',
	'003056500398':'[003056500398]',
	'0030565011A8':'[0030565011A8]',
	'0030565011AE':'[0030565011AE]',
	'003056501200':'[003056501200]',
	'00305650121E':'[00305650121E]',
	'003056A98058':'[003056A98058]',
	'057X1to2Rpm':'[057X1to2Rpm]',
	'057X2to3Rpm':'[057X2to3Rpm]',
	'A000Key':'[A000Key]',
	'A001Country':'[A001Country]',
	'A002German':'[A002German]',
	'A003English':'[A003English]',
	'A004Username':'[A004Username]',
	'A005Login':'[A005Login]',
	'A006Password':'[A006Password]',
	'A007RemPassword':'[A007RemPassword]',
	'A008Login':'[A008Login]',
	'A009Logout':'[A009Logout]',
	'A010Production':'[A010Production]',
	'A011Availability':'[A011Availability]',
	'A012Maintenance':'[A012Maintenance]',
	'A013RemainingUnits':'[A013RemainingUnits]',
	'A014ProductionTime':'[A014ProductionTime]',
	'A015ProductionTodayGeneral':'[A015ProductionTodayGeneral]',
	'A015ProductionToday':'[A015ProductionToday]',
	'A016Units':'[A016Units]',
	'A017RemProduction':'[A017RemProduction]',
	'A018LastReset':'[A018LastReset]',
	'A019Faults':'[A019Faults]',
	'A019FaultsBatch':'[A019FaultsBatch]',
	'A020Count':'[A020Count]',
	'A021FaultDuration':'[A021FaultDuration]',
	'A022Minutes':'[A022Minutes]',
	'A023BagCounterPerFS':'[A023BagCounterPerFS]',
	'A023BagCounterPerFSBatch':'[A023BagCounterPerFSBatch]',
	'A024FillingStation':'[A024FillingStation]',
	'A025FSDifference':'[A025FSDifference]',
	'A026AtLeast1Caps':'[A026AtLeast1Caps]',
	'A026FSworstspout':'[A026FSworstspout]',
	'A027Percent':'[A027Percent]',
	'A028RejectionsToday':'[A028RejectionsToday]',
	'A028RejectionsTodayBatch':'[A028RejectionsTodayBatch]',
	'A029FilterStop':'[A029FilterStop]',
	'A030RotaryDriveStop':'[A030RotaryDriveStop]',
	'A031ProductFeedStop':'[A031ProductFeedStop]',
	'A032RadimatStop':'[A032RadimatStop]',
	'A033PalletizerStop':'[A033PalletizerStop]',
	'A034CompAirFault':'[A034CompAirFault]',
	'A035TypeChange':'[A035TypeChange]',
	'A035TypeChangeBatch':'[A035TypeChangeBatch]',
	'A036ProductionRate':'[A036ProductionRate]',
	'A037SpeedRelated':'[A037SpeedRelated]',
	'A038UnitsPerHour':'[A038UnitsPerHour]',
	'A039GoodWeights':'[A039GoodWeights]',
	'A040ScissorHose':'[A040ScissorHose]',
	'A041ConnectingHose':'[A041ConnectingHose]',
	'A042ConeGasket':'[A042ConeGasket]',
	'A043AerationPlates':'[A043AerationPlates]',
	'A043ScissorHose':'[A043ScissorHose]',
	'A044ConnectingHose':'[A044ConnectingHose]',
	'A044RegulationValve':'[A044RegulationValve]',
	'A045ConeFeeding':'[A045ConeFeeding]',
	'A045ConeGasket':'[A045ConeGasket]',
	'A046AerationPlates':'[A046AerationPlates]',
	'A046BagTransBelt':'[A046BagTransBelt]',
	'A047BagTransBearing':'[A047BagTransBearing]',
	'A047RegulationValve':'[A047RegulationValve]',
	'A048ConeFeeding':'[A048ConeFeeding]',
	'A048SuctionCup':'[A048SuctionCup]',
	'A049BagTransBelt':'[A049BagTransBelt]',
	'A049BagTransCyl':'[A049BagTransCyl]',
	'A050BagTransBearing':'[A050BagTransBearing]',
	'A050SucRailCyl':'[A050SucRailCyl]',
	'A051SuctionCup':'[A051SuctionCup]',
	'A051VacuumPump':'[A051VacuumPump]',
	'A052BagTransCyl':'[A052BagTransCyl]',
	'A052BagTransCylinde':'[A052BagTransCylinde]',
	'A052CableHightAdj':'[A052CableHightAdj]',
	'A053HullMinSensor':'[A053HullMinSensor]',
	'A053SucRailCyl':'[A053SucRailCyl]',
	'A053SucRailCylinder':'[A053SucRailCylinder]',
	'A054Last48Hours':'[A054Last48Hours]',
	'A054VacuumPump':'[A054VacuumPump]',
	'A055CableHightAdj':'[A055CableHightAdj]',
	'A055Last14Days':'[A055Last14Days]',
	'A056HullMinSensor':'[A056HullMinSensor]',
	'A056off':'[A056off]',
	'A057InOperation':'[A057InOperation]',
	'A058Faulty':'[A058Faulty]',
	'A059InMaintenance':'[A059InMaintenance]',
	'A060HourlyValues':'[A060HourlyValues]',
	'A061DischargesTotal':'[A061DischargesTotal]',
	'A062CreationDate':'[A062CreationDate]',
	'A062Period':'[A062Period]',
	'A063To':'[A063To]',
	'A064BatchValue':'[A064BatchValue]',
	'A064DailyValue':'[A064DailyValue]',
	'A064DailyValues':'[A064DailyValues]',
	'A065supply':'[A065supply]',
	'A066Transport':'[A066Transport]',
	'A067Feeding':'[A067Feeding]',
	'A068PackagesMissing':'[A068PackagesMissing]',
	'A069NoHistoricalData':'[A069NoHistoricalData]',
	'A070And':'[A070And]',
	'A071Available':'[A071Available]',
	'A072ReallyReset':'[A072ReallyReset]',
	'A073Details':'[A073Details]',
	'A074General':'[A074General]',
	'A075Machines':'[A075Machines]',
	'A076Dashborad':'[A076Dashborad]',
	'A077Administration':'[A077Administration]',
	'A078UserRoles':'[A078UserRoles]',
	'A079Companies':'[A079Companies]',
	'A080Machines':'[A080Machines]',
	'A081Date':'[A081Date]',
	'A082Transport':'[A082Transport]',
	'A083Containermissing':'[A083Containermissing]',
	'A084Availability':'[A084Availability]',
	'A085TypeChanges':'[A085TypeChanges]',
	'A086EmptyRejects':'[A086EmptyRejects]',
	'A086EmptyRejectsBatch':'[A086EmptyRejectsBatch]',
	'A087FullRejects':'[A087FullRejects]',
	'A087FullRejectsBatch':'[A087FullRejectsBatch]',
	'A088OpRelease':'[A088OpRelease]',
	'A089ProdFeedStop':'[A089ProdFeedStop]',
	'A090AuxDrive':'[A090AuxDrive]',
	'A091Consumables':'[A091Consumables]',
	'A092DischLineStop':'[A092DischLineStop]',
	'A093TypeChange':'[A093TypeChange]',
	'A094DailyProduction':'[A094DailyProduction]',
	'A094DailyProductionBatch':'[A094DailyProductionBatch]',
	'A095Energy':'[A095Energy]',
	'A096EffectivePower':'[A096EffectivePower]',
	'A097ApparentPower':'[A097ApparentPower]',
	'A098CompAirFlow':'[A098CompAirFlow]',
	'A099ResetFailed':'[A099ResetFailed]',
	'A1000Spanish':'[A1000Spanish]',
	'A1000UploadMaintenanceAid':'[A1000UploadMaintenanceAid]',
	'A1001UploadMaintenanceAidSuccess':'[A1001UploadMaintenanceAidSuccess]',
	'A1002UploadMaintenanceAidFailure':'[A1002UploadMaintenanceAidFailure]',
	'A1005MachineServiceRequest':'[A1005MachineServiceRequest]',
	'A1006RequestService':'[A1006RequestService]',
	'A1007ServiceContract':'[A1007ServiceContract]',
	'A1008ServiceRequestSuccesful':'[A1008ServiceRequestSuccesful]',
	'A1009ServiceRequestFailure':'[A1009ServiceRequestFailure]',
	'A100AcessDenied':'[A100AcessDenied]',
	'A100AlertmailSubject':'[A100AlertmailSubject]',
	'A1010LogBook':'[A1010LogBook]',
	'A1011CreateLogBook':'[A1011CreateLogBook]',
	'A1012EditLogBook':'[A1012EditLogBook]',
	'A1013LogBookItemType':'[A1013LogBookItemType]',
	'A1014LogBookItem':'[A1014LogBookItem]',
	'A1015LogBookItemSaved':'[A1015LogBookItemSaved]',
	'A1016ErrorInSavingLogBookItem':'[A1016ErrorInSavingLogBookItem]',
	'A1017ErrorInRetrievingLogBookItem':'[A1017ErrorInRetrievingLogBookItem]',
	'A1018ErrorInDeletingLogBookItem':'[A1018ErrorInDeletingLogBookItem]',
	'A1019LogBookItemDeleted':'[A1019LogBookItemDeleted]',
	'A101MailSalutation':'[A101MailSalutation]',
	'A101ResetSuccessful':'[A101ResetSuccessful]',
	'A1020LogBookItemIsDone':'[A1020LogBookItemIsDone]',
	'A1021LogBookItemType':'[A1021LogBookItemType]',
	'A1022ServiceEmail':'[A1022ServiceEmail]',
	'A1023CannotMakeServieRequest':'[A1023CannotMakeServieRequest]',
	'A1024MaxUsers':'[A1024MaxUsers]',
	'A1024MaxUsersReached':'[A1024MaxUsersReached]',
	'A1025AtLeast8Characters':'[A1025AtLeast8Characters]',
	'A1027AtLeast1Lowercase':'[A1027AtLeast1Lowercase]',
	'A1028AtLeast1Number':'[A1028AtLeast1Number]',
	'A1029AtLeast1SpecialChar':'[A1029AtLeast1SpecialChar]',
	'A102CompanyAddressNewtec':'[A102CompanyAddressNewtec]',
	'A102CompanyAddressAventus':'[A102CompanyAddressAventus]',
	'A102CompanyAddressNiagaraCanada':'[A102CompanyAddressNiagaraCanada]',
	'A102CompanyAddressNiagaraChile':'[A102CompanyAddressNiagaraChile]',
	'A102CompanyAddressNiagaraBrazil':'[A102CompanyAddressNiagaraBrazil]',
	'A102CompanyAddressNiagara':'[A102CompanyAddressNiagara]',
	'A102CompanyAddressFeige':'[A102CompanyAddressFeige]',
	'A102CompanyAddressH_B':'[A102CompanyAddressH_B]',
	'A102CompanyAddressBehnBates':'[A102CompanyAddressBehnBates]',
	'A102CompanyEmailAventus':'[A102CompanyEmailAventus]',
	'A102CompanyEmailNiagaraCanada':'[A102CompanyEmailNiagaraCanada]',
	'A102CompanyEmailNiagaraChile':'[A102CompanyEmailNiagaraChile]',
	'A102CompanyEmailNiagaraBrazil':'[A102CompanyEmailNiagaraBrazil]',
	'A102CompanyEmailNiagara':'[A102CompanyEmailNiagara]',
	'A102CompanyEmailNewtec':'[A102CompanyEmailNewtec]',
	'A102CompanyEmailFeige':'[A102CompanyEmailFeige]',
	'A102CompanyEmailH_B':'[A102CompanyEmailH_B]',
	'A102CompanyEmailBehnBates':'[A102CompanyEmailBehnBates]',
	'A102CompanyPhoneNumberNewtec':'[A102CompanyPhoneNumberNewtec]',
	'A102CompanyPhoneNumberFeige':'[A102CompanyPhoneNumberFeige]',
	'A102CompanyPhoneNumberAventus':'[A102CompanyPhoneNumberAventus]',
	'A102CompanyPhoneNumberNiagaraCanada':'[A102CompanyPhoneNumberNiagaraCanada]',
	'A102CompanyPhoneNumberNiagaraChile':'[A102CompanyPhoneNumberNiagaraChile]',
	'A102CompanyPhoneNumberNiagaraBrazil':'[A102CompanyPhoneNumberNiagaraBrazil]',
	'A102CompanyPhoneNumberNiagara':'[A102CompanyPhoneNumberNiagara]',
	'A102CompanyPhoneNumberH_B':'[A102CompanyPhoneNumberH_B]',
	'A102CompanyPhoneNumberBehnBates':'[A102CompanyPhoneNumberBehnBates]',
	'A102CompanyWebsiteNewtec':'[A102CompanyWebsiteNewtec]',
	'A102CompanyWebsiteFeige':'[A102CompanyWebsiteFeige]',
	'A102CompanyWebsiteAventus':'[A102CompanyWebsiteAventus]',
	'A102CompanyWebsiteNiagaraCanada':'[A102CompanyWebsiteNiagaraCanada]',
	'A102CompanyWebsiteNiagaraChile':'[A102CompanyWebsiteNiagaraChile]',
	'A102CompanyWebsiteNiagaraBrazil':'[A102CompanyWebsiteNiagaraBrazil]',
	'A102CompanyWebsiteNiagara':'[A102CompanyWebsiteNiagara]',
	'A102CompanyWebsiteH_B':'[A102CompanyWebsiteH_B]',
	'A102CompanyWebsiteBehnBates':'[A102CompanyWebsiteBehnBates]',
	'A102MailContent':'[A102MailContent]',
	'A102TypeChanges':'[A102TypeChanges]',
	'A102TypeChangesBatch':'[A102TypeChangesBatch]',
	'A102TYPWebsite':'[A102TYPWebsite]',
	'A1030Description':'[A1030Description]',
	'A103EndOfBatch':'[A103EndOfBatch]',
	'A103ResetmailSubject':'[A103ResetmailSubject]',
	'A104FullPalletCount':'[A104FullPalletCount]',
	'A104ResetMailContent':'[A104ResetMailContent]',
	'A105ConnectivityMailSubject':'[A105ConnectivityMailSubject]',
	'A105LoadEmptyPallets':'[A105LoadEmptyPallets]',
	'A106Fillings':'[A106Fillings]',
	'A106PLCMailSubject':'[A106PLCMailSubject]',
	'A107FillingsPerHour':'[A107FillingsPerHour]',
	'A107PLCResolvedMailContent':'[A107PLCResolvedMailContent]',
	'A108Availability':'[A108Availability]',
	'A108AvailabilityMailSubject':'[A108AvailabilityMailSubject]',
	'A109FillingsRemain':'[A109FillingsRemain]',
	'A110StartOfOrder':'[A110StartOfOrder]',
	'A111ProductID':'[A111ProductID]',
	'A112TargetWeight':'[A112TargetWeight]',
	'A113Kg':'[A113Kg]',
	'A114CurrentOrder':'[A114CurrentOrder]',
	'A115FillingCount':'[A115FillingCount]',
	'A116CycleTime':'[A116CycleTime]',
	'A117Total':'[A117Total]',
	'A118CentreStation':'[A118CentreStation]',
	'A119N2Station':'[A119N2Station]',
	'A120FillingStation':'[A120FillingStation]',
	'A121SealingStation':'[A121SealingStation]',
	'A122Seconds':'[A122Seconds]',
	'A123FaultLength':'[A123FaultLength]',
	'A124Minutes':'[A124Minutes]',
	'A124Minute':'[A124Minute]',
	'A125ExtFaultLength':'[A125ExtFaultLength]',
	'A126FillingCount':'[A126FillingCount]',
	'A127WithinTolerance':'[A127WithinTolerance]',
	'A128OutsideTolerance':'[A128OutsideTolerance]',
	'A129BagManufacture':'[A129BagManufacture]',
	'A130BagsPerHour':'[A130BagsPerHour]',
	'A131BagQuality':'[A131BagQuality]',
	'A132CorrPlacedbags':'[A132CorrPlacedbags]',
	'A133TwoAttempts':'[A133TwoAttempts]',
	'A134ThreeAttempts':'[A134ThreeAttempts]',
	'A135DiscardeBags':'[A135DiscardeBags]',
	'A136Faults':'[A136Faults]',
	'A137CompAir':'[A137CompAir]',
	'A138ProductFeed':'[A138ProductFeed]',
	'A139EmptyBagMag':'[A139EmptyBagMag]',
	'A140Separation':'[A140Separation]',
	'A141Vacuum':'[A141Vacuum]',
	'A142FillingStation':'[A142FillingStation]',
	'A143FullBagTransport':'[A143FullBagTransport]',
	'A144BagSealing':'[A144BagSealing]',
	'A145Disrupt':'[A145Disrupt]',
	'A145AccessDoor':'[A145AccessDoor]',
	'A145DSafetyLightBarrier':'[A145DSafetyLightBarrier]',
	'A145UpstreamSupply':'[A145UpstreamSupply]',
	'A145EmergencyStop':'[A145EmergencyStop]',
	'A146BunChangeAbs':'[A146BunChangeAbs]',
	'A147BunChangeToday':'[A147BunChangeToday]',
	'A147BunChangeTodayBatch':'[A147BunChangeTodayBatch]',
	'A148BagsPerBundle':'[A148BagsPerBundle]',
	'A149TypeChanges':'[A149TypeChanges]',
	'A149TypeChangesBatch':'[A149TypeChangesBatch]',
	'A150FillingTime':'[A150FillingTime]',
	'A151DosingUnit1':'[A151DosingUnit1]',
	'A152DosingUnit2':'[A152DosingUnit2]',
	'A153AerInletBox1':'[A153AerInletBox1]',
	'A154AerInletBox2':'[A154AerInletBox2]',
	'A154DosingUnit1':'[A154DosingUnit1]',
	'A155DosingUnit2':'[A155DosingUnit2]',
	'A155RotaryDrive':'[A155RotaryDrive]',
	'A156AerInletBox1':'[A156AerInletBox1]',
	'A156DischargeBelt':'[A156DischargeBelt]',
	'A157AerInletBox2':'[A157AerInletBox2]',
	'A157AlignmentBelt':'[A157AlignmentBelt]',
	'A158BagCleaning':'[A158BagCleaning]',
	'A158RotaryDrive':'[A158RotaryDrive]',
	'A159AccelerationBelt':'[A159AccelerationBelt]',
	'A159DischargeBelt':'[A159DischargeBelt]',
	'A160AlignmentBelt':'[A160AlignmentBelt]',
	'A160CheckBelt':'[A160CheckBelt]',
	'A161BagCleaning':'[A161BagCleaning]',
	'A161FlatBelt':'[A161FlatBelt]',
	'A162AccelerationBelt':'[A162AccelerationBelt]',
	'A162RejectionBelt':'[A162RejectionBelt]',
	'A163CheckBelt':'[A163CheckBelt]',
	'A163RejectionDrive':'[A163RejectionDrive]',
	'A164FlatBelt':'[A164FlatBelt]',
	'A164RotaryCutter':'[A164RotaryCutter]',
	'A165RejectionBelt':'[A165RejectionBelt]',
	'A165ScreenDrum':'[A165ScreenDrum]',
	'A166RejectionDrive':'[A166RejectionDrive]',
	'A166Welding':'[A166Welding]',
	'A167Cutter':'[A167Cutter]',
	'A167RotaryCutter':'[A167RotaryCutter]',
	'A168ScreenDrum':'[A168ScreenDrum]',
	'A168SuctionCup':'[A168SuctionCup]',
	'A169Brakes':'[A169Brakes]',
	'A170VacuumPump':'[A170VacuumPump]',
	'A171Filter':'[A171Filter]',
	'A172BagDisConveyor':'[A172BagDisConveyor]',
	'A173BagTurnConveyor':'[A173BagTurnConveyor]',
	'A174BagTurnCross':'[A174BagTurnCross]',
	'A175BagDisConveyor':'[A175BagDisConveyor]',
	'A175BagTurnClamp':'[A175BagTurnClamp]',
	'A176BagTurnConveyor':'[A176BagTurnConveyor]',
	'A176GroupingBelt':'[A176GroupingBelt]',
	'A177BagTurnCross':'[A177BagTurnCross]',
	'A177RowPusher':'[A177RowPusher]',
	'A178DoubleStripPlate':'[A178DoubleStripPlate]',
	'A179ConformingPlate':'[A179ConformingPlate]',
	'A179GroupingBelt':'[A179GroupingBelt]',
	'A180GantrySafety':'[A180GantrySafety]',
	'A180RowPusher':'[A180RowPusher]',
	'A181DoubleStripPlate':'[A181DoubleStripPlate]',
	'A181Gantry':'[A181Gantry]',
	'A182ConformingPlate':'[A182ConformingPlate]',
	'A182PalletAlignment':'[A182PalletAlignment]',
	'A183GantrySafety':'[A183GantrySafety]',
	'A183PalletRoller1':'[A183PalletRoller1]',
	'A184Gantry':'[A184Gantry]',
	'A184PalletDispenser':'[A184PalletDispenser]',
	'A185PalletAlignment':'[A185PalletAlignment]',
	'A185PalletRoller2':'[A185PalletRoller2]',
	'A186PalletRoller1':'[A186PalletRoller1]',
	'A186PalletRoller3':'[A186PalletRoller3]',
	'A187CyclesChain':'[A187CyclesChain]',
	'A187PalletDispenser':'[A187PalletDispenser]',
	'A188BundlePusher':'[A188BundlePusher]',
	'A188PalletRoller2':'[A188PalletRoller2]',
	'A189BundlePartition':'[A189BundlePartition]',
	'A189PalletRoller3':'[A189PalletRoller3]',
	'A190BagLifter':'[A190BagLifter]',
	'A190CyclesChain':'[A190CyclesChain]',
	'A191BundlePusher':'[A191BundlePusher]',
	'A191PressRolls':'[A191PressRolls]',
	'A192BundlePartition':'[A192BundlePartition]',
	'A192OpeningStation':'[A192OpeningStation]',
	'A193BagApplicator':'[A193BagApplicator]',
	'A193BagLifter':'[A193BagLifter]',
	'A194BagSupport':'[A194BagSupport]',
	'A194PressRolls':'[A194PressRolls]',
	'A195OpeningStation':'[A195OpeningStation]',
	'A195Pusher':'[A195Pusher]',
	'A196BagApplicator':'[A196BagApplicator]',
	'A196StretchPliers':'[A196StretchPliers]',
	'A197BagSupport':'[A197BagSupport]',
	'A197FillingStation':'[A197FillingStation]',
	'A198FilledBagGripper':'[A198FilledBagGripper]',
	'A198Pusher':'[A198Pusher]',
	'A199StretchPliers':'[A199StretchPliers]',
	'A199SucBagSeperation':'[A199SucBagSeperation]',
	'A200FillingStation':'[A200FillingStation]',
	'A200SucOpenStation':'[A200SucOpenStation]',
	'A201FilledBagGripper':'[A201FilledBagGripper]',
	'A201Remaininghours':'[A201Remaininghours]',
	'A202PriceTotal':'[A202PriceTotal]',
	'A202SucBagSeperation':'[A202SucBagSeperation]',
	'A203RemainingNumberFillings':'[A203RemainingNumberFillings]',
	'A203SucOpenStation':'[A203SucOpenStation]',
	'A204SafetyDoor':'[A204SafetyDoor]',
	'A205SafetyLightBarrier':'[A205SafetyLightBarrier]',
	'A206BagTransport':'[A206BagTransport]',
	'A207EmptyPalletTrans':'[A207EmptyPalletTrans]',
	'A208Palletizer':'[A208Palletizer]',
	'A209FullPalletTrans':'[A209FullPalletTrans]',
	'A210NumberTodayPiece':'[A210NumberTodayPiece]',
	'A211Programme':'[A211Programme]',
	'A212Efficiency':'[A212Efficiency]',
	'A213Loginsuccessful':'[A213Loginsuccessful]',
	'A214CurrentPerformance':'[A214CurrentPerformance]',
	'A215CyclesToday':'[A215CyclesToday]',
	'A215CyclesTodayBatch':'[A215CyclesTodayBatch]',
	'A216RejectsToday':'[A216RejectsToday]',
	'A216RejectsTodayBatch':'[A216RejectsTodayBatch]',
	'A217MetalDetectorToday':'[A217MetalDetectorToday]',
	'A217MetalDetectorTodayBatch':'[A217MetalDetectorTodayBatch]',
	'A218BrokenBagsToday':'[A218BrokenBagsToday]',
	'A218BrokenBagsTodayBatch':'[A218BrokenBagsTodayBatch]',
	'A219FaultPerShift':'[A219FaultPerShift]',
	'A220FaultBagManufacture':'[A220FaultBagManufacture]',
	'A221FaultBagFilling':'[A221FaultBagFilling]',
	'A222FaultBagTransport':'[A222FaultBagTransport]',
	'A223FaultSealing':'[A223FaultSealing]',
	'A224FaultWeigher':'[A224FaultWeigher]',
	'A225FaultPeriphery':'[A225FaultPeriphery]',
	'A226Shift':'[A226Shift]',
	'A227Container':'[A227Container]',
	'A228Hours':'[A228Hours]',
	'A229NoPackage':'[A229NoPackage]',
	'A230NoProduct':'[A230NoProduct]',
	'A231NoOutlet':'[A231NoOutlet]',
	'A232GoodPerformance':'[A232GoodPerformance]',
	'A233GoodWeights':'[A233GoodWeights]',
	'A234NotPlacedBags':'[A234NotPlacedBags]',
	'A234NotPlacedBagsAutomatic':'[A234NotPlacedBagsAutomatic]',
	'A235CollectiveFaultsToday':'[A235CollectiveFaultsToday]',
	'A235CollectiveFaultsTodayBatch':'[A235CollectiveFaultsTodayBatch]',
	'A236Applicator':'[A236Applicator]',
	'A237NetWeigher':'[A237NetWeigher]',
	'A238IncorrectWeight':'[A238IncorrectWeight]',
	'A239Hours':'[A239Hours]',
	'A240QrCode':'[A240QrCode]',
	'A241OpenBags':'[A241OpenBags]',
	'A242FaultCheckWeigher':'[A242FaultCheckWeigher]',
	'A243DashboardAllMachines':'[A243DashboardAllMachines]',
	'A244DashboardState':'[A244DashboardState]',
	'A245DashboardMachineStateRunning':'[A245DashboardMachineStateRunning]',
	'A246DashboardMachineStateOffline':'[A246DashboardMachineStateOffline]',
	'A247DashboardMachineStateMaintenance':'[A247DashboardMachineStateMaintenance]',
	'A248DashboardMachineStateError':'[A248DashboardMachineStateError]',
	'A249SortChangeToday':'[A249SortChangeToday]',
	'A249SortChangeTodayBatch':'[A249SortChangeTodayBatch]',
	'A250EmptyBagMagazineToday':'[A250EmptyBagMagazineToday]',
	'A250EmptyBagMagazineTodayBatch':'[A250EmptyBagMagazineTodayBatch]',
	'A251FaultCheckWeigher':'[A251FaultCheckWeigher]',
	'A252IVTSTOP':'[A252IVTSTOP]',
	'A253N/A':'[A253N/A]',
	'A254CyclePumpFS1':'[A254CyclePumpFS1]',
	'A255CyclePumpFS2':'[A255CyclePumpFS2]',
	'A256CyclePumpFS3':'[A256CyclePumpFS3]',
	'A257ConHoseFS1':'[A257ConHoseFS1]',
	'A258ConHoseFS2':'[A258ConHoseFS2]',
	'A259ConHoseFS3':'[A259ConHoseFS3]',
	'A260InfSleeveFS1':'[A260InfSleeveFS1]',
	'A261InfSleeveFS2':'[A261InfSleeveFS2]',
	'A262InfSleeveFS3':'[A262InfSleeveFS3]',
	'A263BundlePusher':'[A263BundlePusher]',
	'A264VacuumUnit':'[A264VacuumUnit]',
	'A265TravellingDrive':'[A265TravellingDrive]',
	'A266CenteringUnit':'[A266CenteringUnit]',
	'A267EmptyBagMagazine':'[A267EmptyBagMagazine]',
	'A268BeltPlant':'[A268BeltPlant]',
	'A269IvtTurbine':'[A269IvtTurbine]',
	'A270IvtTripSaddle':'[A270IvtTripSaddle]',
	'A271IvtCoarseFeed':'[A271IvtCoarseFeed]',
	'A272IvtBagHolder':'[A272IvtBagHolder]',
	'A273IvtFillBoxAir':'[A273IvtFillBoxAir]',
	'A274IvtFillChanAir':'[A274IvtFillChanAir]',
	'A275IvtInflSleeve':'[A275IvtInflSleeve]',
	'A276IvtInletBoxAir':'[A276IvtInletBoxAir]',
	'A277IvtFillTubeBlow':'[A277IvtFillTubeBlow]',
	'A278IvtOutlChanAir':'[A278IvtOutlChanAir]',
	'A279IvtPusher':'[A279IvtPusher]',
	'A280IvtSaddleHeight':'[A280IvtSaddleHeight]',
	'A281IvtCloseAnvil':'[A281IvtCloseAnvil]',
	'A282IvtBagSeal':'[A282IvtBagSeal]',
	'A283IvtFineFeedAdj':'[A283IvtFineFeedAdj]',
	'A284Interrupts':'[A284Interrupts]',
	'A284InterruptsGeneralPage':'[A284InterruptsGeneralPage]',
	'A284InterruptsBatch':'[A284InterruptsBatch]',
	'A285BagCounterShift':'[A285BagCounterShift]',
	'A286WrongBags':'[A286WrongBags]',
	'A287ReelChanges':'[A287ReelChanges]',
	'A287ReelChangesBatch':'[A287ReelChangesBatch]',
	'A288VibrationDuration':'[A288VibrationDuration]',
	'A289Total':'[A289Total]',
	'A290HopperMin':'[A290HopperMin]',
	'A291ManualActivation':'[A291ManualActivation]',
	'A292FillingRelease':'[A292FillingRelease]',
	'A293EGF':'[A293EGF]',
	'A294PalletTrasport':'[A294PalletTrasport]',
	'A295SafetyCircuit':'[A295SafetyCircuit]',
	'A296FltCompAir':'[A296FltCompAir]',
	'A296FltCompAirBatch':'[A296FltCompAirBatch]',
	'A297StrokesPump':'[A297StrokesPump]',
	'A297StrokesPumpBatch':'[A297StrokesPumpBatch]',
	'A298MaxStrokeDur':'[A298MaxStrokeDur]',
	'A298MaxStrokeDurBatch':'[A298MaxStrokeDurBatch]',
	'A299AvgStrokes':'[A299AvgStrokes]',
	'A299AvgStrokesBatch':'[A299AvgStrokesBatch]',
	'A300InternalPressure':'[A300InternalPressure]',
	'A301Max':'[A301Max]',
	'A301MessageAdministration':'[A301MessageAdministration]',
	'A302ErrorNumber':'[A302ErrorNumber]',
	'A302Millibar':'[A302Millibar]',
	'A303ActivateAlertMail':'[A303ActivateAlertMail]',
	'A303SetValueMax':'[A303SetValueMax]',
	'A304AlertText':'[A304AlertText]',
	'A304SetValueMin':'[A304SetValueMin]',
	'A305ActivateResolvedMail':'[A305ActivateResolvedMail]',
	'A305SetValueChanged':'[A305SetValueChanged]',
	'A305SetValueChangedRefreshWindow':'[A305SetValueChangedRefreshWindow]',
	'A306KW':'[A306KW]',
	'A306Resolved Text':'[A306Resolved Text]',
	'A306ResolvedText':'[A306ResolvedText]',
	'A307AddMessage':'[A307AddMessage]',
	'A307KVA':'[A307KVA]',
	'A308EditMessage':'[A308EditMessage]',
	'A308Nm3PerH':'[A308Nm3PerH]',
	'A309KWH':'[A309KWH]',
	'A310NM3':'[A310NM3]',
	'A311CompAirVol':'[A311CompAirVol]',
	'A312ActiveEnergy':'[A312ActiveEnergy]',
	'A313ReactivePower':'[A313ReactivePower]',
	'A314ChartConfiguration':'[A314ChartConfiguration]',
	'A315SetDateValueMax':'[A315SetDateValueMax]',
	'A316SetDateValueMin':'[A316SetDateValueMin]',
	'A317InvalidData':'[A317InvalidData]',
	'A318DeleteUser':'[A318DeleteUser]',
	'A319EnterAllFields':'[A319EnterAllFields]',
	'A320EnterValidEmail':'[A320EnterValidEmail]',
	'A321DeleteCompany':'[A321DeleteCompany]',
	'A322CouldNotDeleteUser':'[A322CouldNotDeleteUser]',
	'A323LoginSuccess':'[A323LoginSuccess]',
	'A324Selected':'[A324Selected]',
	'A325Total':'[A325Total]',
	'A326Reset':'[A326Reset]',
	'A327Reporting':'[A327Reporting]',
	'A328ReportingActive':'[A328ReportingActive]',
	'A329ReportingActiveHelpPrefix':'[A329ReportingActiveHelpPrefix]',
	'A330ReportingActiveHelpSuffix':'[A330ReportingActiveHelpSuffix]',
	'A331ReportingFrequency':'[A331ReportingFrequency]',
	'A332ReportingFrequencyDaily':'[A332ReportingFrequencyDaily]',
	'A333ReportingFrequencyWeekly':'[A333ReportingFrequencyWeekly]',
	'A334ReportingFrequencyMonthly':'[A334ReportingFrequencyMonthly]',
	'A335ReportingDynContent':'[A335ReportingDynContent]',
	'A336ReportingDynContIncludeElapsedMI':'[A336ReportingDynContIncludeElapsedMI]',
	'A337ReportingLanguage':'[A337ReportingLanguage]',
	'A338ReportingProdDayEnd':'[A338ReportingProdDayEnd]',
	'A339ReportingFirstReport':'[A339ReportingFirstReport]',
	'A340CardCtxMenuReport':'[A340CardCtxMenuReport]',
	'A341CardCtxMenuHistoReport':'[A341CardCtxMenuHistoReport]',
	'A342CardCtxMenuReset':'[A342CardCtxMenuReset]',
	'A343CardCtxMenuSettings':'[A343CardCtxMenuSettings]',
	'A344CardLabelReport':'[A344CardLabelReport]',
	'A345CardLabelHistoReport':'[A345CardLabelHistoReport]',
	'A346CardLabelMaintenReport':'[A346CardLabelMaintenReport]',
	'A347ReportSettings':'[A347ReportSettings]',
	'A348Alerting':'[A348Alerting]',
	'A349AlertingSettings':'[A349AlertingSettings]',
	'A350AlertingActive':'[A350AlertingActive]',
	'A351AlertingActiveHelpPrefix':'[A351AlertingActiveHelpPrefix]',
	'A352AlertingActiveHelpSuffix':'[A352AlertingActiveHelpSuffix]',
	'A353AlertingTypes':'[A353AlertingTypes]',
	'A354AlertTypeMainten':'[A354AlertTypeMainten]',
	'A355AlertTypeAvail':'[A355AlertTypeAvail]',
	'A356AlertTypePLC':'[A356AlertTypePLC]',
	'A357AlertTypeConError':'[A357AlertTypeConError]',
	'A358DeleteAdminUser':'[A358DeleteAdminUser]',
	'A359MaintAids':'[A359MaintAids]',
	'A360MaintInt':'[A360MaintInt]',
	'A361ChooseMaschine':'[A361ChooseMaschine]',
	'A362Video':'[A362Video]',
	'A363InstructionManual':'[A363InstructionManual]',
	'A364ExplodedviewDrawing':'[A364ExplodedviewDrawing]',
	'A364SparePartCatalog':'[A364SparePartCatalog]',
	'A365OpenVideoHelp':'[A365OpenVideoHelp]',
	'A366OpenInstructionManual':'[A366OpenInstructionManual]',
	'A367OpenExploadedView':'[A367OpenExploadedView]',
	'A368ResetMaschineData':'[A368ResetMaschineData]',
	'A369BotWeld':'[A369BotWeld]',
	'A370BotWeldTef':'[A370BotWeldTef]',
	'A371TopWeld':'[A371TopWeld]',
	'A372TopWeldTef':'[A372TopWeldTef]',
	'A373LeftWeld':'[A373LeftWeld]',
	'A374LefWeldTef':'[A374LefWeldTef]',
	'A375RigWeld':'[A375RigWeld]',
	'A376RigWeldTef':'[A376RigWeldTef]',
	'A377CutKnife':'[A377CutKnife]',
	'A378ReallyClearReport':'[A378ReallyClearReport]',
	'A379ProdCurrShift':'[A379ProdCurrShift]',
	'A380ReallyDeleteItemReport':'[A380ReallyDeleteItemReport]',
	'A380TypeCurrent':'[A380TypeCurrent]',
	'A381SampleBags':'[A381SampleBags]',
	'A382OpenBags':'[A382OpenBags]',
	'A383IncorrectBags':'[A383IncorrectBags]',
	'A384NotPlacedBagsMan':'[A384NotPlacedBagsMan]',
	'A385Spout1':'[A385Spout1]',
	'A386LastUpdate':'[A386LastUpdate]',
	'A386Spout2':'[A386Spout2]',
	'A387Spout3':'[A387Spout3]',
	'A388Spout4':'[A388Spout4]',
	'A389LastUpdate':'[A389LastUpdate]',
	'A390FilledBagTrans':'[A390FilledBagTrans]',
	'A391ConveyerSystem':'[A391ConveyerSystem]',
	'A392BeltBundleTrans':'[A392BeltBundleTrans]',
	'A393BagHolderBuffer':'[A393BagHolderBuffer]',
	'A394SlidePlates':'[A394SlidePlates]',
	'A395ImpellerShaft':'[A395ImpellerShaft]',
	'A396ShaftAssembly':'[A396ShaftAssembly]',
	'A397ThreePosCylinder':'[A397ThreePosCylinder]',
	'A398DateFormatNoSeconds':'[A398DateFormatNoSeconds]',
	'A399DateFormat':'[A399DateFormat]',
	'A399DateFormatAngularPipe':'[A399DateFormatAngularPipe]',
	'A400ConveyorSystem':'[A400ConveyorSystem]',
	'A400History':'[A400History]',
	'A401ReportHistory':'[A401ReportHistory]',
	'A402ReportId':'[A402ReportId]',
	'A403ReportGeneratedTime':'[A403ReportGeneratedTime]',
	'A404DosingFeeder':'[A404DosingFeeder]',
	'A404ReportGeneratedBy':'[A404ReportGeneratedBy]',
	'A405DownloadReport':'[A405DownloadReport]',
	'A406TriggeredHistoryReport':'[A406TriggeredHistoryReport]',
	'A407ViewReport':'[A407ViewReport]',
	'A408BagHolder':'[A408BagHolder]',
	'A408BagHolder ':'[A408BagHolder ]',
	'A414BundleTransport':'[A414BundleTransport]',
	'A415HoistingDevice':'[A415HoistingDevice]',
	'A416PlacerArm':'[A416PlacerArm]',
	'A417CrossProcess':'[A417CrossProcess]',
	'A418OpeningStation':'[A418OpeningStation]',
	'A421Claw':'[A421Claw]',
	'A422WeightData':'[A422WeightData]',
	'A423StdDevData':'[A423StdDevData]',
	'A430kg':'[A430kg]',
	'A431lb':'[A431lb]',
	'A432t':'[A432t]',
	'A433klb':'[A433klb]',
	'A434g':'[A434g]',
	'A435BagManufacture':'[A435BagManufacture]',
	'A436Aux_Pump':'[A436Aux_Pump]',
	'A437TopWelding':'[A437TopWelding]',
	'A438PunchingDeviceWelding':'[A438PunchingDeviceWelding]',
	'A439Aux_Vibrator1':'[A439Aux_Vibrator1]',
	'A440Aux_Vibrator2':'[A440Aux_Vibrator2]',
	'A441Aux_Vibrator3':'[A441Aux_Vibrator3]',
	'A442Truck_Loading':'[A442Truck_Loading]',
	'A443Truck_Available':'[A443Truck_Available]',
	'A444FS1':'[A444FS1]',
	'A445FS2':'[A445FS2]',
	'A446FS3':'[A446FS3]',
	'A447FS4':'[A447FS4]',
	'A448FS5':'[A448FS5]',
	'A449FS6':'[A449FS6]',
	'A450FS7':'[A450FS7]',
	'A451FS8':'[A451FS8]',
	'A452FS9':'[A452FS9]',
	'A453FS10':'[A453FS10]',
	'A454FS11':'[A454FS11]',
	'A455FS12':'[A455FS12]',
	'A456FS13':'[A456FS13]',
	'A457FS14':'[A457FS14]',
	'A458FS15':'[A458FS15]',
	'A459FS16':'[A459FS16]',
	'A460OeeValue':'[A460OeeValue]',
	'A460Oee':'[A460Oee]',
	'A460PushAlert':'[A460PushAlert]',
	'A461OeeAvailable':'[A461OeeAvailable]',
	'A461PushAlertResolved':'[A461PushAlertResolved]',
	'A462OeePerformance':'[A462OeePerformance]',
	'A463DowntimePlaned':'[A463DowntimePlaned]',
	'A464DowntimeMachine':'[A464DowntimeMachine]',
	'A465DowntimeOther':'[A465DowntimeOther]',
	'A466OperatingFactor':'[A466OperatingFactor]',
	'A467Throughput':'[A467Throughput]',
	'A468OeeRejects':'[A468OeeRejects]',
	'A469OeeQuality':'[A469OeeQuality]',
	'A470OeeDowntime':'[A470OeeDowntime]',
	'A471RejectsTotal':'[A471RejectsTotal]',
	'A472RejectsIncorrect':'[A472RejectsIncorrect]',
	'A473RejectsOpen':'[A473RejectsOpen]',
	'A474RejectsSample':'[A474RejectsSample]',
	'A475RejectsMetal':'[A475RejectsMetal]',
	'A476ConfigTable':'[A476ConfigTable]',
	'A477OEERowActive':'[A477OEERowActive]',
	'A478OEEProduction':'[A478OEEProduction]',
	'A479OEEBreak':'[A479OEEBreak]',
	'A480OEEMaint':'[A480OEEMaint]',
	'A481OEETypeChg':'[A481OEETypeChg]',
	'A482OEEOthers':'[A482OEEOthers]',
	'A483OEEFrequ':'[A483OEEFrequ]',
	'A484OEEUnique':'[A484OEEUnique]',
	'A485OEEDaily':'[A485OEEDaily]',
	'A486OEEWeekly':'[A486OEEWeekly]',
	'A487Days':'[A487Days]',
	'A488Monday':'[A488Monday]',
	'A489Tuesday':'[A489Tuesday]',
	'A490Wednesday':'[A490Wednesday]',
	'A491Thursday':'[A491Thursday]',
	'A492Friday':'[A492Friday]',
	'A494Saturday':'[A494Saturday]',
	'A498Comment':'[A498Comment]',
	'A495Sunday':'[A495Sunday]',
	'A496From':'[A496From]',
	'A497To':'[A497To]',
	'A499TabMachineMessages':'[A499TabMachineMessages]',
	'A500MMLive':'[A500MMLive]',
	'A500VbeltDrive':'[A500VbeltDrive]',
	'A501MMStatistic':'[A501MMStatistic]',
	'A501VbeltTurbine':'[A501VbeltTurbine]',
	'A502Impeller':'[A502Impeller]',
	'A502MMLive':'[A502MMLive]',
	'A503FillingTube':'[A503FillingTube]',
	'A503MMStatistic':'[A503MMStatistic]',
	'A504BagGuide':'[A504BagGuide]',
	'A504Type':'[A504Type]',
	'A505Control':'[A505Control]',
	'A505Saddle':'[A505Saddle]',
	'A506BoltBagChair':'[A506BoltBagChair]',
	'A506Number':'[A506Number]',
	'A507BushBagChair':'[A507BushBagChair]',
	'A507Message':'[A507Message]',
	'A508Amount':'[A508Amount]',
	'A508BearingPusher':'[A508BearingPusher]',
	'A509Duration':'[A509Duration]',
	'A509RearSlidePlate':'[A509RearSlidePlate]',
	'A510SlideValve':'[A510SlideValve]',
	'A510TonsPerHour':'[A510TonsPerHour]',
	'A511FrontSlidePlate':'[A511FrontSlidePlate]',
	'A511Tons':'[A511Tons]',
	'A512RepairKitSlidePlates':'[A512RepairKitSlidePlates]',
	'A512RPM':'[A512RPM]',
	'A513BagChairChange':'[A513BagChairChange]',
	'A513BagTransBeltServo':'[A513BagTransBeltServo]',
	'A514InfeedBelt':'[A514InfeedBelt]',
	'A515FlattBelt':'[A515FlattBelt]',
	'A516BagTakingBelt':'[A516BagTakingBelt]',
	'A517RollerConveyor':'[A517RollerConveyor]',
	'A518LiftingGantry':'[A518LiftingGantry]',
	'A519LinearDrive':'[A519LinearDrive]',
	'A520SwivelJoint':'[A520SwivelJoint]',
	'A521LiftingGantry':'[A521LiftingGantry]',
	'A522FullPallets':'[A522FullPallets]',
	'A523EvacConveyor':'[A523EvacConveyor]',
	'A524SafetyBarrier':'[A524SafetyBarrier]',
	'A525BasicCleaning':'[A525BasicCleaning]',
	'A526SafetyDevices':'[A526SafetyDevices]',
	'A530CylSucBar':'[A530CylSucBar]',
	'A531FilledBagGripper1':'[A531FilledBagGripper1]',
	'A532FilledBagGripper2':'[A532FilledBagGripper2]',
	'A533CylCutKnife':'[A533CylCutKnife]',
	'A534CylBotWelding':'[A534CylBotWelding]',
	'A535SpoutLift':'[A535SpoutLift]',
	'A536CylClampTopWeld':'[A536CylClampTopWeld]',
	'A537CylTopWeld':'[A537CylTopWeld]',
	'A538CylCornerWeld':'[A538CylCornerWeld]',
	'A539AdjHeightDischargebelt':'[A539AdjHeightDischargebelt]',
	'A540EmptyBagTransport':'[A540EmptyBagTransport]',
	'A541Virbator':'[A541Virbator]',
	'A542CylCoolPlatesCornerWeld':'[A542CylCoolPlatesCornerWeld]',
	'A543CylBagGuide':'[A543CylBagGuide]',
	'A544CylClampBotWeld':'[A544CylClampBotWeld]',
	'A545CylCoolBotWeld':'[A545CylCoolBotWeld]',
	'A546CylFilledBagGripper3':'[A546CylFilledBagGripper3]',
	'A547CylStatBagGripper':'[A547CylStatBagGripper]',
	'A548CylEmptyBagGripper1':'[A548CylEmptyBagGripper1]',
	'A549CylStretching':'[A549CylStretching]',
	'A550CylOpenSpout':'[A550CylOpenSpout]',
	'A551CylBagAplicatorLift':'[A551CylBagAplicatorLift]',
	'A552CylStretcherOpen':'[A552CylStretcherOpen]',
	'A553CylStretcherInwards':'[A553CylStretcherInwards]',
	'A554FoilTrackingDevice':'[A554FoilTrackingDevice]',
	'A555DischargeBelt2':'[A555DischargeBelt2]',
	'A556CylStretching':'[A556CylStretching]',
	'A557CylEmptyBagGripper2':'[A557CylEmptyBagGripper2]',
	'A558UnwindingDevice':'[A558UnwindingDevice]',
	'A559BagLenghtAdj':'[A559BagLenghtAdj]',
	'A560CylVibratorLift':'[A560CylVibratorLift]',
	'A561CylFilmGuide':'[A561CylFilmGuide]',
	'A562CylPushFlaps':'[A562CylPushFlaps]',
	'A563CylWeldReelChanger':'[A563CylWeldReelChanger]',
	'A564CylWeldReelChangerTef':'[A564CylWeldReelChangerTef]',
	'A565DriveReelChanger':'[A565DriveReelChanger]',
	'A566DriveAdjCornerWeld':'[A566DriveAdjCornerWeld]',
	'A567CylThreadingInwards':'[A567CylThreadingInwards]',
	'A568CylThreadingOutwards':'[A568CylThreadingOutwards]',
	'A569CylThreadingDownwards':'[A569CylThreadingDownwards]',
	'A570CylSucRailClose':'[A570CylSucRailClose]',
	'A571DischargeBelt2':'[A571DischargeBelt2]',
	'A572CylSpoutOpen':'[A572CylSpoutOpen]',
	'A573Drives':'[A573Drives]',
	'A574Cylinders':'[A574Cylinders]',
	'A575LowAttrition':'[A575LowAttrition]',
	'A576HighAttrition':'[A576HighAttrition]',
	'A577SetUpTimeAvBatch':'[A577SetUpTimeAvBatch]',
	'A578SetUpTimeAv':'[A578SetUpTimeAv]',
	'A579GrossTimeBatch':'[A579GrossTimeBatch]',
	'A580GrossTime':'[A580GrossTime]',
	'A581SetUpTime':'[A581SetUpTime]',
	'A582Downtime':'[A582Downtime]',
	'A583MonthBestPerf':'[A583MonthBestPerf]',
	'A584PressureChamperSeal':'[A584PressureChamperSeal]',
	'A585Aeration':'[A585Aeration]',
	'A586SpillageRejector':'[A586SpillageRejector]',
	'A587VibrationBottle':'[A587VibrationBottle]',
	'A588VibrationTable':'[A588VibrationTable]',
	'A589BagPlacing':'[A589BagPlacing]',
	'A590RotaryFeeder':'[A590RotaryFeeder]',
	'A591TopSeamCleaning':'[A591TopSeamCleaning]',
	'A592Beltplant1':'[A592Beltplant1]',
	'A593Beltplant2':'[A593Beltplant2]',
	'A594HydraulikPump':'[A594HydraulikPump]',
	'A595DriveEBTS':'[A595DriveEBTS]',
	'A596HostingEBTS':'[A596HostingEBTS]',
	'A597Ventilator':'[A597Ventilator]',
	'A598RD':'[A598RD]',
	'A599SpoutCoarse':'[A599SpoutCoarse]',
	'A600RotaryGateCoarse':'[A600RotaryGateCoarse]',
	'A601HostingVIC':'[A601HostingVIC]',
	'A602SpoutFine':'[A602SpoutFine]',
	'A603RotaryGateFine':'[A603RotaryGateFine]',
	'A604HostingVSC1':'[A604HostingVSC1]',
	'A605HostingVSC2':'[A605HostingVSC2]',
	'A606FBTransfer':'[A606FBTransfer]',
	'A607FBTHost':'[A607FBTHost]',
	'A608FBTswivel':'[A608FBTswivel]',
	'A609FBTClamp':'[A609FBTClamp]',
	'A610FBTransport':'[A610FBTransport]',
	'A611FBTransportClamp':'[A611FBTransportClamp]',
	'A612HostBFS':'[A612HostBFS]',
	'A613SwivelBFS':'[A613SwivelBFS]',
	'A614SideGussetblade':'[A614SideGussetblade]',
	'A615HostTopwelding':'[A615HostTopwelding]',
	'A616CloseTopWelding':'[A616CloseTopWelding]',
	'A617Folding1':'[A617Folding1]',
	'A618Folding2':'[A618Folding2]',
	'A619Folding3 ':'[A619Folding3 ]',
	'A620PUD':'[A620PUD]',
	'A621PUDWeld':'[A621PUDWeld]',
	'A622BeltReject':'[A622BeltReject]',
	'A623RejectingDevice':'[A623RejectingDevice]',
	'A624BagLenght':'[A624BagLenght]',
	'A624PerfToday':'[A624PerfToday]',
	'A625PerfTodayBatch':'[A625PerfTodayBatch]',
	'A626Output':'[A626Output]',
	'A627OutputBatch':'[A627OutputBatch]',
	'A628UnitperH':'[A628UnitperH]',
	'A629Percent':'[A629Percent]',
	'A630BagJunction':'[A630BagJunction]',
	'A631ToothedBelt':'[A631ToothedBelt]',
	'A632LevellingFoot':'[A632LevellingFoot]',
	'A633PolymerGuide':'[A633PolymerGuide]',
	'A634BallBushing':'[A634BallBushing]',
	'A635ScissorHoseReplace':'[A635ScissorHoseReplace]',
	'A636ConnectingHoseReplace':'[A636ConnectingHoseReplace]',
	'A637Bellow':'[A637Bellow]',
	'A638TipperFrame':'[A638TipperFrame]',
	'A639HosePressureChamber':'[A639HosePressureChamber]',
	'A640FlapFillingBox':'[A640FlapFillingBox]',
	'A641RotoLock':'[A641RotoLock]',
	'A642Hours':'[A642Hours]',
	'A643Temp':'[A643Temp]',
	'A644Millisecond':'[A644Millisecond]',
	'A645KgPerSecond':'[A645KgPerSecond]',
	'A646RoundsperMinute':'[A646RoundsperMinute]',
	'A647Meter':'[A647Meter]',
	'A648Millimeter':'[A648Millimeter]',
	'A649Cubicmeter':'[A649Cubicmeter]',
	'A650Liter':'[A650Liter]',
	'A651Counts':'[A651Counts]',
	'A652WeldingTemperature':'[A652WeldingTemperature]',
	'A653WeldingDuration':'[A653WeldingDuration]',
	'A654FillingSystem':'[A654FillingSystem]',
	'A655EmailSignature':'[A655EmailSignature]',
	'A656NumberWasteBags':'[A656NumberWasteBags]',
	'A657FreqConverter':'[A657FreqConverter]',
	'A658ServoDrives':'[A658ServoDrives]',
	'A656Voltage':'[A656Voltage]',
	'A657Volt':'[A657Volt]',
	'A658U1':'[A658U1]',
	'A659U2':'[A659U2]',
	'A660U3':'[A660U3]',
	'A661U12':'[A661U12]',
	'A662U23':'[A662U23]',
	'A663U31':'[A663U31]',
	'A664Current':'[A664Current]',
	'A665Amps':'[A665Amps]',
	'A666I1':'[A666I1]',
	'A667I2':'[A667I2]',
	'A668I3':'[A668I3]',
	'A669Frequency':'[A669Frequency]',
	'A670Hertz':'[A670Hertz]',
	'A671Pressure':'[A671Pressure]',
	'A672Bar':'[A672Bar]',
	'A673Moisture':'[A673Moisture]',
	'A674AirFlowUnit':'[A674AirFlowUnit]',
	'A675AnnualConsum':'[A675AnnualConsum]',
	'A676MonthlyConsum':'[A676MonthlyConsum]',
	'A677FreeConsum':'[A677FreeConsum]',
	'A678PowerEfficiency':'[A678PowerEfficiency]',
	'A679BagsPerKWH':'[A679BagsPerKWH]',
	'A681SinceReset':'[A681SinceReset]',
	'A682LastTimeSortUse':'[A682LastTimeSortUse]',
	'A683SortNumber':'[A683SortNumber]',
	'A684Overall':'[A684Overall]',
	'A685AirEfficiency':'[A685AirEfficiency]',
	'A686BagsPerAirFlowUnit':'[A686BagsPerAirFlowUnit]',
	'A687Jan':'[A687Jan]',
	'A688Feb':'[A688Feb]',
	'A689Mar':'[A689Mar]',
	'A690Apr':'[A690Apr]',
	'A691Mai':'[A691Mai]',
	'A692Jun':'[A692Jun]',
	'A693Jul':'[A693Jul]',
	'A694Aug':'[A694Aug]',
	'A695Sep':'[A695Sep]',
	'A696Oct':'[A696Oct]',
	'A697Nov':'[A697Nov]',
	'A698Dec':'[A698Dec]',
	'A699Year':'[A699Year]',
	'A700EnterDateRange':'[A700EnterDateRange]',
	'A701PowerConsumption':'[A701PowerConsumption]',
	'A702AirConsumption':'[A702AirConsumption]',
	'A703FlowControlGate':'[A703FlowControlGate]',
	'A704BagDirectionConv':'[A704BagDirectionConv]',
	'A705BottomBearing':'[A705BottomBearing]',
	'A706PressureSwitch':'[A706PressureSwitch]',
	'A707ElecModules':'[A707ElecModules]',
	'A708SpoutWeight':'[A708SpoutWeight]',
	'A709Search':'[A709Search]',
	'A710Overweight':'[A710Overweight]',
	'A711Underweight':'[A711Underweight]',
	'A712RotaryDriveFreqConLoad':'[A712RotaryDriveFreqConLoad]',
	'A713MeanValueLoadInPercent':'[A713MeanValueLoadInPercent]',
	'A714EmptyBagDischarge':'[A714EmptyBagDischarge]',
	'A715Bellow1':'[A715Bellow1]',
	'A716TotalCounter':'[A716TotalCounter]',
	'A717Spout0Counter':'[A717Spout0Counter]',
	'A718LSdirtyCounter':'[A718LSdirtyCounter]',
	'A719Peripherie':'[A719Peripherie]',
	'A720Robot':'[A720Robot]',
	'A721DepositBelt':'[A721DepositBelt]',
	'A722RollerConveyor1':'[A722RollerConveyor1]',
	'A723PalletCentering1':'[A723PalletCentering1]',
	'A724RollerConveyor2':'[A724RollerConveyor2]',
	'A725PalletCentering2':'[A725PalletCentering2]',
	'A726GripperClamp':'[A726GripperClamp]',
	'A727GripperMotor':'[A727GripperMotor]',
	'A728AdjustmentCamera':'[A728AdjustmentCamera]',
	'A729BagSize':'[A729BagSize]',
	'A730PalletSupply':'[A730PalletSupply]',
	'A731Length':'[A731Length]',
	'A732Width':'[A732Width]',
	'A733DepalPallets':'[A733DepalPallets]',
	'A734DepalBundles':'[A734DepalBundles]',
	'A735RejectedBundles':'[A735RejectedBundles]',
	'A736BagsPerBundle':'[A736BagsPerBundle]',
	'A737CountsOfDataPoints':'[A737CountsOfDataPoints]',
	'A738EmptyKey':'[A738EmptyKey]',
	'A739TrendMeanWeight':'[A739TrendMeanWeight]',
	'A740TrendSD':'[A740TrendSD]',
	'A741PistonBlower1':'[A741PistonBlower1]',
	'A742PistonBlower2':'[A742PistonBlower2]',
	'A743RotaryFlap':'[A743RotaryFlap]',
	'A744CurrentLayer':'[A744CurrentLayer]',
	'A745CurrentBag':'[A745CurrentBag]',
	'A746Days':'[A746Days]',
	'A747LayerNo':'[A747LayerNo]',
	'A748BagNo':'[A748BagNo]',
	'A749AnnualMaint':'[A749AnnualMaint]',
	'A750GPerSecond':'[A750GPerSecond]',
	'A751SortName':'[A751SortName]',
	'A752PressureTemp':'[A752PressureTemp]',
	'A901MachineCustomerOverview':'[A901MachineCustomerOverview]',
	'A902Machines':'[A902Machines]',
	'A903Permissions':'[A903Permissions]',
	'A904CompanyName':'[A904CompanyName]',
	'A905MachineName':'[A905MachineName]',
	'A906MachineType':'[A906MachineType]',
	'A907Order':'[A907Order]',
	'A908Action':'[A908Action]',
	'A909Remove':'[A909Remove]',
	'A910ChooseCustomer':'[A910ChooseCustomer]',
	'A911ConnectMachines':'[A911ConnectMachines]',
	'A912User':'[A912User]',
	'A913FirstName':'[A913FirstName]',
	'A914Surname':'[A914Surname]',
	'A915Login':'[A915Login]',
	'A916Roles':'[A916Roles]',
	'A917Company':'[A917Company]',
	'A918CreateUser':'[A918CreateUser]',
	'A919Username':'[A919Username]',
	'A920Password':'[A920Password]',
	'A921Name':'[A921Name]',
	'A922ParentCompany':'[A922ParentCompany]',
	'A923Create':'[A923Create]',
	'A924Edit':'[A924Edit]',
	'A925SubCompany':'[A925SubCompany]',
	'A926Companies':'[A926Companies]',
	'A927EditMachine':'[A927EditMachine]',
	'A928CompaniesFunctionality':'[A928CompaniesFunctionality]',
	'A930SelectAll':'[A930SelectAll]',
	'A931DetailView':'[A931DetailView]',
	'A932EditUser':'[A932EditUser]',
	'A933Delete':'[A933Delete]',
	'A934Cancel':'[A934Cancel]',
	'A935Save':'[A935Save]',
	'A936LoginFailed':'[A936LoginFailed]',
	'A936Malayalam':'[A936Malayalam]',
	'A937DisplayName1':'[A937DisplayName1]',
	'A938DisplayName2':'[A938DisplayName2]',
	'A939DisplayName3':'[A939DisplayName3]',
	'A940ForgotPassword':'[A940ForgotPassword]',
	'A941ForgotText':'[A941ForgotText]',
	'A942Submit':'[A942Submit]',
	'A943EmailAddress':'[A943EmailAddress]',
	'A944ChangePassword':'[A944ChangePassword]',
	'A945CurrentPassword':'[A945CurrentPassword]',
	'A946NewPassword':'[A946NewPassword]',
	'A947ConfirmPassword':'[A947ConfirmPassword]',
	'A948Update':'[A948Update]',
	'A949IncorrectPassword':'[A949IncorrectPassword]',
	'A950Metrics':'[A950Metrics]',
	'A950PasswordsNotMatch':'[A950PasswordsNotMatch]',
	'A951EmailNotFound':'[A951EmailNotFound]',
	'A952InvalidEmail':'[A952InvalidEmail]',
	'A953EmailSent':'[A953EmailSent]',
	'A954ForgetPasswordMailSubject':'[A954ForgetPasswordMailSubject]',
	'A955ForgetPasswordMailTitle':'[A955ForgetPasswordMailTitle]',
	'A956ForgetPasswordMailBody':'[A956ForgetPasswordMailBody]',
	'A957DateFormat':'[A957DateFormat]',
	'A958D3DateFormat':'[A958D3DateFormat]',
	'A959DashboardMachineStateStandBy':'[A959DashboardMachineStateStandBy]',
	'A959MessageUpdateSuccess':'[A959MessageUpdateSuccess]',
	'A960MessageSaveSuccess':'[A960MessageSaveSuccess]',
	'A961Yes':'[A961Yes]',
	'A962No':'[A962No]',
	'A963ReportOverview':'[A963ReportOverview]',
	'A964Machine':'[A964Machine]',
	'A965Card':'[A965Card]',
	'A966ReportType':'[A966ReportType]',
	'A967HistogramReport':'[A967HistogramReport]',
	'A968Report':'[A968Report]',
	'A969Day':'[A969Day]',
	'A969French':'[A969French]',
	'A970Week':'[A970Week]',
	'A971Month':'[A971Month]',
	'A972HistoryChart':'[A972HistoryChart]',
	'A972HistoryChartBacth':'[A972HistoryChartBacth]',
	'A973Unit':'[A973Unit]',
	'A974Time':'[A974Time]',
	'A975ShowBagCounterStackChart':'[A975ShowBagCounterStackChart]',
	'A976TestReportStarted':'[A976TestReportStarted]',
	'A977ActiveSettingsRequired':'[A977ActiveSettingsRequired]',
	'A978FirstDayRequired':'[A978FirstDayRequired]',
	'A979CompanyDeleted':'[A979CompanyDeleted]',
	'A980CompanyDeleteError':'[A980CompanyDeleteError]',
	'A981CompanyParentShouldDifferent':'[A981CompanyParentShouldDifferent]',
	'A982CompanyCreated':'[A982CompanyCreated]',
	'A983CompanyCreateError':'[A983CompanyCreateError]',
	'A984CompanyAllFields':'[A984CompanyAllFields]',
	'A985UserCreated':'[A985UserCreated]',
	'A986UserExists':'[A986UserExists]',
	'A987UserDeleted':'[A987UserDeleted]',
	'A988IncludedInReport':'[A988IncludedInReport]',
	'A989IncludedInHistogram':'[A989IncludedInHistogram]',
	'A990DateFormat':'[A990DateFormat]',
	'A991MachineTime':'[A991MachineTime]',
	'A992LocalTime':'[A992LocalTime]',
	'A993NoMachines':'[A993NoMachines]',
	'A994Imprint':'[A994Imprint]',
	'A995PrivacyPolicy':'[A995PrivacyPolicy]',
	'A996Chinese':'[A996Chinese]',
	'A997Portuguese':'[A997Portuguese]',
	'A998Vietnamese':'[A998Vietnamese]',
	'A999CompanyNameExists':'[A999CompanyNameExists]',
	'B100ActivatingReports':'[B100ActivatingReports]',
	'B101EnterValidEmailIds':'[B101EnterValidEmailIds]',
	'B101ValidFrom':'[B101ValidFrom]',
	'B102AtleastOneEmailIdRequired':'[B102AtleastOneEmailIdRequired]',
	'B102ValidTo':'[B102ValidTo]',
	'B103MaximumLimitReached':'[B103MaximumLimitReached]',
	'C001FetchingWait':'[C001FetchingWait]',
	'C002PreparingWait':'[C002PreparingWait]',
	'C003RenderingWait':'[C003RenderingWait]',
	'C004PixelScale':'[C004PixelScale]',
	'C005ChartError':'[C005ChartError]',
	'C006ChartOk':'[C006ChartOk]',
	'D001PortalType':'[D001PortalType]',
	'D002CompanyDomain':'[D002CompanyDomain]',
	'E001AlertSettingSavedSuccessfully':'[E001AlertSettingSavedSuccessfully]',
	'E002SelectAtLeastOneAlertTypeToActivateAlerting':'[E002SelectAtLeastOneAlertTypeToActivateAlerting]',
	'E003ErrorNumberMustBePositive':'[E003ErrorNumberMustBePositive]',
	'E004AlertEmailNeedsToBeActive':'[E004AlertEmailNeedsToBeActive]',
	'E005AnErrorHasOccured':'[E005AnErrorHasOccured]',
	'E005ErrorMessageAlreadyExists':'[E005ErrorMessageAlreadyExists]',
	'E005OperationSuccesful':'[E005OperationSuccesful]',
	'E006ErrorMessageSave':'[E006ErrorMessageSave]',
	'E006ErrorOccurredSettingsWasNotSaved':'[E006ErrorOccurredSettingsWasNotSaved]',
	'E007ReportSettingsSuccessfullySaved':'[E007ReportSettingsSuccessfullySaved]',
	'E008ErrorSettingsDoNotExists':'[E008ErrorSettingsDoNotExists]',
	'E009ReportSettingsSuccessfullyUpdated':'[E009ReportSettingsSuccessfullyUpdated]',
	'E010InvalidPassword':'[E010InvalidPassword]',
	'E011UserUpdated':'[E011UserUpdated]',
	'E012InvalidPassword':'[E012InvalidPassword]',
	'E013UserUpdated':'[E013UserUpdated]',
	'E015CompanyMaxUsersLowerThanActiveUsers':'[E015CompanyMaxUsersLowerThanActiveUsers]',
	'E016PasswordChangedSuccessfully':'[E016PasswordChangedSuccessfully]',
	'F001_CurrentOrder':'[F001_CurrentOrder]',
	'F002_ProductID':'[F002_ProductID]',
	'F003_BatchStart':'[F003_BatchStart]',
	'F004_Targetweight':'[F004_Targetweight]',
	'F005_RemainingFill':'[F005_RemainingFill]',
	'F006_CurrentProduction':'[F006_CurrentProduction]',
	'F007_FillStatistic':'[F007_FillStatistic]',
	'F010_Sum':'[F010_Sum]',
	'F011_Centering':'[F011_Centering]',
	'F012_Debunging':'[F012_Debunging]',
	'F013_Inert':'[F013_Inert]',
	'F014_Filling_1':'[F014_Filling_1]',
	'F015_Filling_2':'[F015_Filling_2]',
	'F016_Filling_3':'[F016_Filling_3]',
	'F017_Filling_4':'[F017_Filling_4]',
	'F018_Bunging':'[F018_Bunging]',
	'F019_Sealing':'[F019_Sealing]',
	'F020_Position':'[F020_Position]',
	'F021_TransportCycle':'[F021_TransportCycle]',
	'F022_CheckWeigher':'[F022_CheckWeigher]',
	'F023_Closing':'[F023_Closing]',
	'F024_Palletizing':'[F024_Palletizing]',
	'F025_EmptyPaletMagazin':'[F025_EmptyPaletMagazin]',
	'F026_DePalletizing':'[F026_DePalletizing]',
	'F027_Transport':'[F027_Transport]',
	'F028_PailSeperator':'[F028_PailSeperator]',
	'F029_HangUpCap':'[F029_HangUpCap]',
	'F030_PushInCap':'[F030_PushInCap]',
	'F031_TestConsoles':'[F031_TestConsoles]',
	'F050_TU2':'[F050_TU2]',
	'F051_TU1':'[F051_TU1]',
	'F052_LowerTol':'[F052_LowerTol]',
	'F053_InTol':'[F053_InTol]',
	'F054_UpperTol':'[F054_UpperTol]',
	'F055_TO1':'[F055_TO1]',
	'F056_TO2':'[F056_TO2]',
	'F060_ProductInfeed':'[F060_ProductInfeed]',
	'F061_EmptyTruss':'[F061_EmptyTruss]',
	'F062DetailedDateFormat':'[F062DetailedDateFormat]',
	'F090_Minuten':'[F090_Minuten]',
	'F091_Stunden':'[F091_Stunden]',
	'F092_Woche':'[F092_Woche]',
	'F100_Eichung':'[F100_Eichung]',
	'F101_TransKette':'[F101_TransKette]',
	'F102_Hubtor':'[F102_Hubtor]',
	'F103_ZentRollen':'[F103_ZentRollen]',
	'F104_SpundSuch':'[F104_SpundSuch]',
	'F105_Aushub':'[F105_Aushub]',
	'F106_WerkSchlitten':'[F106_WerkSchlitten]',
	'F107_Aufschrauber':'[F107_Aufschrauber]',
	'F108_VakuSys':'[F108_VakuSys]',
	'F109_HubAufSchraub':'[F109_HubAufSchraub]',
	'F110_OelAufSchraub':'[F110_OelAufSchraub]',
	'F111_Inertgas':'[F111_Inertgas]',
	'F112_Aushub_F1':'[F112_Aushub_F1]',
	'F113_HE_F1':'[F113_HE_F1]',
	'F114_TFE_F1':'[F114_TFE_F1]',
	'F115_Aushub_F2':'[F115_Aushub_F2]',
	'F116_HE_F2':'[F116_HE_F2]',
	'F117_TFE_F2':'[F117_TFE_F2]',
	'F118_ZentDorn':'[F118_ZentDorn]',
	'F119_Schiebetisch':'[F119_Schiebetisch]',
	'F120_Kappenband':'[F120_Kappenband]',
	'F121_VakuKap':'[F121_VakuKap]',
	'F122_KapSort':'[F122_KapSort]',
	'F123_VerKap':'[F123_VerKap]',
	'F124_KapZu':'[F124_KapZu]',
	'F125_Schrauber':'[F125_Schrauber]',
	'F126_VakuSchrauber':'[F126_VakuSchrauber]',
	'F127_HubSchrauber':'[F127_HubSchrauber]',
	'F128_OelZuSchraub':'[F128_OelZuSchraub]',
	'F129_Dichtung':'[F129_Dichtung]',
	'F130_HubClinch':'[F130_HubClinch]',
	'F131_Clinchen':'[F131_Clinchen]',
	'F132_OelClincher':'[F132_OelClincher]',
	'F133_VakuClincher':'[F133_VakuClincher]',
	'F140_Startphase':'[F140_Startphase]',
	'F141_Grobstromphase':'[F141_Grobstromphase]',
	'F142_Feinstromphase':'[F142_Feinstromphase]',
	'F143_DurchflussF1':'[F143_DurchflussF1]',
	'F144_DurchflussF2':'[F144_DurchflussF2]',
	'G001BatchReport':'[G001BatchReport]',
	'G002ActivateBatchReport':'[G002ActivateBatchReport]',
	'Hülsen MIN-Melder':'[Hülsen MIN-Melder]',
	'IVT-Simulation':'[IVT-Simulation]',
	'Kabel Sattelhöhenv':'[Kabel Sattelhöhenv]',
	'MACHINE_AVAILABILITY_RED_ZONE':'[MACHINE_AVAILABILITY_RED_ZONE]',
	'MACHINE_AVAILABILITY_YELLOW_ZONE':'[MACHINE_AVAILABILITY_YELLOW_ZONE]',
	'Maint Interval 0':'[Maint Interval 0]',
	'Maint Interval 1':'[Maint Interval 1]',
	'Maint Interval 10':'[Maint Interval 10]',
	'Maint Interval 11':'[Maint Interval 11]',
	'Maint Interval 12':'[Maint Interval 12]',
	'Maint Interval 13':'[Maint Interval 13]',
	'Maint Interval 14':'[Maint Interval 14]',
	'Maint Interval 2':'[Maint Interval 2]',
	'Maint Interval 3':'[Maint Interval 3]',
	'Maint Interval 4':'[Maint Interval 4]',
	'Maint Interval 5':'[Maint Interval 5]',
	'Maint Interval 6':'[Maint Interval 6]',
	'Maint Interval 7':'[Maint Interval 7]',
	'Maint Interval 8':'[Maint Interval 8]',
	'Maint Interval 9':'[Maint Interval 9]',
	'miniADAMS-Messe':'[miniADAMS-Messe]',
	'N042BrazAlertGlobalAccL0':'[N042BrazAlertGlobalAccL0]',
	'N042BrazAlertGlobalAccL1':'[N042BrazAlertGlobalAccL1]',
	'N042BrazAlertGlobalAccL2':'[N042BrazAlertGlobalAccL2]',
	'N042BrazAlertGlobalAccL3':'[N042BrazAlertGlobalAccL3]',
	'N042BrazAlertGlobalAccL4':'[N042BrazAlertGlobalAccL4]',
	'N042BrazAlertGlobalAmplL0':'[N042BrazAlertGlobalAmplL0]',
	'N042BrazAlertGlobalAmplL1':'[N042BrazAlertGlobalAmplL1]',
	'N042BrazAlertGlobalAmplL2':'[N042BrazAlertGlobalAmplL2]',
	'N042BrazAlertGlobalAmplL3':'[N042BrazAlertGlobalAmplL3]',
	'N042BrazAlertGlobalAmplL4':'[N042BrazAlertGlobalAmplL4]',
	'N042BrazAlertMaxRmsAccL0':'[N042BrazAlertMaxRmsAccL0]',
	'N042BrazAlertMaxRmsAccL1':'[N042BrazAlertMaxRmsAccL1]',
	'N042BrazAlertMaxRmsAccL2':'[N042BrazAlertMaxRmsAccL2]',
	'N042BrazAlertMaxTempBearingSensL0':'[N042BrazAlertMaxTempBearingSensL0]',
	'N042BrazAlertMaxTempBearingSensL1':'[N042BrazAlertMaxTempBearingSensL1]',
	'N042BrazAlertMaxTempBearingSensL2':'[N042BrazAlertMaxTempBearingSensL2]',
	'N042BrazAlertMaxZAxisSensorsL0':'[N042BrazAlertMaxZAxisSensorsL0]',
	'N042BrazAlertMaxZAxisSensorsL1':'[N042BrazAlertMaxZAxisSensorsL1]',
	'N042BrazAlertMaxZAxisSensorsL2':'[N042BrazAlertMaxZAxisSensorsL2]',
	'N042BrazAlertRotFreqL0':'[N042BrazAlertRotFreqL0]',
	'N042BrazAlertRotFreqL1':'[N042BrazAlertRotFreqL1]',
	'N042BrazAlertRotFreqL2':'[N042BrazAlertRotFreqL2]',
	'N042BrazAlertRotFreqL3':'[N042BrazAlertRotFreqL3]',
	'N042BrazAlertRotFreqL4':'[N042BrazAlertRotFreqL4]',
	'N042BrazAlertType':'[N042BrazAlertType]',
	'N042GlobalAcceleration':'[N042GlobalAcceleration]',
	'N042GlobalAmplitude':'[N042GlobalAmplitude]',
	'N042GlobalFrequency':'[N042GlobalFrequency]',
	'N043CardCtxMenuShowConfigScreen':'[N043CardCtxMenuShowConfigScreen]',
	'N043ConfigSaveFailure':'[N043ConfigSaveFailure]',
	'N043ConfigSaveSuccess':'[N043ConfigSaveSuccess]',
	'N044BrazilAlertMailSubject':'[N044BrazilAlertMailSubject]',
	'N045AlertWhenThresholdCrossed':'[N045AlertWhenThresholdCrossed]',
	'N046RawDataTab':'[N046RawDataTab]',
	'N047SensorL1':'[N047SensorL1]',
	'N047SensorL4':'[N047SensorL4]',
	'N047SensorR1':'[N047SensorR1]',
	'N047SensorR4':'[N047SensorR4]',
	'N048Acceleration':'[N048Acceleration]',
	'N048AccelerationLocal':'[N048AccelerationLocal]',
	'N048AccelerationX':'[N048AccelerationX]',
	'N048AccelerationY':'[N048AccelerationY]',
	'N048AccelerationZ':'[N048AccelerationZ]',
	'N048Amplitude':'[N048Amplitude]',
	'N048AmplitudeLocal':'[N048AmplitudeLocal]',
	'N048AmplitudeX':'[N048AmplitudeX]',
	'N048AmplitudeY':'[N048AmplitudeY]',
	'N048AmplitudeZ':'[N048AmplitudeZ]',
	'N048ChooseEndDate':'[N048ChooseEndDate]',
	'N048ChooseStartDate':'[N048ChooseStartDate]',
	'N048Constant':'[N048Constant]',
	'N048CourseAngle':'[N048CourseAngle]',
	'N048DeltaTemperature':'[N048DeltaTemperature]',
	'N048Frequency':'[N048Frequency]',
	'N048LastSeen':'[N048LastSeen]',
	'N048MovementAngle':'[N048MovementAngle]',
	'N048OffsetX':'[N048OffsetX]',
	'N048OffsetY':'[N048OffsetY]',
	'N048OffsetZ':'[N048OffsetZ]',
	'N048PhaseAngle':'[N048PhaseAngle]',
	'N048MotionAngle':'[N048MotionAngle]',
	'N048RMS':'[N048RMS]',
	'N048Rpm':'[N048Rpm]',
	'N048SensorDescription':'[N048SensorDescription]',
	'N048SensorId':'[N048SensorId]',
	'N048SensorTemperature':'[N048SensorTemperature]',
	'N048Side':'[N048Side]',
	'N048SiteName':'[N048SiteName]',
	'N048UpdatedTime':'[N048UpdatedTime]',
	'N049accelFDCompLeft':'[N049accelFDCompLeft]',
	'N049accelFDCompRight':'[N049accelFDCompRight]',
	'N049accelLRCompDisch':'[N049accelLRCompDisch]',
	'N049accelLRCompFeed':'[N049accelLRCompFeed]',
	'N049Date':'[N049Date]',
	'N049ErrorFetchingData':'[N049ErrorFetchingData]',
	'N049FrequencyDaily':'[N049FrequencyDaily]',
	'N049FrequencyHourly':'[N049FrequencyHourly]',
	'N049FrequencyWeekly':'[N049FrequencyWeekly]',
	'N049Length':'[N049Length]',
	'N049ModelName':'[N049ModelName]',
	'N049pitch':'[N049pitch]',
	'N049roll':'[N049roll]',
	'N049Rpm':'[N049Rpm]',
	'N049strokeDeparture':'[N049strokeDeparture]',
	'N049TuningWizard':'[N049TuningWizard]',
	'N049twistG':'[N049twistG]',
	'N049twistXG':'[N049twistXG]',
	'N049twistYG':'[N049twistYG]',
	'N049twistZG':'[N049twistZG]',
	'N049Width':'[N049Width]',
	'N049xgFDCompLeft':'[N049xgFDCompLeft]',
	'N049xgFDCompRight':'[N049xgFDCompRight]',
	'N049xgLrCompDisch':'[N049xgLrCompDisch]',
	'N049xgLrCompFeed':'[N049xgLrCompFeed]',
	'N049ygFDCompLeft':'[N049ygFDCompLeft]',
	'N049ygFDCompRight':'[N049ygFDCompRight]',
	'N049ygLrCompDisch':'[N049ygLrCompDisch]',
	'N049ygLrCompFeed':'[N049ygLrCompFeed]',
	'N049zgFDCompLeft':'[N049zgFDCompLeft]',
	'N049zgFDCompRight':'[N049zgFDCompRight]',
	'N049zgLrCompDisch':'[N049zgLrCompDisch]',
	'N049zgLrCompFeed':'[N049zgLrCompFeed]',
	'N049zgMax':'[N049zgMax]',
	'N050AnalystCommentary':'[N050AnalystCommentary]',
	'N050mAvgLong':'[N050mAvgLong]',
	'N050mAvgShort':'[N050mAvgShort]',
	'N050TrendAnalysis':'[N050TrendAnalysis]',
	'N051SensorL1':'[N051SensorL1]',
	'N051SensorL2':'[N051SensorL2]',
	'N051SensorL3':'[N051SensorL3]',
	'N051SensorL4':'[N051SensorL4]',
	'N051SensorMovement':'[N051SensorMovement]',
	'N051SensorR1':'[N051SensorR1]',
	'N051SensorR2':'[N051SensorR2]',
	'N051SensorR3':'[N051SensorR3]',
	'N051SensorR4':'[N051SensorR4]',
	'N052Anomaly':'[N052Anomaly]',
	'N052AnomalyDetectionTab':'[N052AnomalyDetectionTab]',
	'N052BrazAlertTypeSL1LocalAmplitudeL0':'[N052BrazAlertTypeSL1LocalAmplitudeL0]',
	'N052BrazAlertTypeSL1LocalAmplitudeL1':'[N052BrazAlertTypeSL1LocalAmplitudeL1]',
	'N052BrazAlertTypeSL1LocalAmplitudeL2':'[N052BrazAlertTypeSL1LocalAmplitudeL2]',
	'N052BrazAlertTypeSL1LocalAmplitudeL3':'[N052BrazAlertTypeSL1LocalAmplitudeL3]',
	'N052BrazAlertTypeSL1LocalAmplitudeL4':'[N052BrazAlertTypeSL1LocalAmplitudeL4]',
	'N052BrazAlertTypeSL1LocalAmplitudeL5':'[N052BrazAlertTypeSL1LocalAmplitudeL5]',
	'N052BrazAlertTypeSL1SensorTempL0':'[N052BrazAlertTypeSL1SensorTempL0]',
	'N052BrazAlertTypeSL1SensorTempL1':'[N052BrazAlertTypeSL1SensorTempL1]',
	'N052BrazAlertTypeSL1SensorTempL2':'[N052BrazAlertTypeSL1SensorTempL2]',
	'N052BrazAlertTypeSL1SensorTempL3':'[N052BrazAlertTypeSL1SensorTempL3]',
	'N052ObservedValue':'[N052ObservedValue]',
	'N053Angle':'[N053Angle]',
	'N053BrazAlertTypeSL1LocalAccelerationL0':'[N053BrazAlertTypeSL1LocalAccelerationL0]',
	'N053BrazAlertTypeSL1LocalAccelerationL1':'[N053BrazAlertTypeSL1LocalAccelerationL1]',
	'N053BrazAlertTypeSL1LocalAccelerationL2':'[N053BrazAlertTypeSL1LocalAccelerationL2]',
	'N053BrazAlertTypeSL1LocalAccelerationL3':'[N053BrazAlertTypeSL1LocalAccelerationL3]',
	'N053BrazAlertTypeSL1LocalAccelerationL4':'[N053BrazAlertTypeSL1LocalAccelerationL4]',
	'N053BrazAlertTypeSL1LocalAccelerationL5':'[N053BrazAlertTypeSL1LocalAccelerationL5]',
	'N053BrazAlertTypeSl1RotationFreqL0':'[N053BrazAlertTypeSl1RotationFreqL0]',
	'N053BrazAlertTypeSl1RotationFreqL1':'[N053BrazAlertTypeSl1RotationFreqL1]',
	'N053BrazAlertTypeSl1RotationFreqL2':'[N053BrazAlertTypeSl1RotationFreqL2]',
	'N053BrazAlertTypeSl1RotationFreqL3':'[N053BrazAlertTypeSl1RotationFreqL3]',
	'N053BrazAlertTypeSl1RotationFreqL4':'[N053BrazAlertTypeSl1RotationFreqL4]',
	'N053BrazAlertTypeSl1RotationFreqL5':'[N053BrazAlertTypeSl1RotationFreqL5]',
	'N053BrazAlertTypeSL1ZAxisL0':'[N053BrazAlertTypeSL1ZAxisL0]',
	'N053BrazAlertTypeSL1ZAxisL1':'[N053BrazAlertTypeSL1ZAxisL1]',
	'N053BrazAlertTypeSL1ZAxisL2':'[N053BrazAlertTypeSL1ZAxisL2]',
	'N053BrazAlertTypeSL1ZAxisL3':'[N053BrazAlertTypeSL1ZAxisL3]',
	'N053Displacement':'[N053Displacement]',
	'N053G':'[N053G]',
	'N053LocalAmplitude':'[N053LocalAmplitude]',
	'N053LocalRotationFrequency':'[N053LocalRotationFrequency]',
	'N053MM':'[N053MM]',
	'N053XAxis':'[N053XAxis]',
	'N053YAxis':'[N053YAxis]',
	'N053ZAxis':'[N053ZAxis]',
	'N053ZAxisAcceleration':'[N053ZAxisAcceleration]',
	'N054Higher':'[N054Higher]',
	'N054Lower':'[N054Lower]',
	'N054StructuralAnalysis':'[N054StructuralAnalysis]',
	'N054TWvarsForecasts':'[N054TWvarsForecasts]',
	'N054TWvarsFull':'[N054TWvarsFull]',
	'N054Value':'[N054Value]',
	'N0551On0Off':'[N0551On0Off]',
	'N055BrazTabGeneral':'[N055BrazTabGeneral]',
	'N055ChooseStartEndDate':'[N055ChooseStartEndDate]',
	'N055Close':'[N055Close]',
	'N055Custom':'[N055Custom]',
	'N055MaterialFlow':'[N055MaterialFlow]',
	'N055MAxBearingTemperature':'[N055MAxBearingTemperature]',
	'N055MaxRmsAccelBearingSensors':'[N055MaxRmsAccelBearingSensors]',
	'N055MazAccelZAxis':'[N055MazAccelZAxis]',
	'N055Off':'[N055Off]',
	'N055On':'[N055On]',
	'N055RDCenter':'[N055RDCenter]',
	'N055ShowHistory':'[N055ShowHistory]',
	'N055StartDateCannotBeAfterEndDate':'[N055StartDateCannotBeAfterEndDate]',
	'N056Commentary':'[N056Commentary]',
	'N056EvaluateEquipment':'[N056EvaluateEquipment]',
	'N056High80':'[N056High80]',
	'N056High95':'[N056High95]',
	'N056Importance':'[N056Importance]',
	'N056Low80':'[N056Low80]',
	'N056Low95':'[N056Low95]',
	'N056MaxLatAccelZgMax':'[N056MaxLatAccelZgMax]',
	'N056Pred':'[N056Pred]',
	'N056Recommendations':'[N056Recommendations]',
	'N056Type':'[N056Type]',
	'N056Report':'[N056Report]',
	'N0571Year':'[N0571Year]',
	'N057AccelGms':'[N057AccelGms]',
	'N057AccelRms':'[N057AccelRms]',
	'N057AccelSpectrum':'[N057AccelSpectrum]',
	'N057AccelTimeWForm':'[N057AccelTimeWForm]',
	'N057Axial':'[N057Axial]',
	'N057CrestFactor':'[N057CrestFactor]',
	'N057DegreeCelcius':'[N057DegreeCelcius]',
	'N057FreqHz':'[N057FreqHz]',
	'N057FullSpectrum':'[N057FullSpectrum]',
	'N057FundBearingDefectFreq':'[N057FundBearingDefectFreq]',
	'N057HighHarBearFreq':'[N057HighHarBearFreq]',
	'N057HighRes':'[N057HighRes]',
	'N057HistoricTemperature':'[N057HistoricTemperature]',
	'N057Horizontal':'[N057Horizontal]',
	'N057Latest':'[N057Latest]',
	'N057LowHarBearFreq':'[N057LowHarBearFreq]',
	'N057mms':'[N057mms]',
	'N057PeakToPeak':'[N057PeakToPeak]',
	'N057PeakToPeakMg':'[N057PeakToPeakMg]',
	'N057PowerBands':'[N057PowerBands]',
	'N057RedAlert':'[N057RedAlert]',
	'N057Rmsmg':'[N057Rmsmg]',
	'N057Rmsmms':'[N057Rmsmms]',
	'N057SensorLB1':'[N057SensorLB1]',
	'N057SensorLB2':'[N057SensorLB2]',
	'N057SensorRB1':'[N057SensorRB1]',
	'N057SensorRB2':'[N057SensorRB2]',
	'N057SensorCB1':'[N057SensorCB1]',
	'N057SensorCB2':'[N057SensorCB2]',
	'N057Spectrum':'[N057Spectrum]',
	'N057Temperature':'[N057Temperature]',
	'N057TimeMs':'[N057TimeMs]',
	'N057Trending':'[N057Trending]',
	'N057VelocityRms':'[N057VelocityRms]',
	'N057Vertical':'[N057Vertical]',
	'N057X1Rpm':'[N057X1Rpm]',
	'N057YellowAlert':'[N057YellowAlert]',
	'N058ImageUploadedFailure':'[N058ImageUploadedFailure]',
	'N058ImageUploadedSuccesfully':'[N058ImageUploadedSuccesfully]',
	'N058UploadImage':'[N058UploadImage]',
	'N059Print':'[N059Print]',
	'N060MachineHistoricalData':'[N060MachineHistoricalData]',
	'N061HistoricalBearingData':'[N061HistoricalBearingData]',
	'N062HistoricalSpectrumData':'[N062HistoricalSpectrumData]',
	'N063GlobalAcceleration':'[N063GlobalAcceleration]',
	'N064RotationFrequency':'[N064RotationFrequency]',
	'N065GlobalBreadth':'[N065GlobalBreadth]',
	'N066LocalAccelerationsEachStructureSensor':'[N066LocalAccelerationsEachStructureSensor]',
	'N067MotionAngleEachStructureSensor':'[N067MotionAngleEachStructureSensor]',
	'N067PhaseAngleEachStructureSensor':'[N067PhaseAngleEachStructureSensor]',
	'N067LocalAmplitudesEachStructureSensor':'[N067LocalAmplitudesEachStructureSensor]',
	'N068LateralAccelerationsStructureSensor':'[N068LateralAccelerationsStructureSensor]',
	'N069TemperatureEachBearingSensor':'[N069TemperatureEachBearingSensor]',
	'N070PeakToPeakEachBearingSensor':'[N070PeakToPeakEachBearingSensor]',
	'N071AccelerationRMS':'[N071AccelerationRMS]',
	'N072VelocityRMS':'[N072VelocityRMS]',
	'N073CrestFactor':'[N073CrestFactor]',
	'N074PowerBandsSensorLB1':'[N074PowerBandsSensorLB1]',
	'N075PowerBandsSensorRB1':'[N075PowerBandsSensorRB1]',
	'N076PowerBandsSensorLB2':'[N076PowerBandsSensorLB2]',
	'N077PowerBandsSensorRB2':'[N077PowerBandsSensorRB2]',
	'N077PowerBandsSensorCB1':'[N077PowerBandsSensorCB1]',
	'N077PowerBandsSensorCB2':'[N077PowerBandsSensorCB2]',
	'N078TimeWaveformDataAcceleration':'[N078TimeWaveformDataAcceleration]',
	'N079FrequencyDomainAcceleration':'[N079FrequencyDomainAcceleration]',
	'N080Select':'[N080Select]',
	'N081SelectedPeriod':'[N081SelectedPeriod]',
	'N100Capacity':'[N100Capacity]',
	'N101Product':'[N101Product]',
	'N102Undersized':'[N102Undersized]',
	'N103Oversized':'[N103Oversized]',
	'N104Speed':'[N104Speed]',
	'N105Inclination':'[N105Inclination]',
	'N106BoardHeigh':'[N106BoardHeigh]',
	'N107Moisture':'[N107Moisture]',
	'N108WaterAdd':'[N108WaterAdd]',
	'N109OpMode':'[N109OpMode]',
	'N110Auto':'[N110Auto]',
	'N110Hand':'[N110Hand]',
	'N111OpHours':'[N111OpHours]',
	'N112Temp':'[N112Temp]',
	'N113BearingTemp1':'[N113BearingTemp1]',
	'N114BearingTemp2':'[N114BearingTemp2]',
	'N115DriveTemp':'[N115DriveTemp]',
	'N116ConvTemp':'[N116ConvTemp]',
	'N117PowerInput':'[N117PowerInput]',
	'New001BagTransArea':'[New001BagTransArea]',
	'New002EmptyPalArea':'[New002EmptyPalArea]',
	'New003FullPalArea':'[New003FullPalArea]',
	'New004SheetDispArea':'[New004SheetDispArea]',
	'New005PneuFalut':'[New005PneuFalut]',
	'New006ElectricFault':'[New006ElectricFault]',
	'New007Introduction':'[New007Introduction]',
	'New008Orientation':'[New008Orientation]',
	'New009PreGrouping':'[New009PreGrouping]',
	'New010Grouping':'[New010Grouping]',
	'New011RowPusher':'[New011RowPusher]',
	'New012SlideTable':'[New012SlideTable]',
	'New013Elevator':'[New013Elevator]',
	'New014Gantry':'[New014Gantry]',
	'New015Gripper':'[New015Gripper]',
	'New016FilmDispenser':'[New016FilmDispenser]',
	'New017FullPalTrans':'[New017FullPalTrans]',
	'New018EmpPalDisp':'[New018EmpPalDisp]',
	'New019FilmDisp':'[New019FilmDisp]',
	'New020EmpCardDisp':'[New020EmpCardDisp]',
	'New021EmpPalTrans':'[New021EmpPalTrans]',
	'New022EjectingDev':'[New022EjectingDev]',
	'New023MetDetector':'[New023MetDetector]',
	'New024MarkingDev':'[New024MarkingDev]',
	'New025LabellingDev':'[New025LabellingDev]',
	'New026PalletStracker':'[New026PalletStracker]',
	'New027PalletPress':'[New027PalletPress]',
	'New028Wrapper':'[New028Wrapper]',
	'New029Fault':'[New029Fault]',
	'New030StandBy':'[New030StandBy]',
	'New031BagProduction':'[New031BagProduction]',
	'New032WeightDetection':'[New032WeightDetection]',
	'New033LeakingDetection':'[New033LeakingDetection]',
	'New034PrintingFault':'[New034PrintingFault]',
	'New035BagProduced':'[New035BagProduced]',
	'New036SamplingEjection':'[New036SamplingEjection]',
	'New037PalletProduction':'[New037PalletProduction]',
	'New038PalletInfeed':'[New038PalletInfeed]',
	'New039PalletOutfeed':'[New039PalletOutfeed]',
	'New040PalletIncomplete':'[New040PalletIncomplete]',
	'New041Checkweigher':'[New041Checkweigher]',
	'New041PalledProduced':'[New041PalledProduced]',
	'New042ProgramChngsToday':'[New042ProgramChngsToday]',
	'New042ProgramChngs':'[New042ProgramChngs]',
	'New043EndofBatch':'[New043EndofBatch]',
	'New044ProductonTime':'[New044ProductonTime]',
	'RVT8-Messe':'[RVT8-Messe]',
	'RVT8-Simulation':'[RVT8-Simulation]',
	'TERAM2-Messe':'[TERAM2-Messe]',
	'TERAM-Messe':'[TERAM-Messe]',
	'Typ84-Messe':'[Typ84-Messe]',
	'Waagenabgleich':'[Waagenabgleich]',
	'Zyklen':'[Zyklen]',
	'N082AlertMe':'[N082AlertMe]',
	'N083TimeFormat':'[N083TimeFormat]',
	'N090Synced':'[N090Synced]',
	'N091NotSynced':'[N091NotSynced]',
	'N084New':'[N084New]',
	'N085ConfigSettingSavedSuccessfully':'[N085ConfigSettingSavedSuccessfully]',
	'N86ErrorOccurredSettingsWasNotSaved':'[N86ErrorOccurredSettingsWasNotSaved]',
	'A496OEEWeek':'[A496OEEWeek]',
	'A497OEEMonthly':'[A497OEEMonthly]',
	'N87ServiceRequestUploadFiles':'[N87ServiceRequestUploadFiles]',
	'N88ServiceRequestFileSizeError':'[N88ServiceRequestFileSizeError]',
	'N89ServiceRequestFileTypeError':'[N89ServiceRequestFileTypeError]',
	'N90FilesUploadedSuccesfully':'[N90FilesUploadedSuccesfully]',
	'N91FilesUploadedFailure':'[N91FilesUploadedFailure]',
	'N932IncludeExcelReport':'[N932IncludeExcelReport]',
	'N94ServiceRequestFileLimitError':'[N94ServiceRequestFileLimitError]',
	'N055MaxHAxisRmsAccelBearingSensors':'[N055MaxHAxisRmsAccelBearingSensors]',
	'N055MaxAxialRmsAccelBearingSensors':'[N055MaxAxialRmsAccelBearingSensors]',
	'N053MG':'[N053MG]',
	'N056DummyMachine':'[N056DummyMachine]',
	'N057NiagaraAlertYellow':'[N057NiagaraAlertYellow]',
	'N058NiagaraAlertOrange':'[N058NiagaraAlertOrange]',
	'N059NiagaraAlertRed':'[N059NiagaraAlertRed]',
	'N060AddDummyMachine':'[N060AddDummyMachine]',
	'N96ConfigureValue':'[N96ConfigureValue]',
	'N97NoDataAvailable':'[N97NoDataAvailable]',
	'N98TimeStamp':'[N98TimeStamp]',
	'N99Documentation':'[N99Documentation]',
	'N100ErrorFileHeader':'[N100ErrorFileHeader]',
	'N101DocumentationHeader':'[N101DocumentationHeader]',
	'N102DownloadMachineTypeErrorMessageFile':'[N102DownloadMachineTypeErrorMessageFile]',
	'N102DownloadMachineErrorMessageFile':'[N102DownloadMachineErrorMessageFile]',
	'N103ErrorInRetrievingErrorMessages':'[N103ErrorInRetrievingErrorMessages]',
	'E011MaintIntervalSubmitSuccess':'[E011MaintIntervalSubmitSuccess]',
	'A368OpenPartsShop':'[A368OpenPartsShop]',
	'N104ExtSparePartCatalog':'[N104ExtSparePartCatalog]',
	'N105OpenExtSparePartCatalog':'[N105OpenExtSparePartCatalog]',
	'N106LperH':'[N106LperH]',
	'N107Degree':'[N107Degree]',
	'N107DegreeText':'[N107DegreeText]',
	'N108UseRelativeValues':'[N108UseRelativeValues]',
	'N109PartsShopUsername':'[N109PartsShopUsername]',
	'N110Tons':'[N110Tons]',
	'AX_VibratingBodyCondition':'[AX_VibratingBodyCondition]',
	'AX_BearingCondition':'[AX_BearingCondition]',
	'AX_AlarmsCount':'[AX_AlarmsCount]',
	'AX_Last24Hours':'[AX_Last24Hours]',
	'D001TransBearingCondition':'[D001TransBearingCondition]',
	'D002TransBearingCondition':'[D002TransBearingCondition]',
	'D003TransBearingCondition':'[D003TransBearingCondition]',
	'D004TransBearingCondition':'[D004TransBearingCondition]',
	'D005TransBearingCondition':'[D005TransBearingCondition]',
	'D006TransBearingCondition':'[D006TransBearingCondition]',
	'D007TransBearingCondition':'[D007TransBearingCondition]',
	'D008TransBearingCondition':'[D008TransBearingCondition]',
	'D009TransBearingCondition':'[D009TransBearingCondition]',
	'D010TransBearingCondition':'[D010TransBearingCondition]',
	'D011TransBearingCondition':'[D011TransBearingCondition]',
	'D012TransBearingCondition':'[D012TransBearingCondition]',
	'D013TransBearingCondition':'[D013TransBearingCondition]',
	'D014TransBearingCondition':'[D014TransBearingCondition]',
	'D015TransBearingCondition':'[D015TransBearingCondition]',
	'D016TransBearingCondition':'[D016TransBearingCondition]',
	'D001TransVBCondition':'[D001TransVBCondition]',
	'D002TransVBCondition':'[D002TransVBCondition]',
	'D003TransVBCondition':'[D003TransVBCondition]',
	'D004TransVBCondition':'[D004TransVBCondition]',
	'D005TransVBCondition':'[D005TransVBCondition]',
	'D006TransVBCondition':'[D006TransVBCondition]',
	'D007TransVBCondition':'[D007TransVBCondition]',
	'D008TransVBCondition':'[D008TransVBCondition]',
	'D009TransVBCondition':'[D009TransVBCondition]',
	'D010TransVBCondition':'[D010TransVBCondition]',
	'D011TransVBCondition':'[D011TransVBCondition]',
	'D012TransVBCondition':'[D012TransVBCondition]',
	'D013TransVBCondition':'[D013TransVBCondition]',
	'D014TransVBCondition':'[D014TransVBCondition]',
	'D015TransVBCondition':'[D015TransVBCondition]',
	'D016TransVBCondition':'[D016TransVBCondition]',
	'F01CardExpProduction':'[F01CardExpProduction]',
	'F01CardExpAvailability':'[F01CardExpAvailability]',
	'F01CardExpMaintenance':'[F01CardExpMaintenance]',
	'F01CardExpProductionTime':'[F01CardExpProductionTime]',
	'F01CardExpProductionTimeBaumit':'[F01CardExpProductionTimeBaumit]',
	'F01CardExpProductionToday':'[F01CardExpProductionToday]',
	'F01CardExpRemProduction':'[F01CardExpRemProduction]',
	'F01CardExpLastReset':'[F01CardExpLastReset]',
	'F01CardExpFaults_C':'[F01CardExpFaults_C]',
	'F01CardExpFaults_M':'[F01CardExpFaults_M]',
	'F01CardExpEnergy':'[F01CardExpEnergy]',
	'F01CardExpFillingTime':'[F01CardExpFillingTime]',
	'F01CardExpBagManufacture':'[F01CardExpBagManufacture]',
	'F01CardExpWeightData':'[F01CardExpWeightData]',
	'F01CardExpStdDevData':'[F01CardExpStdDevData]',
	'F01CardExpIncorrectWeight':'[F01CardExpIncorrectWeight]',
	'F01CardExpInterrupts':'[F01CardExpInterrupts]',
	'F01CardExpTypeChange':'[F01CardExpTypeChange]',
	'F01CardExpBagCounterPerFS':'[F01CardExpBagCounterPerFS]',
	'F01CardExpNotPlacedBags':'[F01CardExpNotPlacedBags]',
	'F01CardExpEmptyBagMagazineToday':'[F01CardExpEmptyBagMagazineToday]',
	'F01CardExpRejectsToday':'[F01CardExpRejectsToday]',
	'F01CardExpCollectiveFaultsToday':'[F01CardExpCollectiveFaultsToday]',
	'F01CardExpGoodWeights':'[F01CardExpGoodWeights]',
	'F01CardExpFltCompAir':'[F01CardExpFltCompAir]',
	'F01CardExpStrokesPump':'[F01CardExpStrokesPump]',
	'F01CardExpMaxStrokeDur':'[F01CardExpMaxStrokeDur]',
	'F01CardExpAvgStrokes':'[F01CardExpAvgStrokes]',
	'F01CardExpInternalPressure':'[F01CardExpInternalPressure]',
	'F01CardExpFillings':'[F01CardExpFillings]',
	'F01CardExpStartOfOrder':'[F01CardExpStartOfOrder]',
	'F01CardExpProductID':'[F01CardExpProductID]',
	'F01CardExpTargetWeight':'[F01CardExpTargetWeight]',
	'F01CardExpFillingCount':'[F01CardExpFillingCount]',
	'F01CardExpRemainingNumberFillings':'[F01CardExpRemainingNumberFillings]',
	'F01CardExpCycleTime':'[F01CardExpCycleTime]',
	'F01CardExpFaultLength':'[F01CardExpFaultLength]',
	'F01CardExpExtFaultLength':'[F01CardExpExtFaultLength]',
	'F01CardExpWithinTolerance':'[F01CardExpWithinTolerance]',
	'F01CardExpOutsideTolerance':'[F01CardExpOutsideTolerance]',
	'F01CardExpCurrentProduction':'[F01CardExpCurrentProduction]',
	'F01CardExpFillStatistic':'[F01CardExpFillStatistic]',
	'F01CardExpRDCenter':'[F01CardExpRDCenter]',
	'F01CardExpGlobalAcceleration':'[F01CardExpGlobalAcceleration]',
	'F01CardExpGlobalFrequency':'[F01CardExpGlobalFrequency]',
	'F01CardExpGlobalAmplitude':'[F01CardExpGlobalAmplitude]',
	'F01CardExpMazAccelZAxis':'[F01CardExpMazAccelZAxis]',
	'F01CardExpMaxHAxisRmsAccelBearingSensors':'[F01CardExpMaxHAxisRmsAccelBearingSensors]',
	'F01CardExpMaxRmsAccelBearingSensors':'[F01CardExpMaxRmsAccelBearingSensors]',
	'F01CardExpMaxAxialRmsAccelBearingSensors':'[F01CardExpMaxAxialRmsAccelBearingSensors]',
	'F01CardExpMAxBearingTemperature':'[F01CardExpMAxBearingTemperature]',
	'F01CardExpSensorMovement':'[F01CardExpSensorMovement]',
	'F01CardExpAccelerationLocal':'[F01CardExpAccelerationLocal]',
	'F01CardExpAcceleration':'[F01CardExpAcceleration]',
	'F01CardExpAxisAcceleration':'[F01CardExpAxisAcceleration]',
	'F01CardExpLocalRotationFrequency':'[F01CardExpLocalRotationFrequency]',
	'F01CardExpLocalAmplitude':'[F01CardExpLocalAmplitude]',
	'F01CardExpDisplacement':'[F01CardExpDisplacement]',
	'F01CardExpPhaseAngle':'[F01CardExpPhaseAngle]',
	'F01CardExpMotionAngle':'[F01CardExpMotionAngle]',
	'F01CardExpSensorTemperature':'[F01CardExpSensorTemperature]',
	'F01CardExpBagQuality':'[F01CardExpBagQuality]',
	'F01CardExpFaults':'[F01CardExpFaults]',
	'F01CardExpBunChangeAbs':'[F01CardExpBunChangeAbs]',
	'F01CardExpBunChangeToday':'[F01CardExpBunChangeToday]',
	'F01CardExpBagsPerBundle':'[F01CardExpBagsPerBundle]',
	'F01CardExpTypeChanges':'[F01CardExpTypeChanges]',
	'F01CardExpSortChangeToday':'[F01CardExpSortChangeToday]',
	'F01CardExpCyclesToday':'[F01CardExpCyclesToday]',
	'F01CardExpMetalDetectorToday':'[F01CardExpMetalDetectorToday]',
	'F01CardExpIncorrectBags':'[F01CardExpIncorrectBags]',
	'F01CardExpOpenBags':'[F01CardExpOpenBags]',
	'F01CardExpSampleBags':'[F01CardExpSampleBags]',
	'F01CardExpRejectionsToday':'[F01CardExpRejectionsToday]',
	'F01CardExpContainer':'[F01CardExpContainer]',
	'F01CardExpEmptyRejects':'[F01CardExpEmptyRejects]',
	'F01CardExpFullRejects':'[F01CardExpFullRejects]',
	'F01CardExpProductionRate':'[F01CardExpProductionRate]',
	'F01CardExpDailyProduction':'[F01CardExpDailyProduction]',
	'F01CardExpFillingStation':'[F01CardExpFillingStation]',
	'F01CardExpPalletizer':'[F01CardExpPalletizer]',
	'F01CardExpEmptyPalArea':'[F01CardExpEmptyPalArea]',
	'F01CardExpBagTransArea':'[F01CardExpBagTransArea]',
	'F01CardExpFullPalArea':'[F01CardExpFullPalArea]',
	'F01CardExpSheetDispArea':'[F01CardExpSheetDispArea]',
	'F01CardExpProductonTime':'[F01CardExpProductonTime]',
	'F01CardExpProgramChngsToday':'[F01CardExpProgramChngsToday]',
	'F01CardExpEndofBatch':'[F01CardExpEndofBatch]',
	'F01CardExpEmptyPallets':'[F01CardExpEmptyPallets]',
	'F01CardExpBagProduction':'[F01CardExpBagProduction]',
	'F01CardExpPalletProduction':'[F01CardExpPalletProduction]',
	'F01CardExpFSDifference':'[F01CardExpFSDifference]',
	'F01CardExpProduct':'[F01CardExpProduct]',
	'F01CardExpCapacity':'[F01CardExpCapacity]',
	'F01CardExpUndersized':'[F01CardExpUndersized]',
	'F01CardExpOversized':'[F01CardExpOversized]',
	'F01CardExpSpeed':'[F01CardExpSpeed]',
	'F01CardExpInclination':'[F01CardExpInclination]',
	'F01CardExpBoardHeigh':'[F01CardExpBoardHeigh]',
	'F01CardExpMoisture':'[F01CardExpMoisture]',
	'F01CardExpWaterAdd':'[F01CardExpWaterAdd]',
	'F01CardExpOpMode':'[F01CardExpOpMode]',
	'F01CardExpOpHours':'[F01CardExpOpHours]',
	'F01CardExpTemp':'[F01CardExpTemp]',
	'F01CardExpPowerInput':'[F01CardExpPowerInput]',
	'F01CardExpFullPalletCount':'[F01CardExpFullPalletCount]',
	'F01CardExpLoadEmptyPallets':'[F01CardExpLoadEmptyPallets]',
	'F01CardExpOeeValue':'[F01CardExpOeeValue]',
	'F01CardExpOeeAvailable':'[F01CardExpOeeAvailable]',
	'F01CardExpOeePerformance':'[F01CardExpOeePerformance]',
	'F01CardExpOeeQuality':'[F01CardExpOeeQuality]',
	'F01CardExpOeeDowntime':'[F01CardExpOeeDowntime]',
	'F01CardExpOperatingFactor':'[F01CardExpOperatingFactor]',
	'F01CardExpThroughput':'[F01CardExpThroughput]',
	'F01CardExpOeeRejects':'[F01CardExpOeeRejects]',
	'F01CardExpMachineDetailPage':'[F01CardExpMachineDetailPage]',
	'F01CardExpReportSettingsPage':'[F01CardExpReportSettingsPage]',
	'F01CardExpReportOverviewPage':'[F01CardExpReportOverviewPage]',
	'F01CardExpCompanyOverviewPage':'[F01CardExpCompanyOverviewPage]',
	'F01CardExpAlertSettingsPage':'[F01CardExpAlertSettingsPage]',
	'F01CardExpMsgAdminPage':'[F01CardExpMsgAdminPage]',
	'F01CardExpUserRolesPage':'[F01CardExpUserRolesPage]',
	'F01CardExpCompaniesPage':'[F01CardExpCompaniesPage]',
	'F01CardExpDummyMachinePage':'[F01CardExpDummyMachinePage]',
	'F01CardExpPermissionsPage':'[F01CardExpPermissionsPage]',
	'F01CardExpMaintenanceAidPage':'[F01CardExpMaintenanceAidPage]',
	'F01CardExpMachinePage':'[F01CardExpMachinePage]',
	'F01CardExpLogBookTab':'[F01CardExpLogBookTab]',
	'F01CardExpServiceRequestTab':'[F01CardExpServiceRequestTab]',
	'F01CardExpMessagesTab':'[F01CardExpMessagesTab]',
	'F01CardExpDocumentSTab':'[F01CardExpDocumentSTab]',
	'F01CardExpOeeTab':'[F01CardExpOeeTab]',
	'F01CardExpMaintenanceTab':'[F01CardExpMaintenanceTab]',
	'F01CardExpMachineSpecificTab':'[F01CardExpMachineSpecificTab]',
	'F01CardExpGeneralTab':'[F01CardExpGeneralTab]',
	'F01CardExpGoodPerformance':'[F01CardExpGoodPerformance]',
	'F01CardExpWrongApplBags':'[F01CardExpWrongApplBags]',
	'F01CardExpReelChanges':'[F01CardExpReelChanges]',
	'F01CardExpTypeCurrent':'[F01CardExpTypeCurrent]',
	'F01CardExpInterruptsFeigeGeneral':'[F01CardExpInterruptsFeigeGeneral]',
	'F01CardExpInterruptsAdams':'[F01CardExpInterruptsAdams]',
	'F01CardExpInterruptsISF':'[F01CardExpInterruptsISF]',
	'F01CardExpInterruptsIVT':'[F01CardExpInterruptsIVT]',
	'F01CardExpInterruptsMiniAdams':'[F01CardExpInterruptsMiniAdams]',
	'F01CardExpInterruptsRVT':'[F01CardExpInterruptsRVT]',
	'F01CardExpInterruptsNewtecTerram':'[F01CardExpInterruptsNewtecTerram]',
	'A-AlertTip-main_acc-ORANGE':'[A-AlertTip-main_acc-ORANGE]',
	'A-AlertTip-main_acc-YELLOW':'[A-AlertTip-main_acc-YELLOW]',
	'A-AlertTip-main_acc-RED':'[A-AlertTip-main_acc-RED]',
	'A-AlertTip-u8MachAvail-AVAIL':'[A-AlertTip-u8MachAvail-AVAIL]',
	'A905MachineNameNotAllowedCharacter':'[A905MachineNameNotAllowedCharacter]',
};