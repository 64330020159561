<mat-icon
	class="snackbar-close-icon t-close-notification"
	(click)="onDismisBtnClick()"
	>close</mat-icon
>
<br />
<div class="t-notification-text" *ngFor="let message of data.messages">
	<div class="snackbar-header">
		<b>{{ message.severity }}</b>
		<br />
	</div>
	<ng-container *ngIf="message.severity === 'success'; else errorDetail">
		{{ message.detail }}
	</ng-container>
	<ng-template #errorDetail>
		{{ message.detail }}
		{{ message.detail.name }}
	</ng-template>
	<hr />
	<br />
</div>
