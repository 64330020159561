import { Component, Inject, OnInit } from '@angular/core';
import {
	MatSnackBarRef,
	MAT_SNACK_BAR_DATA
} from '@angular/material/snack-bar';

@Component({
	selector: 'mat-snackbar',
	templateUrl: './snackbar.component.html',
	styleUrls: ['./snackbar.component.scss']
})
export class SnackbarComponent implements OnInit {
	constructor(
		@Inject(MAT_SNACK_BAR_DATA) public data: any,
		private snackBarRef: MatSnackBarRef<SnackbarComponent>
	) {}

	ngOnInit() {}

	onDismisBtnClick() {
		this.snackBarRef.dismissWithAction();
	}
}
