<hb-notification></hb-notification>
<div>
	<div
		class="app-layout"
		[ngStyle]="{
			'border-top': mqttService.isOpen
				? '5px solid #0e500e   '
				: '5px solid Crimson'
		}"
	>
		<router-outlet></router-outlet>

		<div class="bottom-bar noprint">
			<div
				attr.data-gitcommitid="{{ gitCommitId }}"
				class="appVersion"
				title="{{ propertiesJsonStringify }}"
			>
				App Version : {{ appVersion }}
			</div>
			<div *ngIf="isLoggedIn" class="last-update">
				{{ 'A386LastUpdate' | translate: lang }}
				{{ lasttraffic | translateDate: 'A399DateFormat' }}
			</div>

			<div class="text-logo t-text-logo">
				<span *ngIf="theme == 'H&B'">HAVER & BOECKER</span>
				<span *ngIf="theme == 'Feige'">Feige FILLING</span>
				<span *ngIf="theme == 'Newtec'">Newtec</span>
				<span *ngIf="theme == 'Niagara'">Niagara</span>
				<span *ngIf="theme == 'Aventus'">AVENTUS</span><br />
				<span *ngIf="theme == 'Behnbates'">Behn Bates</span><br />
				<div class="policy" style="display: inline-block">
					<span class="policy-imprint" (click)="onImprintClicked()">
						{{ 'A994Imprint' | translate: lang }}
					</span>
					<span> | </span>
					<span (click)="onPrivacyPolicyClicked()">{{
						'A995PrivacyPolicy' | translate: lang
					}}</span>
				</div>
			</div>
			<!-- commented out until customer wants it (theme-change) <div class="theming">
                    <button mat-raised-button color="primary"
                    (click)="changeTheme($event)">
                    <mat-icon>invert_colors</mat-icon>
                    </button>
                </div> -->
		</div>
	</div>
</div>
