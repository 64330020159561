import { Injectable } from '@angular/core';
import { ChartDefinitionBase } from './chart-definition-base';
import { IMachine } from '../api/domain-model';
import { MachineService } from '../api/machine.service';
import { NotificationService } from '../shared/notifications/notification.service';
import { ChartConfigService } from '../company/chart-config.service';
import { AuthService } from '../shared/auth-service';
import { TranslateService } from '../shared/translate';
import { IGaugeCardDefinition, IInterruptsTableCardDefinition, ISimpleContentCardDefinition, IStackedBarCardDefinition } from '../charts/definition-model';
import { Identifiers } from '../common/domain/bindings';
import { IColumnChartOptions, StackedAreaChartType } from '../charts/domain-model';
import { ChartSpec } from '../company';
import { IStackChartOptions } from '../d3-charts/chart-domains';

@Injectable()
export class ChartDefinitionOeeService extends ChartDefinitionBase {

	private machine: IMachine;

	constructor(
		public machineService: MachineService,
		public notificationService: NotificationService,
		public chartConfigService: ChartConfigService,
		public authService: AuthService,
		public translateService: TranslateService
	) {
		super();
	}

	setMachine(machine: IMachine): void {
		this.machine = machine;
	}

	getMachine(): IMachine {
		return this.machine;
	}

	getOeeValueCardDefinition(): IGaugeCardDefinition {
		return <IGaugeCardDefinition>{
			chartOptions: this.chartConfigService.anyGaugeChartSpec(
				this.machine,
				'oeeValue',
				0,
				100,
				Identifiers.oeeTabOeeValueCard,
				null
			).options,
			unit: 'A027Percent',
			title: 'A460OeeValue',
			historyVariables: ['s16OeeValCur'],
			colorHistoryVariables: Identifiers.oeeTabOeeValueCard,
			displayVariable: '',
		};
	}

	getAvailabilityCardDefinition(): IGaugeCardDefinition {
		return <IGaugeCardDefinition>{
			chartOptions: this.chartConfigService.anyGaugeChartSpec(
				this.machine,
				'Availability',
				0,
				100,
				Identifiers.oeeTabAvailabilityCard,
				null
			).options,
			unit: 'A027Percent',
			title: 'A461OeeAvailable',
			historyVariables: ['s16OeeAvailCur'],
			colorHistoryVariables: Identifiers.oeeTabAvailabilityCard,
			displayVariable: '',
		};
	}

	getPerformanceCardDefinition(): IGaugeCardDefinition {
		return <IGaugeCardDefinition>{
			chartOptions: this.chartConfigService.anyGaugeChartSpec(
				this.machine,
				'Performance',
				0,
				100,
				Identifiers.oeeTabPerformanceCard,
				null
			).options,
			unit: 'A027Percent',
			title: 'A462OeePerformance',
			historyVariables: ['s16OeePerfCur'],
			colorHistoryVariables: Identifiers.oeeTabPerformanceCard,
			displayVariable: '',
		};
	}

	getQualityCardDefinition(): IGaugeCardDefinition {
		return <IGaugeCardDefinition>{
			chartOptions: this.chartConfigService.anyGaugeChartSpec(
				this.machine,
				'Quality',
				0,
				100,
				Identifiers.oeeTabQualityCard,
				null
			).options,
			unit: 'A027Percent',
			title: 'A469OeeQuality',
			historyVariables: ['s16OeeQualCur'],
			colorHistoryVariables: Identifiers.oeeTabQualityCard,
			displayVariable: '',
		};
	}

	getOperatingFactorCardDefinition(): ISimpleContentCardDefinition {
		const requiredVariables = ['s16OeeOperating', 's16OeeOperating'];
		return <ISimpleContentCardDefinition>{
			neededValues: [requiredVariables],
			historyVariables: requiredVariables,
			title: 'A466OperatingFactor',
			cardBatchTitle: 'A466OperatingFactor',
			unit: 'A027Percent',
			displayVariable: '',
		}
	}

	getThroughputFactorCardDefinition(): ISimpleContentCardDefinition {
		const requiredVariables = ['s16OeeThroughput', 's16OeeThroughput'];
		return <ISimpleContentCardDefinition>{
			neededValues: [requiredVariables],
			historyVariables: requiredVariables,
			title: 'A467Throughput',
			cardBatchTitle: 'A467Throughput',
			unit: 'A027Percent',
			displayVariable: '',
		}
	}

	getOeeRejectsFactorCardDefinition(): IInterruptsTableCardDefinition {
		const requiredVariables = [
			...Identifiers.oeeTabRejectsCard.timeParams,
		];
		return {
			neededValues: requiredVariables,
			historyVariables: ['u16RejectsSampleBag'],
			title: 'A468OeeRejects',
			cardBatchTitle: 'A468OeeRejects',
			unit: '',
			machineType: '',
			countParams: Identifiers.oeeTabRejectsCard.timeParams,
			timeParams: Identifiers.oeeTabRejectsCard.timeParams,
			titleParams: Identifiers.oeeTabRejectsCard.titleParams,
			displayVariable: '',
		}
	}


	getTotalDownTimeCardDefinition(): IStackedBarCardDefinition {
		let totalDownTimeChartHeadings = [
			'A463DowntimePlaned',
			'A464DowntimeMachine',
			'A465DowntimeOther'
		];
		let totalDownTimeChartNeededValues = [
			['s32OeeDownPlaned', 's32OeeDownPlaned'],
			['s32OeeDownMach', 's32OeeDownMach'],
			['s32OeeDownOther', 's32OeeDownOther']
		];
		let colors = ['#87CEEF', '#999999', '#FFFF00'];
		const initialModel = {
			daily: {s32OeeDownPlaned: 0, s32OeeDownMach: 0, s32OeeDownOther: 0},
			batch: {s32OeeDownPlaned: 0, s32OeeDownMach: 0, s32OeeDownOther: 0}
		};


		const totalDownTimeChartSpec: ChartSpec<IColumnChartOptions> = this.chartConfigService.stackedColumnChartSpec(
			this.machine,
			'DownTime',
			totalDownTimeChartHeadings,
			totalDownTimeChartNeededValues,
			colors,
			initialModel
		);

		const stackChartOptions: IStackChartOptions = {
			...totalDownTimeChartSpec.options,
			initialModel,
			neededValues: totalDownTimeChartSpec.options.neededValues,
			header: totalDownTimeChartSpec.options.header,
			machineName: this.getMachine().name,
			isBatch: false
		}
		const columnValues = [];
		totalDownTimeChartSpec.options.header.map((heading) => columnValues.push(this.translateService.instant(heading)));

		const stackedAreaChartSpec: StackedAreaChartType = <StackedAreaChartType>{
			column: {
				values: columnValues,
				countAlias: []
			},
			neededValues: totalDownTimeChartSpec.options.neededValues,
			colors: totalDownTimeChartSpec.options.colors,
			vAxis: {title: 'A124Minutes'},
			hideData: {isHidden: false, value: 0},
			transformData: {
				applyTransformFunction: false,
				transformFunction: (value: number) => value
			}
		};

		return <IStackedBarCardDefinition>{
			title: 'A470OeeDowntime',
			unit: 'A124Minutes',
			historyVariables: ['s32OeeDownPlaned'],
			displayVariable: '',
			stackedAreaChartSpec: stackedAreaChartSpec,
			chartOptions: stackChartOptions
		}
	}
}
