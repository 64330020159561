import { Injectable } from '@angular/core';
import { ChartDefinitionBase } from './chart-definition-base';
import { IMachine } from '../api/domain-model';
import { MachineService } from '../api/machine.service';
import { NotificationService } from '../shared/notifications/notification.service';
import { ChartConfigService } from '../company/chart-config.service';
import { AuthService } from '../shared/auth-service';
import { TranslateService } from '../shared/translate';
import { IGaugeCardDefinition, IInterruptsTableCardDefinition, ISimpleContentCardDefinition, ISpoutTableCardDefinition, IWeightDataTableCardDefinition, } from '../charts/definition-model';
import CardDisplayVariables from '../common/domain/card-display-variables';
import { Identifiers, Util } from '../common/domain/bindings';

@Injectable()
export class ChartDefinitionIsf1Service extends ChartDefinitionBase {

	private machine: IMachine;

	constructor(
		public machineService: MachineService,
		public notificationService: NotificationService,
		public chartConfigService: ChartConfigService,
		public authService: AuthService,
		public translateService: TranslateService
	) {
		super();
	}

	setMachine(machine: IMachine): void {
		this.machine = machine;
	}

	getMachine(): IMachine {
		return this.machine;
	}

	getNotPlacedBagsCardDefinition(): ISimpleContentCardDefinition {
		const requiredVariables = ['u32SpoutPlacedCnt1', 'u32SpoutPlacedCntBatch1'];
		return <ISimpleContentCardDefinition>{
			neededValues: [requiredVariables],
			historyVariables: requiredVariables,
			title: 'A234NotPlacedBags',
			cardBatchTitle: 'A234NotPlacedBags',
			unit: 'A020Count',
			displayVariable: CardDisplayVariables.isf1CardDisplayVariables().notPlacedBags,
		}
	}

	getSortChangesCardDefinition(): ISimpleContentCardDefinition {
		const requiredVariables = ['u16SortChgDay', 'u16SortChgBatch'];
		return <ISimpleContentCardDefinition>{
			neededValues: [requiredVariables],
			historyVariables: requiredVariables,
			title: 'A249SortChangeToday',
			cardBatchTitle: 'A249SortChangeTodayBatch',
			unit: 'A020Count',
			displayVariable: CardDisplayVariables.isf1CardDisplayVariables().sortChangesToday,
		}
	}

	getProductionCardDefinition(): ISimpleContentCardDefinition {
		const requiredVariables = ['u32TotalProductionDay', 'u32TotalProductionBatch'];
		return <ISimpleContentCardDefinition>{
			neededValues: [requiredVariables],
			historyVariables: requiredVariables,
			title: 'A010Production',
			cardBatchTitle: 'A010Production',
			unit: 'A020Count',
			displayVariable: CardDisplayVariables.isf1CardDisplayVariables().production,
		}
	}

	getCyclesTodayCardDefinition(): ISimpleContentCardDefinition {
		const requiredVariables = ['u32TotalCyclesDay', 'u32TotalCyclesBatch'];
		return <ISimpleContentCardDefinition>{
			neededValues: [requiredVariables],
			historyVariables: requiredVariables,
			title: 'A215CyclesToday',
			cardBatchTitle: 'A215CyclesTodayBatch',
			unit: 'A020Count',
			displayVariable: CardDisplayVariables.isf1CardDisplayVariables().totalCyclesToday,
		}
	}

	getMetalDetectorTodayCardDefinition(): ISimpleContentCardDefinition {
		const requiredVariables = ['u16MetalDetectorCnt', 'u16MetalDetectorCntBatch'];
		return <ISimpleContentCardDefinition>{
			neededValues: [requiredVariables],
			historyVariables: requiredVariables,
			title: 'A217MetalDetectorToday',
			cardBatchTitle: 'A217MetalDetectorTodayBatch',
			unit: 'A020Count',
			displayVariable: CardDisplayVariables.isf1CardDisplayVariables().metalDetectorToday,
		}
	}

	getRejectsTodayCardDefinition(): ISimpleContentCardDefinition {
		const requiredVariables = ['u32RejectCnt', 'u32RejectCntBatch'];
		return <ISimpleContentCardDefinition>{
			neededValues: [requiredVariables],
			historyVariables: requiredVariables,
			title: 'A216RejectsToday',
			cardBatchTitle: 'A216RejectsToday',
			unit: 'A020Count',
			displayVariable: CardDisplayVariables.isf1CardDisplayVariables().rejectsToday,
		}
	}

	getRejectedIncorrectWeightsCardDefinition(): ISimpleContentCardDefinition {
		const requiredVariables = ['u16RejectsWrongWeight', 'u16RejectsWrongWeightBatch'];
		return <ISimpleContentCardDefinition>{
			neededValues: [requiredVariables],
			historyVariables: requiredVariables,
			title: 'A383IncorrectBags',
			cardBatchTitle: 'A383IncorrectBags',
			unit: 'A020Count',
			displayVariable: CardDisplayVariables.isf1CardDisplayVariables().rejectedIncorrectWeights,
		}
	}

	getRejectedOpenBagsCardDefinition(): ISimpleContentCardDefinition {
		const requiredVariables = ['u16RejectsOpenBag', 'u16RejectsOpenBagBatch'];
		return <ISimpleContentCardDefinition>{
			neededValues: [requiredVariables],
			historyVariables: requiredVariables,
			title: 'A382OpenBags',
			cardBatchTitle: 'A382OpenBags',
			unit: 'A020Count',
			displayVariable: CardDisplayVariables.isf1CardDisplayVariables().rejectedIncorrectWeights,
		}
	}

	getRejectedSampleBagsCardDefinition(): ISimpleContentCardDefinition {
		const requiredVariables = ['u16RejectsSampleBag', 'u16RejectsSampleBagBatch'];
		return <ISimpleContentCardDefinition>{
			neededValues: [requiredVariables],
			historyVariables: requiredVariables,
			title: 'A381SampleBags',
			cardBatchTitle: 'A381SampleBags',
			unit: 'A020Count',
			displayVariable: CardDisplayVariables.isf1CardDisplayVariables().rejectedSampleBags,
		}
	}

	getProductionChartDefinition(): IGaugeCardDefinition {
		return <IGaugeCardDefinition>{
			chartOptions: this.chartConfigService.anyGaugeChartSpec(
				this.machine,
				'prodGoodPercentSpec',
				0,
				100,
				Identifiers.isf1GoodProductionCard,
				null
			).options,
			title: 'A233GoodWeights',
			unit: 'A027Percent',
			historyVariables: Identifiers.isf1GoodProductionCard[2],
			colorHistoryVariables: Identifiers.isf1GoodProductionCard,
			displayVariable: CardDisplayVariables.isf1CardDisplayVariables().goodProduction
		};
	}

	getProductionTodayCardDefinition(): IInterruptsTableCardDefinition {
		const requiredVariables = [
			...Identifiers.isfProductionTodayCard.countParams,
		];
		return {
			neededValues: requiredVariables,
			historyVariables: ['u32OpenBags'],
			title: 'A015ProductionToday',
			cardBatchTitle: 'A010Production',
			unit: 'A020Count',
			machineType: '',
			countParams: Identifiers.isfProductionTodayCard.countParams,
			timeParams: [],
			titleParams: Identifiers.isfProductionTodayCard.titleParams,
			displayVariable: CardDisplayVariables.isf1CardDisplayVariables().productionToday,
		}
	}

	getInterruptsCardDefinition(): IInterruptsTableCardDefinition {
		const requiredVariables = [
			...Identifiers.isfInterruptCard.countParams,
			...Identifiers.isfInterruptCard.timeParams
		];
		return {
			neededValues: requiredVariables,
			historyVariables: ['u16FaultTotalrCnt'],
			title: 'A284Interrupts',
			cardBatchTitle: 'A284InterruptsBatch',
			unit: 'A124Minutes',
			machineType: '',
			countParams: Identifiers.isfInterruptCard.countParams,
			timeParams: Identifiers.isfInterruptCard.timeParams,
			titleParams: Identifiers.isfInterruptCard.titleParams,
			displayVariable: CardDisplayVariables.isf1CardDisplayVariables().interruptionsToday,
			historyNeededValues: Util.getHistoryNeededValues(Identifiers.isfInterruptCard.timeParams, Identifiers.isfInterruptCard.countParams, Identifiers.isfInterruptCard.titleParams)
		}
	}

	getWeightFillingStationDataCardDefinition(): IWeightDataTableCardDefinition {
		const requiredVariables = Identifiers.fillingStationWeightCard;
		return {
			neededValues: requiredVariables,
			historyVariables: ['s32MeanNetScale'],
			netScalePrefix: 's32MeanNetScale',
			netWeightsPrefix: 's32MeanWeights',
			decimals: 's16MeanDecimals',
			title: 'A422WeightData',
			cardBatchTitle: 'A422WeightData',
			unit: 's16MeanUnit',
			multilineChartSpec: this.chartConfigService.fillingStationNetWeighterMultiLineChartConfig(
				Identifiers.fillingStationWeightCard,
				['u8NetWeighersCnt', 'u8SpoutCnt']
			),
			displayVariable: CardDisplayVariables.isf1CardDisplayVariables().weightData,
		}
	}

	getStandardDeviationDataCardDefinition(): IWeightDataTableCardDefinition {
		const requiredVariables = Identifiers.fillingStationStandardDeviationCard;
		return {
			neededValues: requiredVariables,
			historyVariables: ['s32StdDevNetScale'],
			netScalePrefix: 's32StdDevNetScale',
			netWeightsPrefix: 's32StdDev',
			decimals: 's16StdDevDecimals',
			title: 'A423StdDevData',
			cardBatchTitle: 'A423StdDevData',
			unit: 's16StdDevUnit',
			multilineChartSpec: this.chartConfigService.fillingStationNetWeighterMultiLineChartConfig(
				Identifiers.fillingStationStandardDeviationCard,
				['u8NetWeighersCnt', 'u8SpoutCnt']
			),
			displayVariable: CardDisplayVariables.isf1CardDisplayVariables().stdDevData,
		}
	}

	getSpoutCardDefinition(): ISpoutTableCardDefinition {
		const requiredVariables = Identifiers.isf1BagcounterCard;
		return {
			neededValues: requiredVariables,
			historyVariables: ['u32NetWeigherProdCnt'],
			title: 'A023BagCounterPerFS',
			cardBatchTitle: 'A023BagCounterPerFSBatch',
			unit: 'A020Count',
			labelPrefix: '',
			displayVariable: CardDisplayVariables.isf1CardDisplayVariables().bagCounterToday,
		}
	}

}
