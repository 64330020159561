import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CountryComponent } from 'app/shared/country/country.component';
import { PipesModule } from 'app/shared/pipes.module';
import { TranslateModule } from 'app/translate/translate.module';
import { FileUploadModule } from 'ng2-file-upload';
import { FileUploadComponent } from 'app/shared/file-upload/file-upload.component';
import { FileUploadMultipleComponent } from 'app/shared/file-upload-multiple/file-upload-multiple.component';
import { HbMaterialModule } from '../hb-material.module';
import { ExplanationComponent } from '../shared/explanation/explanation.component';
import { ExplanationModalComponent } from '../shared/explanation/explanation-modal/explanation-modal.component';
import { ChartDefinitionModule } from '../chart-definition/chart-definition.module';
@NgModule({
	declarations: [
		CountryComponent,
		ExplanationComponent,
		ExplanationModalComponent,
		FileUploadComponent,
		FileUploadMultipleComponent
	],
	imports: [
		CommonModule,
		TranslateModule,
		PipesModule,
		HbMaterialModule,
		FileUploadModule,
		ChartDefinitionModule
	],
	exports: [
		ChartDefinitionModule,
		CountryComponent,
		ExplanationComponent,
		ExplanationModalComponent,
		TranslateModule,
		PipesModule,
		FileUploadComponent,
		FileUploadMultipleComponent,
		FileUploadModule,
	]
})
export class GlobalsharedModule {}
