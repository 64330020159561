import { NgModule } from '@angular/core';
import { ChartDefinitionAllgemeinService } from './chart-definition.allgemein.service';
import { ChartDefinitionEgfService } from './chart-definition.egf.service';
import { ChartDefinitionEvpService } from './chart-definition.evp.service';
import { ChartDefinitionFeigeService } from './chart-definition.feige.service';
import { ChartDefinitionFeigeGeneralService } from './chart-definition-feige-general.service';
import { ChartDefinitionHaveradamsService } from './chart-definition-haveradams.service';
import { ChartDefinitionIntegraService } from './chart-definition-integra.service';
import { ChartDefinitionIsf1Service } from './chart-definition-isf1.service';
import { ChartDefinitionIvtService } from './chart-definition-ivt.service';
import { ChartDefinitionMiniadamsService } from './chart-definition-miniadams.service';
import { ChartDefinitionNeutraleService } from './chart-definition-neutrale.service';
import { ChartDefinitionNewtecPalletizerService } from './chart-definition-newtec-palletizer.service';
import { ChartDefinitionRvt8rotopackerService } from './chart-definition-rvt8rotopacker.service';
import { ChartDefinitionScarabaeusService } from './chart-definition-scarabaeus.service';
import { ChartDefinitionTerramService } from './chart-definition-terram.service';
import { ChartDefinitionOeeService } from './chart-definition.oee.service';

@NgModule({
	providers: [
		ChartDefinitionAllgemeinService,
		ChartDefinitionEgfService,
		ChartDefinitionEvpService,
		ChartDefinitionFeigeService,
		ChartDefinitionFeigeGeneralService,
		ChartDefinitionOeeService,
		ChartDefinitionHaveradamsService,
		ChartDefinitionIntegraService,
		ChartDefinitionIsf1Service,
		ChartDefinitionIvtService,
		ChartDefinitionMiniadamsService,
		ChartDefinitionNeutraleService,
		ChartDefinitionNewtecPalletizerService,
		ChartDefinitionRvt8rotopackerService,
		ChartDefinitionScarabaeusService,
		ChartDefinitionTerramService
	],
	imports: [],
	exports: []
})
export class ChartDefinitionModule {

}
