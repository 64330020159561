import { EventEmitter, Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ICardNeedValues, IMachine } from './domain-model';

@Injectable({ providedIn: 'root' })
export class EventEmitterService {
	_machineEmitter: Subject<IMachine> = new Subject<IMachine>();
	langtrigger: Subject<string> = new Subject(); // is triggered when lang. changes
	themeEmitter: Subject<string> = new Subject(); // is triggered when theme changes
	usertableEmitter: Subject<string> = new Subject(); // is triggered when usertable has to be reloaded from modal Dialog
	companytableEmitter: Subject<string> = new Subject(); // is triggered when companytable has to be reloaded from modal Dialog
	plcMessagetableEmitter: Subject<string> = new Subject(); // is triggered when PLCMessageTable has to be reloaded from modal Dialog
	CompanyTableEmitter: Subject<string> = new Subject(); // is triggered when CompanyTable has to be reloaded from modal Dialog
	structuralDetailTabChangeEventEmitter: Subject<number> = new Subject(); // is triggered when structural analysis detail tab changes
	structuralSubTabChangeEventEmitter: Subject<number> = new Subject(); // is triggered when structural analysis sub tab changes
	sensorTabEmitter: Subject<number> = new Subject(); // is triggered when sensor icon is choose to navigate to corresponding tab
	trendingTabAxisDataChangeEventEmitter: Subject<string> = new Subject(); // is triggered when axis data tab is been changed in sensor RBLB trending data - trending tab
	trendingTabWrapperChangeEventEmitter: Subject<string> = new Subject(); // is triggered when wrapper data tab (fullspectrum/high-res) is been changed in sensor RBLB trending data -trending tab
	spectrumTabAxisDataChangeEventEmitter: Subject<string> = new Subject(); // is triggered when axis data tab is been changed in sensor RBLB trending data -spectrum tab
	spectrumTabWrapperChangeEventEmitter: Subject<string> = new Subject(); // is triggered when wrapper data tab (fullspectrum/high-res) is been changed in sensor RBLB trending data -spectrum tab
	spectrumTabCustomTimeChangeEventEmitter: Subject<
		Array<number>
	> = new Subject(); // is triggered when spectrum time list is changed.
	cardNeedValuesEmitter: Subject<ICardNeedValues> = new Subject(); // is triggered when a card useses relative values instead of absolute values
	tabEmitter: Subject<string> = new Subject(); // is triggered when a tab is changed
	public theme = 'H&B';

	constructor() {}

	get machineEmitter(): Subject<IMachine> {
		return this._machineEmitter;
	}
}
