import { ChartDefinitionBase } from './chart-definition-base';
import { Injectable } from '@angular/core';
import { IMachine } from '../api/domain-model';
import { MachineService } from '../api/machine.service';
import { NotificationService } from '../shared/notifications/notification.service';
import { ChartConfigService } from '../company/chart-config.service';
import { AuthService } from '../shared/auth-service';
import { TranslateService } from '../shared/translate';
import { Identifiers } from '../common/domain/bindings';
import { IGaugeCardDefinition, IMaintenanceCardDefinition, IMultiBarCardDefinition, IProductionTimeCardDefinition, ISimpleContentCardDefinition } from '../charts/definition-model';
import CardDisplayVariables from '../common/domain/card-display-variables';
import { ReplaySubject } from 'rxjs';
import { ModuleGroup } from '../api/domain-impl';
import { ChartSpec } from '../company';
import { IColumnChartOptions, StackedAreaChartType } from '../charts/domain-model';
import { IStackChartOptions } from '../d3-charts/chart-domains';

@Injectable()
export class ChartDefinitionAllgemeinService extends ChartDefinitionBase {

	private machine: IMachine;

	constructor(
		public machineService: MachineService,
		public notificationService: NotificationService,
		public chartConfigService: ChartConfigService,
		public authService: AuthService,
		public translateService: TranslateService
	) {
		super();
	}

	setMachine(machine: IMachine): void {
		this.machine = machine;
	}

	getMachine(): IMachine {
		return this.machine;
	}

	getProductionChartDefinition(): IGaugeCardDefinition {
		return <IGaugeCardDefinition>{
			chartOptions: this.chartConfigService.anyGaugeChartSpec(
				this.machine,
				'productionGen',
				0,
				100,
				Identifiers.generalPageProductionCardVariables,
				null
			).options,
			unit: 'A038UnitsPerHour',
			title: 'A010Production',
			historyVariables: Identifiers.generalPageProductionCardVariables[2],
			colorHistoryVariables: Identifiers.generalPageProductionCardVariables,
			displayVariable: CardDisplayVariables.generalCardDisplayVariables().production
		};
	}

	getInterruptionChartDefinition(): IGaugeCardDefinition {
		return <IGaugeCardDefinition>{
			chartOptions: this.chartConfigService.anyGaugeChartSpec(
				this.machine,
				'productionGen',
				0,
				100,
				Identifiers.generalPageProductionCardVariables,
				null
			).options,
			unit: 'A038UnitsPerHour',
			title: 'A010Production',
			historyVariables: Identifiers.generalPageProductionCardVariables[2],
			colorHistoryVariables: Identifiers.generalPageProductionCardVariables,
			displayVariable: CardDisplayVariables.generalCardDisplayVariables().production
		};
	}

	getAvailabilityChartDefinition(): IGaugeCardDefinition {
		let chartOptions = this.chartConfigService.anyGaugeChartSpec(
			this.machine,
			'availGen',
			0,
			100,
			Identifiers.generalPageAvailabilityCardVariables,
			null
		).options;
		chartOptions.dataThresholdValue = 100;
		return <IGaugeCardDefinition>{
			chartOptions: chartOptions,
			title: 'A084Availability',
			unit: 'A027Percent',
			historyVariables: Identifiers.generalPageAvailabilityCardVariables[2],
			colorHistoryVariables: Identifiers.generalPageAvailabilityCardVariables,
			displayVariable: CardDisplayVariables.generalCardDisplayVariables().availability
		}

	}

	getMaintenanceCardDefinition(): IMaintenanceCardDefinition {

		const chartOptions = this.chartConfigService.anyColumnChartSpec(
			this.machine,
			'availGen',
			Identifiers.generalTabMaintenanceCard,
			100,
			0
		);
		const maintenanceCardInfo = {
			reset: null,
			resetState: 0,
			index: 1,
			isDate: false,
			chartType: 'EMPTY_CHART',
			maintenanceAid: new ReplaySubject<any>(),
			toggleConfig: null,
			resetDisabled: false,
			gearActive: false,
			unit: 'A016Units',
			name: 'A012Maintenance',
			valueAlias: 'u32MaintGenCurr',
			maintenanceCardType: 'GENERAL_CARD',
			moreCardMaxValue: 0,
			cardMinValue: 0,
			moduleGroup: new ModuleGroup('DUMMY_MODULE', 0, 0),
			explanation: '',
			currValue: null,
			isReport: false
		};

		return <IMaintenanceCardDefinition>{
			title: 'A012Maintenance',
			unit: 'A016Units',
			displayVariable: CardDisplayVariables.generalCardDisplayVariables().maintenance,
			historyVariables: Identifiers.generalTabMaintenanceCard[0],
			chartOptions: chartOptions,
			maintenanceCardInfo: maintenanceCardInfo
		}
	}

	getProductionTimeCardDefinition(): IProductionTimeCardDefinition {
		const produktionChartSpec: ChartSpec<IColumnChartOptions> = this.chartConfigService.produktionChartSpec(this.machine);

		const stackChartOptions: IStackChartOptions = {
			...produktionChartSpec.options,
			initialModel : {
				daily: {
					u16ProdTime: 0,
					u16OutletTime: 0,
					u16FeedTime: 0,
					u16ContainerTime: 0,
					u16SortChgTime: 0,
					u16Stillstand: 0
				},
				batch: {
					u16ProdTimeBatch: 0,
					u16OutletTimeBatch: 0,
					u16FeedTimeBatch: 0,
					u16ContainerTimeBatch: 0,
					u16SortChgTimeBatch: 0,
					u16StillstandBatch: 0
				}
			},
			neededValues: produktionChartSpec.options.neededValues,
			header: produktionChartSpec.options.header,
			machineName: this.getMachine().name,
			isBatch: false
		}
		const columnValues = [];
		produktionChartSpec.options.header.map((heading) => columnValues.push(this.translateService.instant(heading)));

		const stackedAreaChartSpec: StackedAreaChartType = <StackedAreaChartType>{
			column: {
				values: columnValues,
				countAlias: []
			},
			neededValues: produktionChartSpec.options.neededValues,
			colors: produktionChartSpec.options.colors,
			vAxis: {title: 'A124Minutes'},
			hideData: {isHidden: false, value: 0},
			transformData: {
				applyTransformFunction: false,
				transformFunction: (value: number) => value
			}
		};

		return <IProductionTimeCardDefinition>{
			title: 'A014ProductionTime',
			unit: 'A124Minutes',
			historyVariables: produktionChartSpec.options.neededValues,
			displayVariable: CardDisplayVariables.generalCardDisplayVariables().productionTime,
			stackedAreaChartSpec: stackedAreaChartSpec,
			chartOptions: stackChartOptions
		}
	}

	getProductionTimeCardDefinitionBaumit(): IProductionTimeCardDefinition {
		const produktionChartSpec: ChartSpec<IColumnChartOptions> = this.chartConfigService.produktionChartSpecBaumit(this.machine);

		const stackChartOptions: IStackChartOptions = {
			...produktionChartSpec.options,
			initialModel : {
				daily: {
					u16ProdTime: 0,
					u16InterruptionTime: 0,
					u16SortChgTime: 0,
					u16Stillstand: 0
				},
				batch: {
					u16ProdTimeBatch: 0,
					u16InterruptionTimeBatch: 0,
					u16SortChgTimeBatch: 0,
					u16StillstandBatch: 0
				}
			},
			neededValues: produktionChartSpec.options.neededValues,
			header: produktionChartSpec.options.header,
			machineName: this.getMachine().name,
			isBatch: false
		}
		const columnValues = [];
		produktionChartSpec.options.header.map((heading) => columnValues.push(this.translateService.instant(heading)));

		const stackedAreaChartSpec: StackedAreaChartType = <StackedAreaChartType>{
			column: {
				values: columnValues,
				countAlias: []
			},
			neededValues: produktionChartSpec.options.neededValues,
			colors: produktionChartSpec.options.colors,
			vAxis: {title: 'A124Minutes'},
			hideData: {isHidden: false, value: 0},
			transformData: {
				applyTransformFunction: false,
				transformFunction: (value: number) => value
			}
		};

		return <IProductionTimeCardDefinition>{
			title: 'A014ProductionTime',
			unit: 'A124Minutes',
			historyVariables: produktionChartSpec.options.neededValues,
			displayVariable: CardDisplayVariables.generalCardDisplayVariables().productionTimeBaumit,
			stackedAreaChartSpec: stackedAreaChartSpec,
			chartOptions: stackChartOptions
		}
	}

	getInterruptionTimeCardDefinition(): IMultiBarCardDefinition {
		const interruptionChartSpec: ChartSpec<IColumnChartOptions> = this.chartConfigService.interruptionChartSpec(this.machine);

		const stackChartOptions: IStackChartOptions = {
			...interruptionChartSpec.options,
			initialModel : {
				daily: {
					u16OutletTime: 0,
					u16FeedTime: 0,
					u16ContainerTime: 0,
					u16MachineFaultTime: 0
				},
				batch: {
					u16OutletTimeBatch: 0,
					u16FeedTimeBatch: 0,
					u16ContainerTimeBatch: 0,
					u16MachineFaultTimeBatch: 0
				}
			},
			neededValues: interruptionChartSpec.options.neededValues,
			header: interruptionChartSpec.options.header,
			machineName: this.getMachine().name,
			isBatch: false
		}
		const columnValues = [];
		interruptionChartSpec.options.header.map(heading => columnValues.push(this.translateService.instant(heading)));

		const stackedAreaChartSpec: StackedAreaChartType = <StackedAreaChartType>{
			column: {
				values: columnValues,
				countAlias: []
			},
			neededValues: interruptionChartSpec.options.neededValues,
			colors: interruptionChartSpec.options.colors,
			vAxis: {title: 'A124Minutes'},
			hideData: {isHidden: false, value: 0},
			transformData: {
				applyTransformFunction: false,
				transformFunction: (value: number) => value
			}
		};

		return <IMultiBarCardDefinition>{
			title: 'A284InterruptsGeneralPage',
			unit: 'A124Minutes',
			historyVariables: interruptionChartSpec.options.neededValues,
			displayVariable: CardDisplayVariables.generalCardDisplayVariables().productionTime,
			stackedAreaChartSpec: stackedAreaChartSpec,
			chartOptions: stackChartOptions
		}
	}

	getProductionTodayCardDefinition(): ISimpleContentCardDefinition {
		const requiredVariables = ['u32ProdDay', 'u32ProdBatch'];
		return <ISimpleContentCardDefinition>{
			neededValues: [requiredVariables],
			historyVariables: requiredVariables,
			title: 'A015ProductionTodayGeneral',
			cardBatchTitle: 'A232GoodPerformance',
			unit: 'A016Units',
			displayVariable: CardDisplayVariables.generalCardDisplayVariables().productionToday,
		}
	}

	getRemProductionCardDefinition(): ISimpleContentCardDefinition {
		const requiredVariables = ['u32ProdAll', 'u32ProdAll'];
		return <ISimpleContentCardDefinition>{
			neededValues: [requiredVariables],
			historyVariables: requiredVariables,
			title: 'A017RemProduction',
			cardBatchTitle: 'A017RemProduction',
			unit: 'A016Units',
			displayVariable: CardDisplayVariables.generalCardDisplayVariables().totalProduction,
		}
	}

	getFaultsCardDefinition(): ISimpleContentCardDefinition {
		const requiredVariables = ['u16FaultCntDay', 'u16FaultCntBatch'];
		return <ISimpleContentCardDefinition>{
			neededValues: [requiredVariables],
			historyVariables: requiredVariables,
			title: 'A019Faults',
			cardBatchTitle: 'A136Faults',
			unit: 'A020Count',
			displayVariable: CardDisplayVariables.generalCardDisplayVariables().faultsTodayCount,
		}
	}

	getFaultsTimeDayCardDefinition(): ISimpleContentCardDefinition {
		const requiredVariables = ['u16FaultTimeDay', 'u16FaultTimeBatch'];
		return <ISimpleContentCardDefinition>{
			neededValues: [requiredVariables],
			historyVariables: requiredVariables,
			title: 'A019Faults',
			cardBatchTitle: 'A136Faults',
			unit: 'A124Minutes',
			displayVariable: CardDisplayVariables.generalCardDisplayVariables().faultsTodayDuration,
		}
	}
}
