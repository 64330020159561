import { Pipe, PipeTransform } from '@angular/core';
import * as Moment from 'moment';
import { TranslateService } from '../translate/translate.service';

@Pipe({
	name: 'translateDate',
	pure: false
})
export class TranslateDatePipe implements PipeTransform {
	constructor(private _translate: TranslateService) {}

	transform(date: Date, format: string) {
		if (!date || !format) {
			return;
		}
		return Moment(date).format(this._translate.instant(format));
	}
}
