import { Injectable } from '@angular/core';
import { ChartDefinitionBase } from './chart-definition-base';
import { IMachine } from '../api/domain-model';
import { MachineService } from '../api/machine.service';
import { NotificationService } from '../shared/notifications/notification.service';
import { ChartConfigService } from '../company/chart-config.service';
import { AuthService } from '../shared/auth-service';
import { TranslateService } from '../shared/translate';
import { IGaugeCardDefinition, IInterruptsTableCardDefinition, ISimpleContentCardDefinition, ISpoutTableCardDefinition, IWeightDataTableCardDefinition } from '../charts/definition-model';
import CardDisplayVariables from '../common/domain/card-display-variables';
import { Identifiers, Util } from '../common/domain/bindings';

@Injectable()
export class ChartDefinitionNeutraleService extends ChartDefinitionBase {

	private machine: IMachine;

	constructor(
		public machineService: MachineService,
		public notificationService: NotificationService,
		public chartConfigService: ChartConfigService,
		public authService: AuthService,
		public translateService: TranslateService
	) {
		super();
	}

	setMachine(machine: IMachine): void {
		this.machine = machine;
	}

	getMachine(): IMachine {
		return this.machine;
	}

	getFillingStationCardDefinition(): ISimpleContentCardDefinition {
		const requiredVariables = ['u32FillingTime', 'u32FillingTime'];
		return <ISimpleContentCardDefinition>{
			neededValues: [requiredVariables],
			historyVariables: requiredVariables,
			title: 'A142FillingStation',
			cardBatchTitle: 'A142FillingStation',
			unit: 'A122Seconds',
			displayVariable: CardDisplayVariables.neutraleCardDisplayVariables().fillingTime,
		}
	}

	getIncorrectWeightCardDefinition(): ISimpleContentCardDefinition {
		const requiredVariables = ['u32WrongWeight', 'u32WrongWeightBatch'];
		return <ISimpleContentCardDefinition>{
			neededValues: [requiredVariables],
			historyVariables: requiredVariables,
			title: 'A238IncorrectWeight',
			cardBatchTitle: 'A238IncorrectWeight',
			unit: 'A020Count',
			displayVariable: CardDisplayVariables.neutraleCardDisplayVariables().incorrectWeight,
		}
	}

	getTypeChangeCardDefinition(): ISimpleContentCardDefinition {
		const requiredVariables = ['u16SortChgDay', 'u16SortChgBatch'];
		return <ISimpleContentCardDefinition>{
			neededValues: [requiredVariables],
			historyVariables: requiredVariables,
			title: 'A035TypeChange',
			cardBatchTitle: 'A035TypeChangeBatch',
			unit: 'A020Count',
			displayVariable: CardDisplayVariables.neutraleCardDisplayVariables().typeChangesToday,
		}
	}

	getProductionChartDefinition(): IGaugeCardDefinition {
		return <IGaugeCardDefinition>{
			chartOptions: this.chartConfigService.anyGaugeChartSpec(
				this.machine,
				'produktionGutsaeckeSpec',
				0,
				100,
				Identifiers.neutraleGoodProductionCard,
				null
			).options,
			title: 'A129BagManufacture',
			unit: 'A027Percent',
			historyVariables: Identifiers.neutraleGoodProductionCard[2],
			colorHistoryVariables: Identifiers.neutraleGoodProductionCard,
			displayVariable: CardDisplayVariables.neutraleCardDisplayVariables().goodProduction
		};
	}


	getSpoutCardDefinition(): ISpoutTableCardDefinition {
		const requiredVariables = Identifiers.spoutCard;
		return {
			neededValues: requiredVariables,
			historyVariables: ['u8SpoutCnt'],
			title: 'A023BagCounterPerFS',
			cardBatchTitle: 'A023BagCounterPerFSBatch',
			unit: 'A016Units',
			labelPrefix: 'A024FillingStation',
			displayVariable: CardDisplayVariables.neutraleCardDisplayVariables().bagCounterToday,
		}
	}

	getInterruptsCardDefinition(): IInterruptsTableCardDefinition {
		const requiredVariables = [
			...Identifiers.neutraleInterruptCard.countParams,
			...Identifiers.neutraleInterruptCard.timeParams
		];
		return {
			neededValues: requiredVariables,
			historyVariables: ['u16StopFilterCnt'],
			title: 'A284Interrupts',
			cardBatchTitle: 'A284Interrupts',
			unit: 'A124Minutes',
			machineType: '',
			countParams: Identifiers.neutraleInterruptCard.countParams,
			timeParams: Identifiers.neutraleInterruptCard.timeParams,
			titleParams: Identifiers.neutraleInterruptCard.titleParams,
			displayVariable: CardDisplayVariables.neutraleCardDisplayVariables().interruptionsToday,
			historyNeededValues: Util.getHistoryNeededValues(Identifiers.neutraleInterruptCard.timeParams, Identifiers.neutraleInterruptCard.countParams, Identifiers.neutraleInterruptCard.titleParams)
		}
	}

	getWeightFillingStationDataCardDefinition(): IWeightDataTableCardDefinition {
		const requiredVariables = Identifiers.fillingStationWeightCard;
		return {
			neededValues: requiredVariables,
			historyVariables: ['s32MeanNetScale'],
			netScalePrefix: 's32MeanNetScale',
			netWeightsPrefix: 's32MeanWeights',
			decimals: 's16MeanDecimals',
			title: 'A422WeightData',
			cardBatchTitle: 'A422WeightData',
			unit: 's16MeanUnit',
			multilineChartSpec: this.chartConfigService.fillingStationNetWeighterMultiLineChartConfig(
				Identifiers.fillingStationWeightCard,
				['u8NetWeighersCnt', 'u8SpoutCnt']
			),
			displayVariable: CardDisplayVariables.neutraleCardDisplayVariables().weightData,
		}
	}


	getStandardDeviationDataCardDefinition(): IWeightDataTableCardDefinition {
		const requiredVariables = Identifiers.fillingStationStandardDeviationCard;
		return {
			neededValues: requiredVariables,
			historyVariables: ['s32StdDevNetScale'],
			netScalePrefix: 's32StdDevNetScale',
			netWeightsPrefix: 's32StdDev',
			decimals: 's16StdDevDecimals',
			title: 'A423StdDevData',
			cardBatchTitle: 'A423StdDevData',
			unit: 's16StdDevUnit',
			multilineChartSpec: this.chartConfigService.fillingStationNetWeighterMultiLineChartConfig(
				Identifiers.fillingStationStandardDeviationCard,
				['u8NetWeighersCnt', 'u8SpoutCnt']
			),
			displayVariable: CardDisplayVariables.neutraleCardDisplayVariables().stdDevData,
		}
	}
}
