import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector } from '@angular/core';
import * as StackTrace from 'stacktrace-js';
import { environment } from '../../environments/environment';
import { HalDiscoveryService } from '../shared/hal/hal-discovery.service';

// from https://medium.com/@amcdnl/global-error-handling-with-angular2-6b992bdfb59c

@Injectable({ providedIn: 'root' })
export class LogService implements ErrorHandler {
	public appVersion: string;
	public buildVersion: Array<string> = [];
	public disco: any;

	constructor(private injector: Injector, private http: HttpClient) {}

	public handleError(error: any): void {
		// get buildversion from package json - todo: not the right place to get from
		let packageJson = require('../../../package.json');
		this.appVersion = packageJson.version;
		this.buildVersion = ['no set'];
		if ((environment as any).version != null) {
			this.buildVersion = (environment as any).version.split('-Build');
		}

		//  let message = "App version: " + this.appVersion + "\nBuild version: " + this.buildVersion + "\n" + error.stack;

		this.disco = this.injector.get(HalDiscoveryService);

		const location = this.injector.get(LocationStrategy);
		const message = error.message ? error.message : error.toString();
		const url = location instanceof PathLocationStrategy ? location.path() : '';

		// get the stack trace, lets grab the last 10 stacks only

		StackTrace.fromError(error).then((stackframes) => {
			// var report = StackTrace.report(stackframes, url, msg)

			const stackString = stackframes
				.splice(0, 20)
				.map((sf) => {
					return sf.toString();
				})
				.join('\r\n');

			// on debug we will alert also (not on prod)
			if (environment.showJSexception === true) {
				window.alert(error + '\r\n' + stackString);
			}

			// log on the server
			this.sendLogs(
				'error',
				`Message: ${message} :\r\n URL:${window.location.href} \r\nTrace:\r\n ${stackString}`
			);
		});

		// after logging throw the error really
		throw error;
	}

	sendLogs(logLevel: string, logMessage: string) {
		console.log('sending error logs');
		let payload = { logMessage: logMessage, logLevel: logLevel };
		// error and response added
		return this.disco.getResourceTree().subscribe((api) => {
			let url = api.metrics.hb.log.uriTemplate.expand({});
			let res = this.http.post(url, payload).subscribe({
				next: (r) => {
					console.log(r);
				},
				error: (e) => {
					console.log(e);
				}
			});
			return res;
		});
		//    return null;
	}
}
