import { Injectable } from '@angular/core';
import { ChartDefinitionBase } from './chart-definition-base';
import { IMachine } from '../api/domain-model';
import { MachineService } from '../api/machine.service';
import { NotificationService } from '../shared/notifications/notification.service';
import { ChartConfigService } from '../company/chart-config.service';
import { AuthService } from '../shared/auth-service';
import { TranslateService } from '../shared/translate';
import { IGaugeCardDefinition, IInterruptsTableCardDefinition, ISimpleContentCardDefinition, IWeightDataTableCardDefinition } from '../charts/definition-model';
import CardDisplayVariables from '../common/domain/card-display-variables';
import { Identifiers, Util } from '../common/domain/bindings';

@Injectable()
export class ChartDefinitionIntegraService extends ChartDefinitionBase {

	private machine: IMachine;

	constructor(
		public machineService: MachineService,
		public notificationService: NotificationService,
		public chartConfigService: ChartConfigService,
		public authService: AuthService,
		public translateService: TranslateService
	) {
		super();
	}

	setMachine(machine: IMachine): void {
		this.machine = machine;
	}

	getMachine(): IMachine {
		return this.machine;
	}

	getBunChangeAbsCardDefinition(): ISimpleContentCardDefinition {
		const requiredVariables = ['u32AbsoluteBundleChangeCnt', 'u32AbsoluteBundleChangeCnt'];
		return <ISimpleContentCardDefinition>{
			neededValues: [requiredVariables],
			historyVariables: requiredVariables,
			title: 'A146BunChangeAbs',
			cardBatchTitle: 'A146BunChangeAbs',
			unit: 'A020Count',
			displayVariable: CardDisplayVariables.integraCardDisplayVariables().numberOfBundleChangesTotal,
		}
	}

	getBunChangeTodayCardDefinition(): ISimpleContentCardDefinition {
		const requiredVariables = ['u16RelativBundleChangeCnt', 'u16RelativBundleChangeCntBatch'];
		return <ISimpleContentCardDefinition>{
			neededValues: [requiredVariables],
			historyVariables: requiredVariables,
			title: 'A147BunChangeToday',
			cardBatchTitle: 'A147BunChangeTodayBatch',
			unit: 'A020Count',
			displayVariable: CardDisplayVariables.integraCardDisplayVariables().numberOfBundleChangesToday,
		}
	}

	getBagsPerBundleCardDefinition(): ISimpleContentCardDefinition {
		const requiredVariables = ['u16BagsPerBundleCnt', 'u16BagsPerBundleCntBatch'];
		return <ISimpleContentCardDefinition>{
			neededValues: [requiredVariables],
			historyVariables: requiredVariables,
			title: 'A148BagsPerBundle',
			cardBatchTitle: 'A148BagsPerBundle',
			unit: 'A020Count',
			displayVariable: CardDisplayVariables.integraCardDisplayVariables().numberOfBagsPerBundle,
		}
	}

	getTypeChangesCardDefinition(): ISimpleContentCardDefinition {
		const requiredVariables = ['u16SortChgDay', 'u16SortChgBatch'];
		return <ISimpleContentCardDefinition>{
			neededValues: [requiredVariables],
			historyVariables: requiredVariables,
			title: 'A149TypeChanges',
			cardBatchTitle: 'A149TypeChangesBatch',
			unit: 'A020Count',
			displayVariable: CardDisplayVariables.integraCardDisplayVariables().typesChangesToday,
		}
	}

	getProductionChartDefinition(): IGaugeCardDefinition {
		return <IGaugeCardDefinition>{
			chartOptions: this.chartConfigService.anyGaugeChartSpec(
				this.machine,
				'productionBagSpec',
				0,
				100,
				Identifiers.integraProductionBagCard,
				null
			).options,
			title: 'A129BagManufacture',
			unit: 'A130BagsPerHour',
			historyVariables: Identifiers.integraProductionBagCard[2],
			colorHistoryVariables: Identifiers.integraProductionBagCard,
			displayVariable: CardDisplayVariables.integraCardDisplayVariables().goodProduction
		};
	}

	getBagQualityCardDefinition(): IInterruptsTableCardDefinition {
		const requiredVariables = [
			...Identifiers.integraBagQualityCard.countParams,
			...Identifiers.integraBagQualityCard.timeParams
		];
		return {
			neededValues: requiredVariables,
			historyVariables: ['u32CorrPlacedBagCnt', 'u32CorrPlacedBagCntBatch'],
			title: 'A131BagQuality',
			cardBatchTitle: 'A131BagQuality',
			unit: 'A027Percent',
			machineType: '',
			countParams: Identifiers.integraBagQualityCard.countParams,
			timeParams: Identifiers.integraBagQualityCard.timeParams,
			titleParams: Identifiers.integraBagQualityCard.titleParams,
			displayVariable: CardDisplayVariables.integraCardDisplayVariables().bagQuality,
			historyNeededValues: Util.getHistoryNeededValues(Identifiers.integraBagQualityCard.timeParams, Identifiers.integraBagQualityCard.countParams, Identifiers.integraBagQualityCard.titleParams)
		}
	}

	getInterruptsCardDefinition(): IInterruptsTableCardDefinition {
		const requiredVariables = [
			...Identifiers.integraInterruptCard.countParams,
		];
		return {
			neededValues: requiredVariables,
			historyVariables: ['u16FaultAirPressCnt'],
			title: 'A136Faults',
			cardBatchTitle: 'A136Faults',
			unit: 'A020Count',
			machineType: '',
			countParams: Identifiers.integraInterruptCard.countParams,
			timeParams: [],
			titleParams: Identifiers.integraInterruptCard.titleParams,
			displayVariable: CardDisplayVariables.integraCardDisplayVariables().faults,
		}
	}

	getWeightFillingStationDataCardDefinition(): IWeightDataTableCardDefinition {
		const requiredVariables = Identifiers.fillingStationWeightCard;
		return {
			neededValues: requiredVariables,
			historyVariables: ['s32MeanNetScale'],
			netScalePrefix: 's32MeanNetScale',
			netWeightsPrefix: 's32MeanWeights',
			decimals: 's16MeanDecimals',
			title: 'A422WeightData',
			cardBatchTitle: 'A422WeightData',
			unit: 's16MeanUnit',
			multilineChartSpec: this.chartConfigService.fillingStationNetWeighterMultiLineChartConfig(
				Identifiers.fillingStationWeightCard,
				['u8NetWeighersCnt', 'u8SpoutCnt']
			),
			displayVariable: CardDisplayVariables.integraCardDisplayVariables().weightData,
		}
	}

	getStandardDeviationDataCardDefinition(): IWeightDataTableCardDefinition {
		const requiredVariables = Identifiers.fillingStationStandardDeviationCard;
		return {
			neededValues: requiredVariables,
			historyVariables: ['s32StdDevNetScale'],
			netScalePrefix: 's32StdDevNetScale',
			netWeightsPrefix: 's32StdDev',
			decimals: 's16StdDevDecimals',
			title: 'A423StdDevData',
			cardBatchTitle: 'A423StdDevData',
			unit: 's16StdDevUnit',
			multilineChartSpec: this.chartConfigService.fillingStationNetWeighterMultiLineChartConfig(
				Identifiers.fillingStationStandardDeviationCard,
				['u8NetWeighersCnt', 'u8SpoutCnt']
			),
			displayVariable: CardDisplayVariables.integraCardDisplayVariables().stdDevData,
		}
	}
}
