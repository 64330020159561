import { IStackChartOptions } from './../d3-charts/chart-domains';
import { Injectable } from '@angular/core';
import { IMachine } from '../api/domain-model';
import { MachineService } from '../api/machine.service';
import { MqttService } from '../api/mqtt.service';
import {
	IColumnChartOptions,
	IGaugeChartCustomOptions,
	IGaugeChartOptions,
	MultiLineChartType,
	StackedAreaChartType
} from '../charts/domain-model';
import { IChartType, IChartTypeOptions } from '../d3-charts/chart-domains';
import { NotificationService } from '../shared/notifications/notification.service';
import { TranslateService } from '../shared/translate/translate.service';
import { ChartSpec } from './index';

export interface IArcBorder {
	redFrom: number;
	redTo: number;
	yellowFrom: number;
	yellowTo: number;
	greenFrom: number;
	greenTo: number;
}

export interface IArcBorderCustom {
	redStart: number;
	redEndYellow: number;
	yellowStartFromRed: number;
	yellowEndGreen: number;
	greenStartFromYellow: number;
	greenEndYellow: number;
	yellowStartFromGreen: number;
	yellowEndRed: number;
	redStartFromYellow: number;
	redEnd: number;
}

@Injectable()
export class ChartConfigService {
	private _produktionChartSpec: Array<ChartSpec<IColumnChartOptions>> = [];
	private _produktionChartBaumitSpec: Array<ChartSpec<IColumnChartOptions>> = [];
	private _interruptionChartSpec: Array<ChartSpec<IColumnChartOptions>> = [];
	private _stackedColumnChartSpec: Array<ChartSpec<IColumnChartOptions>> = [];
	private _leistungChartSpec: Array<ChartSpec<IGaugeChartOptions>> = [];
	private _leistungPackerDrehzahl: Array<ChartSpec<IGaugeChartOptions>> = [];

	private _produktionGutsaecke: Array<ChartSpec<IGaugeChartOptions>> = [];
	private _verfuebarkeitChartSpec: Array<ChartSpec<IGaugeChartOptions>> = [];
	private _wartungChartSpec: Array<ChartSpec<IColumnChartOptions>> = [];

	private _anyGaugeChartSpec: Array<ChartSpec<IGaugeChartOptions>> = [];
	private _anyGaugeChartCustomSpec: Array<ChartSpec<IGaugeChartCustomOptions>> =
		[];
	private _anyColumnChartSpec: Array<ChartSpec<IColumnChartOptions>> = [];
	private _anyStackbarChartSpec: Array<ChartSpec<IStackChartOptions>> = [];

	constructor(
		private notificationService: NotificationService,
		public translateService: TranslateService,
		public mqttService: MqttService,
		private machineService: MachineService
	) {
		this.mqttService = mqttService;
	}

	public anyGaugeChartSpec(
		machine: IMachine,
		name: string,
		min: number,
		max: number,
		neededValues: any,
		arcBorder: IArcBorder,
		neededValuesRelative?: any
	): ChartSpec<IGaugeChartOptions> {
		let machineId = machine ? machine.id : 0;

		min = 0;

		if (typeof this._anyGaugeChartSpec[machineId + name] === 'undefined') {
			this._anyGaugeChartSpec[machineId + name] = new ChartSpec(
				'anyGaugeChartSpec',
				<IGaugeChartOptions>{
					min: min,
					max: max,
					redFrom: (arcBorder && arcBorder.redFrom) || min,
					redTo: (arcBorder && arcBorder.redTo) || 70,
					yellowFrom: (arcBorder && arcBorder.yellowFrom) || 70,
					yellowTo: (arcBorder && arcBorder.yellowTo) || 90,
					greenFrom: (arcBorder && arcBorder.greenFrom) || 90,
					greenTo: (arcBorder && arcBorder.greenTo) || 100,
					startToRed: (arcBorder && arcBorder.redFrom) || min,
					redToYellow: (arcBorder && arcBorder.redTo) || 70,
					yellowFromRed: (arcBorder && arcBorder.yellowFrom) || 70,
					yellowToGreen: (arcBorder && arcBorder.yellowTo) || 90,
					greenFromYellow: (arcBorder && arcBorder.greenFrom) || 90,
					greenToYellow: (arcBorder && arcBorder.greenTo) || 100,
					value: min,
					size: 250 * 0.9,
					get radius() {
						return (this.size * 0.97) / 2;
					},
					get cx() {
						return this.size / 2;
					},
					get cy() {
						return this.size / 2;
					},
					get range() {
						return this.max - this.min;
					},
					majorTicks: 5,
					minorTicks: 2,
					greenColor: '#109618',
					yellowColor: '#FF9900',
					redColor: '#DC3912',
					transitionDuration: 200,
					neededValues: neededValues,
					neededValuesRelative: neededValuesRelative,
					machineId: machineId,
					isFivePointChart: false,
					is3PointReverseChart: false,
					fixFloatingPointNumbers: false
				}
			);
		}
		return this._anyGaugeChartSpec[machineId + name];
	}

	public anyGaugeChartCustomSpec(
		machine: IMachine,
		name: string,
		min: number,
		max: number,
		neededValues: any,
		arcBorder: IArcBorderCustom
	): ChartSpec<IGaugeChartCustomOptions> {
		let machineId = machine ? machine.id : 0;

		if (
			typeof this._anyGaugeChartCustomSpec[machineId + name] === 'undefined'
		) {
			this._anyGaugeChartCustomSpec[machineId + name] = new ChartSpec(
				'anyGaugeChartCustomSpec',
				<IGaugeChartCustomOptions>{
					min: min,
					max: max,

					redColor: '#DC3912',
					greenColor: '#109618',
					yellowColor: '#FF9900',

					redStart: (arcBorder && arcBorder.redStart) || min,
					redEndYellow: (arcBorder && arcBorder.redEndYellow) || 90,
					yellowStartFromRed: (arcBorder && arcBorder.yellowStartFromRed) || 90,
					yellowEndGreen: (arcBorder && arcBorder.yellowEndGreen) || 90,
					greenStartFromYellow:
						(arcBorder && arcBorder.greenStartFromYellow) || 90,
					greenEndYellow: (arcBorder && arcBorder.greenEndYellow) || 90,
					yellowStartFromGreen:
						(arcBorder && arcBorder.yellowStartFromGreen) || 90,
					yellowEndRed: (arcBorder && arcBorder.yellowEndRed) || 90,
					redStartFromYellow: (arcBorder && arcBorder.redStartFromYellow) || 90,
					redEnd: (arcBorder && arcBorder.redEnd) || max,

					value: min,
					size: 250 * 0.9,
					get radius() {
						return (this.size * 0.97) / 2;
					},
					get cx() {
						return this.size / 2;
					},
					get cy() {
						return this.size / 2;
					},
					get range() {
						return this.max - this.min;
					},
					majorTicks: 5,
					minorTicks: 2,
					transitionDuration: 200,
					neededValues: neededValues,
					machineId: machineId,
					isFivePointChart: false,
					is3PointReverseChart: false,
					fixFloatingPointNumbers: false
				}
			);
		}
		return this._anyGaugeChartCustomSpec[machineId + name];
	}

	public anyColumnChartSpec(
		machine: IMachine,
		name: string,
		neededValues: Array<Array<string>>,
		maxValue: number,
		minValue: number
	): ChartSpec<IColumnChartOptions> {
		let machineId = machine ? machine.id : 0;

		if (typeof this._anyColumnChartSpec[machineId + name] === 'undefined') {
			this._anyColumnChartSpec[machineId + name] = new ChartSpec(name, <
				IColumnChartOptions
			>{
				hAxis: {
					minValue: 0,
					maxValue: 0,
					textPosition: 'none'
				},
				vAxis: {
					viewWindowMode: 'explicit',
					maxValue: maxValue || 100,
					minValue: minValue || 0,
					viewWindow: {
						max: maxValue || 100,
						min: minValue || 0
					}
				},
				legend: { position: 'none', minLines: 4, maxLines: 4 },
				chartArea: { left: 80, top: 25, right: 0, bottom: 20 },
				valueType: 'number',
				neededValues: neededValues,
				machineId: machineId
			});
		}
		return this._anyColumnChartSpec[machineId + name];
	}

	public anyStackbarChartSpec(
		machine: IMachine,
		name: string,
		header: string[],
		neededValues: Array<Array<string>>,
		countAlias: number,
		colors: string[],
		unit
	): ChartSpec<IStackChartOptions> {
		let machineId = machine ? machine.id : 0;

		if (typeof this._anyStackbarChartSpec[machineId + name] === 'undefined') {
			this._anyStackbarChartSpec[machineId + name] = new ChartSpec(name, {
				column: {
					category: '',
					values: header,
					countAlias: [countAlias.toString()]
				},
				header,
				hAxis: {
					minValue: 0,
					maxValue: -1,
					textPosition: 'none'
				},
				isStacked: true,
				responsive: true,
				maintainAspectRatio: true,
				legend: { position: 'right', textStyle: { fontSize: 11 } },
				colors,
				chartArea: { left: 0, top: 25, right: 0, bottom: 20 },
				neededValues,
				machineId: machineId,
				vAxis: { title: unit },
				hideData: { isHidden: false, value: 0 },
				transformData: {
					applyTransformFunction: false,
					transformFunction: (value: number) => value
				}
			});
		}
		return this._anyStackbarChartSpec[machineId + name];
	}

	public produktionChartSpec(
		machine: IMachine
	): ChartSpec<IColumnChartOptions> {
		let machineId = machine ? machine.id : 0;

		if (typeof this._produktionChartSpec[machineId] === 'undefined') {
			const header = [
				'A232GoodPerformance',
				'A231NoOutlet',
				'A230NoProduct',
				'A229NoPackage',
				'A581SetUpTime',
				'A582Downtime'
			];
			const neededValues = [
				['u16ProdTime', 'u16ProdTimeBatch'],
				['u16OutletTime', 'u16OutletTimeBatch'],
				['u16FeedTime', 'u16FeedTimeBatch'],
				['u16ContainerTime', 'u16ContainerTimeBatch'],
				['u16SortChgTime', 'u16SortChgTimeBatch'],
				['u16Stillstand', 'u16StillstandBatch']
			];

			this._produktionChartSpec[machineId] = new ChartSpec('Produktion', {
				header,
				hAxis: {
					minValue: 0,
					maxValue: -1,
					textPosition: 'none'
				},
				isStacked: true,
				responsive: true,
				maintainAspectRatio: true,
				legend: { position: 'right', textStyle: { fontSize: 11 } },
				colors: [
					'#27408B',
					'#87CEEF',
					'#999999',
					'#FFFF00',
					'#15EC5A',
					'#EC3815'
				],
				chartArea: { left: 0, top: 25, right: 0, bottom: 20 },
				neededValues,
				machineId: machineId,
			});
		}
		return this._produktionChartSpec[machineId];
	}

	public produktionChartSpecBaumit(
		machine: IMachine
	): ChartSpec<IColumnChartOptions> {
		let machineId = machine ? machine.id : 0;

		if (typeof this._produktionChartBaumitSpec[machineId] === 'undefined') {
			const header = [
				'A232GoodPerformance',
				'A284Interrupts',
				'A581SetUpTime',
				'A582Downtime'
			];
			const neededValues = [
				['u16ProdTime', 'u16ProdTimeBatch'],
				['u16InterruptionTime', 'u16InterruptionTimeBatch'],
				['u16SortChgTime', 'u16SortChgTimeBatch'],
				['u16Stillstand', 'u16StillstandBatch']
			];

			this._produktionChartBaumitSpec[machineId] = new ChartSpec('ProduktionBaumit', {
				header,
				hAxis: {
					minValue: 0,
					maxValue: -1,
					textPosition: 'none'
				},
				isStacked: true,
				responsive: true,
				maintainAspectRatio: true,
				legend: { position: 'right', textStyle: { fontSize: 11 } },
				colors: [
					'#27408B',
					'#FFFF00',
					'#15EC5A',
					'#EC3815'
				],
				chartArea: { left: 0, top: 25, right: 0, bottom: 20 },
				neededValues,
				machineId: machineId,
			});
		}
		return this._produktionChartBaumitSpec[machineId];
	}

	public interruptionChartSpec(machine: IMachine): ChartSpec<IColumnChartOptions> {
		let machineId = machine ? machine.id : 0;

		if (typeof this._interruptionChartSpec[machineId] === 'undefined') {
			const header = [
				'A231NoOutlet',
				'A230NoProduct',
				'A229NoPackage',
				'A123FaultLength'
			];
			const neededValues = [
				['u16OutletTime', 'u16OutletTimeBatch'],
				['u16FeedTime', 'u16FeedTimeBatch'],
				['u16ContainerTime', 'u16ContainerTimeBatch'],
				['u16MachineFaultTime', 'u16MachineFaultTimeBatch']
			];

			this._interruptionChartSpec[machineId] = new ChartSpec('Interruptions', {
				header,
				hAxis: {
					minValue: 0,
					maxValue: -1,
					textPosition: 'none'
				},
				isStacked: false,
				responsive: true,
				maintainAspectRatio: true,
				legend: { position: 'right', textStyle: { fontSize: 11 } },
				colors: [
					'#00C6F8',
					'#878500',
					'#EBAC23',
					'#D163E6'
				],
				chartArea: { left: 0, top: 25, right: 0, bottom: 20 },
				neededValues,
				machineId: machineId,
			});
		}

		return this._interruptionChartSpec[machineId];
	}

	public stackedColumnChartSpec(
		machine: IMachine,
		chartName: string,
		headers: Array<string>,
		neededValues: Array<Array<string>>,
		colors: Array<string>,
		initialModel: { daily: any; batch: any }
	): ChartSpec<IColumnChartOptions> {
		let machineId = machine ? machine.id : 0;

		if (typeof this._stackedColumnChartSpec[chartName] === 'undefined') {
			this._stackedColumnChartSpec[chartName] = new ChartSpec(chartName, {
				header: headers,
				hAxis: {
					minValue: 0,
					maxValue: -1,
					textPosition: 'none'
				},
				isStacked: true,
				responsive: true,
				maintainAspectRatio: true,
				legend: { position: 'right', textStyle: { fontSize: 11 } },
				colors: colors,
				chartArea: { left: 0, top: 25, right: 0, bottom: 20 },
				neededValues: neededValues,
				machineId: machineId,
				initialModel: initialModel
			});
		}
		return this._stackedColumnChartSpec[chartName];
	}

	public leistungPackerDrehzahl(
		machine: IMachine
	): ChartSpec<IGaugeChartOptions> {
		if (typeof this._leistungPackerDrehzahl[machine.id] === 'undefined') {
			this._leistungPackerDrehzahl[machine.id] = new ChartSpec('Leistung', <
				IGaugeChartOptions
			>{
				min: 0,
				max: 2000,
				redFrom: 0,
				redTo: 700,
				yellowFrom: 700,
				yellowTo: 1200,
				greenFrom: 1200,
				greenTo: 2000,
				isFivePointChart: false,
				is3PointReverseChart: false,
				fixFloatingPointNumbers: false
			});
		}
		return this._leistungPackerDrehzahl[machine.id];
	}

	public produktionGutsaecke(machine: IMachine): ChartSpec<IGaugeChartOptions> {
		if (typeof this._produktionGutsaecke[machine.id] === 'undefined') {
			this._produktionGutsaecke[machine.id] = new ChartSpec(
				'Produktion Gutsäcke',
				<IGaugeChartOptions>{
					min: 0,
					max: 100,
					redFrom: 0,
					redTo: 70,
					yellowFrom: 70,
					yellowTo: 90,
					greenFrom: 90,
					greenTo: 100,
					isFivePointChart: false,
					is3PointReverseChart: false,
					fixFloatingPointNumbers: false
				}
			);
		}
		return this._produktionGutsaecke[machine.id];
	}

	public leistungChartSpec(machine: IMachine): ChartSpec<IGaugeChartOptions> {
		if (typeof this._leistungChartSpec[machine.id] === 'undefined') {
			this._leistungChartSpec[machine.id] = new ChartSpec('Leistung', <
				IGaugeChartOptions
			>{
				min: 0,
				max: 2000,
				redFrom: 0,
				redTo: 700,
				yellowFrom: 700,
				yellowTo: 1200,
				greenFrom: 1200,
				greenTo: 2000,
				isFivePointChart: false,
				is3PointReverseChart: false,
				fixFloatingPointNumbers: false
			});
		}
		return this._leistungChartSpec[machine.id];
	}

	// public verfuebarkeitChartSpec(machine: IMachine): ChartSpec<IGaugeChartOptions> {
	// 	if (typeof this._verfuebarkeitChartSpec[machine.id] === 'undefined') {
	// 		this._verfuebarkeitChartSpec[machine.id] = new ChartSpec('Verfügb.',
	// 			<IGaugeChartOptions>{
	// 				min: 0, max: 100,
	// 				redFrom: 0, redTo: 70,
	// 				yellowFrom: 70, yellowTo: 90,
	// 				greenFrom: 90, greenTo: 100,
	// 			}
	// 		);
	// 	}
	// 	return this._verfuebarkeitChartSpec[machine.id];
	// }

	// public wartungChartSpec(machine: IMachine): ChartSpec<IColumnChartOptions> {
	// 	if (typeof this._wartungChartSpec[machine.id] === 'undefined') {
	// 		this._wartungChartSpec[machine.id] = new ChartSpec('Wartung', <IColumnChartOptions>{
	// 			hAxis: {
	// 				minValue: 0,
	// 				maxValue: -1,
	// 				textPosition: 'none',
	// 			},
	// 			vAxis: {
	// 				minValue: 0
	// 			},
	// 			legend: { position: 'none', minLines: 4, maxLines: 4 },
	// 			chartArea: { 'left': 80, 'top': 25, 'right': 0, 'bottom': 20 },
	// 		});
	// 	}
	// 	return this._wartungChartSpec[machine.id];
	// }

	/*	public updateWartungChart(currentMetricValues, machine: IMachine) {
			ChartConfigService.updateAnyWartungChart(this.wartungChartSpec(machine),
			currentMetricValues["u32MaintGenCurr"], machine.metricSpecByAlias["u32MaintGenCurr"].description, currentMetricValues["u32MaintGen"]);
		}*/

	/*	public static updateAnyWartungChart(chartSpec: ChartSpec<IColumnChartOptions>, value: number, label: string, maxValue: number) {
			value = maxValue < value ? maxValue : value;
			chartSpec.options.vAxis.maxValue = maxValue;
			let style = "stroke-color: #000; stroke-width: 2; ";
			if (maxValue > 0) {
				let percent = (100 / maxValue) * value;
				if (percent <= 70) style += "fill-color: " + Ci.cssColors.green;
				else if (percent <= 90) style += "fill-color: " + Ci.cssColors.orange;
				else if (percent > 90) style += "fill-color: " + Ci.cssColors.wred;
			}
			chartSpec.observable.next(<Array<IChartValue>>([new ChartValue("", value, style)]));
			chartSpec.label = label;
		}*/

	/*	public updateVerfuegbarkeitChart(currentMetricValues, machine: IMachine) {
			ChartConfigService.updateGauge(this.verfuebarkeitChartSpec(machine), currentMetricValues, machine,
				"u8MachAvail_R_Y", "u8MachAvail_Y_G", "u8MachAvail");
		}

		public updateLeistungChart(currentMetricValues, machine: IMachine) {
			ChartConfigService.updateGauge(this.leistungChartSpec(machine), currentMetricValues, machine,
				"u16MachPerf_R_Y", "u16MachPerf_Y_G", "u16MachPerf");
		}

		public updateProduktionGutsaecke(currentMetricValues, machine: IMachine) {
			ChartConfigService.updateGauge(this.produktionGutsaecke(machine), currentMetricValues, machine,
				null, null, "u8ProdGoodPercent");
		}*/

	/*public updateAnyColumnChart(machine: IMachine, name: string, value: number, label: string, maxValue: number) {
		ChartConfigService.updateAnyColumnChart(this.anyColumnChartSpec(machine, name), value, label, maxValue);
	}*/

	/*	public static updateAnyColumnChart(chartSpec: ChartSpec<IColumnChartOptions>, value: number, label: string, maxValue: number) {
			chartSpec.options.vAxis.viewWindow.max = maxValue;
			value = maxValue < value ? maxValue : value;
			let style = "stroke-color: #000; stroke-width: 2; ";
			if (maxValue > 0) {
				let percent = (100 / maxValue) * value;
				if (percent <= 70) style += "fill-color: " + Ci.cssColors.green;
				else if (percent <= 90) style += "fill-color: " + Ci.cssColors.orange;
				else if (percent > 90) style += "fill-color: " + Ci.cssColors.wred;
			}
			chartSpec.observable.next(<Array<IChartValue>>([new ChartValue("", value, style)]));
			chartSpec.label = label;
		}*/

	/*	public updateAnyGaugeChart(machine: IMachine, name: string, label: string, min: number, max: number,
															ry: number, yg: number, value: number) {
			ChartConfigService.updateAnyGaugeChartSpec(this.anyGaugeChartSpec(machine, name), "", min, max, ry, yg, value);
			return this.anyGaugeChartSpec;
		}*/

	/*	public static updateAnyGaugeChartSpec(gauge: ChartSpec<IGaugeChartOptions>,
																					label: string, min: number, max: number,
																					ry: number, yg: number, value: number) {
			gauge.options.min = min;
			gauge.options.max = max,
				gauge.options.redTo = ry;
			gauge.options.yellowFrom = ry;
			gauge.options.yellowTo = yg;
			gauge.options.greenFrom = yg;
			gauge.options.redFrom = min;
			gauge.options.greenTo = max;
			let val = Math.round(value * 10) / 10;
			gauge.observable.next([new ChartValue("", val, null)]);
			gauge.label = label;

		}*/

	/*public updateLeistungPackerDrehzahl(currentMetricValues, machine: IMachine) {
		ChartConfigService.updateGauge(this.leistungPackerDrehzahl(machine), currentMetricValues, machine,
			"u16PerfByRotationCnt_R_Y", "u16PerfByRotationCnt_Y_G", "u16PerfByRotationCntCurr");
		this.leistungPackerDrehzahl(machine).options.min = 0;
		if (currentMetricValues["u16PerfByRotationCnt"])
		 this.leistungPackerDrehzahl(machine).options.max = currentMetricValues["u16PerfByRotationCnt"];
	}*/

	/*	public static updateGauge(gauge: ChartSpec<IGaugeChartOptions>,
															currentMetricValues, machine: IMachine,
															ry: string,
															yg: string,
															value: string) {
			if (currentMetricValues[ry]) {
				gauge.options.redTo = currentMetricValues[ry];
				gauge.options.yellowFrom = currentMetricValues[ry]
			}
			if (currentMetricValues[yg]) {
				gauge.options.yellowTo = currentMetricValues[yg];
				gauge.options.greenFrom = currentMetricValues[yg];
			}

			let val = Math.round(currentMetricValues[value] * 10) / 10;
			gauge.observable.next([new ChartValue("", val, null)]);
			gauge.label = machine.metricSpecByAlias[value].description;
		}*/

	/*	public updateProduktion(data: IMachineMetricValues, machine: IMachine) {
			this.produktionChartSpec(machine).observable.next([new ChartValue("Produktionszeit", [
				data.aliasToValue["u16ProdTime"],
				data.aliasToValue["u16OutletTime"],
				data.aliasToValue["u16FeedTime"],
				data.aliasToValue["u16ContainerTime"]
			], null)]);
		}*/

	public createChart(
		cTyep: IChartType,
		headerLabel: string,
		footerLabel: string,
		label: string,
		min: any,
		max: string,
		ry: string,
		yg: string,
		value: string,
		cvalue1: any,
		cvalue2: any
	): IChartTypeOptions {
		return <IChartTypeOptions>{
			id: cTyep.id,
			machineId: cTyep.machineId,
			chartName: cTyep.chartName,
			chartType: cTyep.chartType,
			headerLabel: headerLabel,
			footerLabel: footerLabel,
			max: max,
			min: min,
			value: value,
			ry: ry,
			yg: yg,
			label: label,
			cValue1: cvalue1,
			cValue2: cvalue2
		};
	}

	public createChartType(
		machine: IMachine,
		id: number,
		chartName: string,
		chartType: string
	): any {
		let cType = <IChartType>{
			id: id,
			machineId: machine.id,
			chartName: chartName,
			chartType: chartType
		};
		return this.machineService.updateChartType(machine, cType).subscribe(
			(chType) => {
				return chType;
			},
			(err) => {
				this.notificationService.showError(err);
				return null;
			}
		);
	}

	// // Gauge chartspec for the freely configurable cards
	// public anyGaugeChartSpecDynCards(machine: IMachine, name: string, label: string, min: number, max: number,
	// 	ry: number, yg: number, value: string): ChartSpec<IGaugeChartOptions> {

	// 	// todo check why this run's loops inhere

	// 	if (typeof this._anyGaugeChartSpec[machine.id + name] === 'undefined') {
	// 		this._anyGaugeChartSpec[machine.id + name] = new ChartSpec('label', <IGaugeChartOptions>{
	// 			min: min, max: max,
	// 			redFrom: min, redTo: ry,
	// 			yellowFrom: ry, yellowTo: yg,
	// 			greenFrom: yg, greenTo: max,
	// 			value: min,
	// 			size: 250 * 0.9,
	// 			get radius() {
	// 				return this.size * 0.97 / 2
	// 			},
	// 			get cx() {
	// 				return this.size / 2
	// 			},
	// 			get cy() {
	// 				return this.size / 2
	// 			},
	// 			get range() {
	// 				return this.max - this.min
	// 			},
	// 			majorTicks: 5,
	// 			minorTicks: 2,
	// 			greenColor: '#109618',
	// 			yellowColor: '#FF9900',
	// 			redColor: '#DC3912',
	// 			transitionDuration: 0,
	// 			info: { machine: machine, name: name },
	// 			neededValues: ['', '', value, '', ''], // todo move to filtered observable below ? , need to make alias stream first.
	// 			machineId: machine.id
	// 		});
	// 	}

	// 	return this._anyGaugeChartSpec[machine.id + name];
	// }

	// public anyColumnChartSpecDynCards(machine: IMachine, name: string, value: string, max: number, min: number): ChartSpec<IColumnChartOptions> {
	// 	if (typeof this._anyColumnChartSpec[machine.id + name] === 'undefined') {
	// 		this._anyColumnChartSpec[machine.id + name] = new ChartSpec('Wartung', <IColumnChartOptions>{
	// 			hAxis: {
	// 				minValue: 0,
	// 				maxValue: 0,
	// 				textPosition: 'none',
	// 			},
	// 			vAxis: {
	// 				maxValue: max,
	// 				minValue: min,
	// 				viewWindowMode: 'explicit',
	// 				viewWindow: {
	// 					max: max,
	// 					min: min
	// 				}
	// 			},
	// 			legend: { position: 'none', minLines: 4, maxLines: 4 },
	// 			chartArea: { 'left': 80, 'top': 25, 'right': 0, 'bottom': 20 },
	// 			valueType: 'number',
	// 			neededValues: [value, '', ''],
	// 			machineId: machine.id
	// 		});
	// 	}
	// 	return this._anyColumnChartSpec[machine.id + name];
	// }

	public bagCounterStackedAreaChartConfig(
		aliases: Array<Array<string>>,
		countAlias: string,
		verticalAxisTitle: string
	) {
		let fillingStationMaxCount = 16,
			chartDataItems: Array<string> = [],
			fillingStationLabel = this.translateService.instant('A024FillingStation');
		Array.from({ length: fillingStationMaxCount }, (v, k) => k + 1).forEach(
			(index) => {
				chartDataItems.push(`${fillingStationLabel} ${index}`);
			}
		);
		return <StackedAreaChartType>{
			column: {
				category: fillingStationLabel,
				values: chartDataItems,
				countAlias: [countAlias]
			},
			neededValues: aliases,
			vAxis: { title: verticalAxisTitle },
			hideData: { isHidden: false, value: 0 },
			transformData: {
				applyTransformFunction: false,
				transformFunction: (value: number) => value
			}
		};
	}

	public haverBagCounterStackedAreaChartConfig(
		aliases: Array<Array<string>>,
		countAlias: Array<string>,
		verticalAxisTitle: string,
		machine?: IMachine
	) {
		let fillingStationMaxCount = 10,
			alias2MaxCount = 2,
			chartDataItems: Array<string> = [],
			netWeigherLabel = this.getNetWeighterLabelForMachineType(machine),
			fillingStationLabel = this.translateService.instant('A024FillingStation');

		Array.from({ length: alias2MaxCount }, (v, k) => k + 1).forEach((index) => {
			chartDataItems.push(`${netWeigherLabel} ${index}`);
		});
		Array.from({ length: fillingStationMaxCount }, (v, k) => k + 1).forEach(
			(index) => {
				chartDataItems.push(`${fillingStationLabel} ${index}`);
			}
		);
		return <StackedAreaChartType>{
			column: {
				category: '',
				values: chartDataItems,
				countAlias: countAlias
			},
			neededValues: aliases,
			vAxis: { title: verticalAxisTitle },
			hideData: { isHidden: false, value: 0 },
			transformData: {
				applyTransformFunction: false,
				transformFunction: (value: number) => value
			}
		};
	}

	public isf1BagCounterStackedAreaChartConfig(
		aliases: Array<Array<string>>,
		countAlias: Array<string>,
		verticalAxisTitle: string,
		machine?: IMachine
	) {
		let chartDataItems: Array<string> = [],
			netWeigherLabel = this.getNetWeighterLabelForMachineType(machine);

		Array.from({ length: 5 }, (v, k) => k + 1).forEach((index) => {
			chartDataItems.push(`${netWeigherLabel} ${index}`);
		});
		chartDataItems.push(
			`${this.translateService.instant('A024FillingStation')} ${1}`
		);

		return <StackedAreaChartType>{
			column: {
				category: '',
				values: chartDataItems,
				countAlias: countAlias
			},
			neededValues: aliases,
			vAxis: { title: verticalAxisTitle },
			hideData: { isHidden: false, value: 0 },
			transformData: {
				applyTransformFunction: false,
				transformFunction: (value: number) => value
			}
		};
	}

	public fillingStationNetWeighterMultiLineChartConfig(
		aliases: Array<Array<string>>,
		countAlias: Array<string>,
		machine?: IMachine
	) {
		let fillingStationMaxCount = 16,
			netWeighersMaxCount = 5,
			chartDataItems: Array<string> = [],
			netWeigherLabel = this.getNetWeighterLabelForMachineType(machine),
			fillingStationLabel = this.translateService.instant('A024FillingStation');

		return this.getWeightDataAndStandardDeviationDataChartConfig(
			netWeighersMaxCount,
			chartDataItems,
			netWeigherLabel,
			fillingStationMaxCount,
			fillingStationLabel,
			countAlias,
			aliases
		);
	}

	public miniAdamsFillingStationNetWeightierMultiLineChartConfig(
		aliases: Array<Array<string>>,
		countAlias: Array<string>,
		machine?: IMachine
	) {
		let fillingStationMaxCount = 16,
			netWeighersMaxCount = 5,
			chartDataItems: Array<string> = [],
			netWeigherLabel = this.getNetWeighterLabelForMachineType(machine),
			fillingStationLabel = this.translateService.instant('A227Container');

		return this.getWeightDataAndStandardDeviationDataChartConfig(
			netWeighersMaxCount,
			chartDataItems,
			netWeigherLabel,
			fillingStationMaxCount,
			fillingStationLabel,
			countAlias,
			aliases
		);
	}

	private getWeightDataAndStandardDeviationDataChartConfig(
		netWeighersMaxCount: number,
		chartDataItems: Array<string>,
		netWeigherLabel: string,
		fillingStationMaxCount: number,
		fillingStationLabel: string,
		countAlias: Array<string>,
		aliases: Array<Array<string>>
	) {
		Array.from({ length: netWeighersMaxCount }, (v, k) => k + 1).forEach(
			(index) => {
				chartDataItems.push(`${netWeigherLabel} ${index}`);
			}
		);
		Array.from({ length: fillingStationMaxCount }, (v, k) => k + 1).forEach(
			(index) => {
				chartDataItems.push(`${fillingStationLabel} ${index}`);
			}
		);
		return <MultiLineChartType>{
			column: {
				category: '',
				values: chartDataItems,
				countAlias: countAlias
			},
			neededValues: aliases,
			vAxis: { title: fillingStationLabel },
			hideData: { isHidden: false, value: 0 },
			transformData: {
				applyTransformFunction: true,
				transformFunction: (value: number) => value
			}
		};
	}

	public getCollectiveFaultsMultiLineChartConfig(fillingStationLabel: string,
		countAlias: Array<string>,
		aliases: Array<Array<string>>) {

		fillingStationLabel = this.translateService.instant(fillingStationLabel);
		const chartDataItems = [this.translateService.instant('A236Applicator')];

		Array.from({length: 4}, (v, k) => k + 1).forEach(
			(index) => {
				chartDataItems.push(`${fillingStationLabel} ${index}`);
			}
		);

		return <MultiLineChartType>{
			column: {
				category: '',
				values: chartDataItems,
				countAlias: countAlias
			},
			neededValues: aliases,
			vAxis: {title: fillingStationLabel},
			hideData: {isHidden: false, value: 0},
			transformData: {
				applyTransformFunction: true,
				transformFunction: (value: number) => value
			}
		};
	}

	public getInterruptionsMultiLineChartConfig(aliases: Array<Array<string>>) {

		return <MultiLineChartType>{
			neededValues: aliases,
			hideData: {isHidden: true, value: 65535},

			transformData: {
				applyTransformFunction: false,
				transformFunction: (value: number) => value
			}
		};
	}

	public dynamicCardTableChartConfig(
		chartDataItems: Array<string>,
		countAlias: number,
		aliases: Array<Array<string>>,
		unit: string
	): StackedAreaChartType {
		return <StackedAreaChartType>{
			column: {
				category: '',
				values: chartDataItems,
				countAlias: [countAlias.toString()]
			},
			neededValues: aliases,
			vAxis: { title: unit },
			hideData: { isHidden: false, value: 0 },
			transformData: {
				applyTransformFunction: false,
				transformFunction: (value: number) => value
			}
		};
	}
	private getNetWeighterLabelForMachineType(machine: IMachine): string {
			return this.translateService.instant('A237NetWeigher');
	}


}
